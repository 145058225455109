import { CSSProperties, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";

import { WidgetFrameError } from "components/layout/WidgetFrame/WidgetFrameError";
import { WidgetHeightValue, WidgetProps, WidgetSizeSettings } from "components/layout/WidgetFrame/widget.types";
import { Panel } from "components/ui/Panel";

type Props = WidgetProps & {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
    widgetId?: string;
};

const getColSpan =
    (breakpoint: keyof WidgetSizeSettings) =>
    ({ $colSpan }: { $colSpan?: WidgetSizeSettings }) => {
        if (!$colSpan || !$colSpan[breakpoint]) return undefined;

        return `span ${$colSpan[breakpoint]}`;
    };

const getRowSpan =
    (breakpoint: keyof WidgetSizeSettings) =>
    ({ $rowSpan }: { $rowSpan?: WidgetSizeSettings }) => {
        if (!$rowSpan || !$rowSpan[breakpoint]) return undefined;

        return `span ${$rowSpan[breakpoint]}`;
    };

const getHeight =
    (breakpoint: keyof WidgetSizeSettings) =>
    ({ $height }: { $height?: WidgetHeightValue }) => {
        if (typeof $height === "number") return breakpoint === "md" ? `${$height}px` : undefined;

        const value = $height?.[breakpoint];

        if (!value) return undefined;

        return typeof value === "number" ? `${value}px` : value;
    };

const WidgetFrameStyled = styled(Panel)<{
    $colSpan?: WidgetSizeSettings;
    $rowSpan?: WidgetSizeSettings;
    $height?: WidgetHeightValue;
}>`
    display: flex;
    flex-direction: column;

    // FIXME Should be overflow: hidden, but it breaks WidgetFrameTitleInformation tooltip
    //overflow: hidden;

    &:empty {
        display: none;
    }

    height: ${getHeight("md")};

    grid-column: ${getColSpan("md")};
    grid-row: ${getRowSpan("md")};

    @container page (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: ${getHeight("sm")};
        grid-column: ${getColSpan("sm")};
        grid-row: ${getRowSpan("sm")};
    }

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        height: ${getHeight("xs")};
        grid-column: ${getColSpan("xs")};
        grid-row: ${getRowSpan("xs")};
    }

    @container page (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        height: ${getHeight("lg")};
        grid-column: ${getColSpan("lg")};
        grid-row: ${getRowSpan("lg")};
    }

    @container page (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        height: ${getHeight("xl")};
        grid-column: ${getColSpan("xl")};
        grid-row: ${getRowSpan("xl")};
    }
`;

const WidgetFrame = (props: Props) => {
    return (
        <WidgetFrameStyled
            className={props.className}
            $colSpan={props.colSpan}
            $rowSpan={props.rowSpan}
            $height={props.height}
            style={props.style}
            data-widget-id={props.widgetId} // For identifying (and scrolling to) widget frame
        >
            <ErrorBoundary FallbackComponent={WidgetFrameError}>{props.children}</ErrorBoundary>
        </WidgetFrameStyled>
    );
};

export { WidgetFrame };
export type { Props as WidgetFrameProps };
