import { config } from "config";
import { Language, Languages } from "i18n/languages.enum";
import { FmsReportUnit } from "store/services/fms-report-data-source.service";

const LS_PREFIX_KEY = "LSSA::fms-report-";
const LS_TOKEN_KEY = `${LS_PREFIX_KEY}token`;
const FARM_SCAN_REPORT_VALIDATE_TOKEN_URL = `${config.API_URL}api/FarmScanReport`;

export const getFmsReportToken = (): string | null => {
    const token = localStorage.getItem(LS_TOKEN_KEY);
    if (!token) return null;

    return token;
};

export const setFmsReportToken = (token: string) => {
    localStorage.setItem(LS_TOKEN_KEY, token);
};

export const removeFmsReportToken = () => {
    localStorage.removeItem(LS_TOKEN_KEY);
};
export const validateFmsReportToken = async (token: string): Promise<boolean> => {
    const response = await fetch(FARM_SCAN_REPORT_VALIDATE_TOKEN_URL, {
        method: "GET",
        headers: { Authorization: token },
    });

    return response.status === 200;
};

const LS_LANGUAGE_KEY = `${LS_PREFIX_KEY}language`;
const LS_UNITS_KEY = `${LS_PREFIX_KEY}units`;

const languages: Language[] = Object.keys(Languages) as Language[];

export const getFmsReportLanguage = (): Language | null => {
    const language = localStorage.getItem(LS_LANGUAGE_KEY);
    if (!language || !languages.includes(language as Language)) return null;

    return language as Language;
};

export const getFmsReportUnits = (): FmsReportUnit | null => {
    const units = localStorage.getItem(LS_UNITS_KEY);
    if (units !== "Metric" && units !== "Imperial") return null;

    return units;
};

export const setFmsReportLanguage = (language: Language) => {
    localStorage.setItem(LS_LANGUAGE_KEY, language);
};

export const setFmsReportUnits = (units: FmsReportUnit) => {
    localStorage.setItem(LS_UNITS_KEY, units);
};
