import { Page } from "components/layout/Page";
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { colSpan, height } from "components/widgets/widget.utils";
import { AttentionCustomersKPIWidget } from "pages/DashboardPage/sm/widgets/kpi/AttentionCustomersKPIWidget";
import { CriticalAgreementsKPIWidget } from "pages/DashboardPage/sm/widgets/kpi/CriticalAgreementsKPIWidget";
import { PMReportsPerAstronautKPIWidget } from "pages/DashboardPage/sm/widgets/kpi/PMReportsPerAstronautKPIWidget";
import { RepairsPerAstronautKPIWidget } from "pages/DashboardPage/sm/widgets/kpi/RepairsPerAstronautKPIWidget";
import { ServiceCostMilkKPIWidget } from "pages/DashboardPage/sm/widgets/kpi/ServiceCostMilkKPIWidget";
import { SparePartsCostPerAstronautKPIWidget } from "pages/DashboardPage/sm/widgets/kpi/SparePartsCostPerAstronautKPIWidget";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

export function ServiceManagerSubpage({ queryParameters }: Props) {
    return (
        <Page.Content.Widgets>
            <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                <AttentionCustomersKPIWidget queryParameters={queryParameters} />
            </WidgetFrame>
            <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                <CriticalAgreementsKPIWidget queryParameters={queryParameters} />
            </WidgetFrame>
            <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                <ServiceCostMilkKPIWidget queryParameters={queryParameters} />
            </WidgetFrame>
            <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                <PMReportsPerAstronautKPIWidget queryParameters={queryParameters} />
            </WidgetFrame>
            <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                <RepairsPerAstronautKPIWidget queryParameters={queryParameters} />
            </WidgetFrame>
            <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                <SparePartsCostPerAstronautKPIWidget queryParameters={queryParameters} />
            </WidgetFrame>
        </Page.Content.Widgets>
    );
}
