import styled from "styled-components";

import { CategorySelectMenu } from "components/layout/Navbar/ApplicationSearch/SearchCategorySelect/CategorySelectMenu";
import { CategorySelectMenuItem } from "components/layout/Navbar/ApplicationSearch/SearchCategorySelect/CategorySelectMenuItem";
import { CategorySelectTrigger } from "components/layout/Navbar/ApplicationSearch/SearchCategorySelect/CategorySelectTrigger";
import { SearchCategory } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { Popover } from "components/ui/Popover";
import { Icon } from "components/ui-deprecated";
import { usePopover } from "hooks/usePopover";
import { splitAndCapitalize } from "utils/string.utils";

const CategorySelectStyled = styled.div`
    position: relative;
`;

const categoryIconMap: Record<SearchCategory, string> = {
    all: "lely-icon-apps",
    customer: "lely-icon-human",
    device: "lely-icon-robot",
    farm: "lely-icon-farm",
    technician: "lely-icon-person",
};

type Props = {
    selectedCategory: SearchCategory;
    setSelectedCategory: (value: SearchCategory) => void;
};

const SearchCategorySelect = (props: Props) => {
    const { selectedCategory, setSelectedCategory } = props;

    const { ref, isOpen, handleToggleOpen, handleClose } = usePopover();

    // TODO Filter for some roles if needed
    const categories: SearchCategory[] = ["all", "customer", "device", "farm", "technician"];

    const handleSelectCategory = (value: SearchCategory) => {
        setSelectedCategory(value);
        handleClose();
    };

    return (
        <CategorySelectStyled ref={ref}>
            <CategorySelectTrigger onClick={handleToggleOpen}>
                <div>
                    <Icon icon={categoryIconMap[selectedCategory]} />
                    {splitAndCapitalize(selectedCategory)}
                </div>
                <Icon icon={`angle-${isOpen ? "up" : "down"}`} />
            </CategorySelectTrigger>
            {isOpen && (
                // SeachBar zIndex + 1
                <Popover variant={"bottom"} $zIndex={101}>
                    <CategorySelectMenu>
                        {categories.map((category, index) => (
                            <CategorySelectMenuItem key={index} onClick={() => handleSelectCategory(category)}>
                                <Icon icon={categoryIconMap[category]} />
                                {splitAndCapitalize(category)}
                            </CategorySelectMenuItem>
                        ))}
                    </CategorySelectMenu>
                </Popover>
            )}
        </CategorySelectStyled>
    );
};

export { SearchCategorySelect };
