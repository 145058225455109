import styled, { css } from "styled-components";

import { TOOLTIP_CLASS } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/widget-frame-title-information.consts";
import { Icon } from "components/ui-deprecated";

const smSizeCSS = css`
    height: 1rem;

    font-size: 1rem;
`;

export const WidgetFrameTitleInformationIcon = styled(Icon)`
    height: 1.5rem;

    color: ${({ theme }) => theme.color.grayDairyDarker};

    font-size: 1.25rem;

    &:hover {
        color: ${({ theme }) => theme.color.textGray};

        & + .${TOOLTIP_CLASS} {
            display: flex;

            opacity: 1;
        }
    }

    ${({ theme: { widgetSize } }) => widgetSize === "sm" && smSizeCSS};
`;
