import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { KPI, toKPIChartDataPoints, getLastValueTitle } from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";
import { hoursToHoursAndMinutes } from "utils/date.utils";

type TextDataRow = {
    DsThresholdHigher: null;
    DsThresholdLower: null;
    TechnicianCode: " ";
    TextLabel: null;
    TextValue: null;
};

type DataRow = {
    ChartDate: string;
    ChartLabel: string;
    ChartValue: number;
};

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const ProductiveHoursKPIWidget = (props: Props) => {
    const { queryParameters } = props;
    const { dateFrom, dateTo } = queryParameters;

    const title = "Productive Hours per Unique Working Day";
    const color = "blue";

    const dataSourceParameters = { ...queryParameters, KPI: 6, technicianCode: "" };

    const chartDataSource = useDataSource<DataRow[]>("Dashboard_SpentHours_Chart", dataSourceParameters);
    const textDataSource = useDataSource<[TextDataRow]>("Dashboard_SpentHours_Text", dataSourceParameters);

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? [], {
        getLabel: (x) => hoursToHoursAndMinutes(x),
    });

    return (
        <>
            <WidgetFrame.Title
                title={title}
                icon={"lely-icon-chart"}
                size={"sm"}
                link={generatePath(routes.productiveTime)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"207552653"}>
                    Productive hours per unique working days. Includes Service Work types like: Installation, Repair,
                    Preventive maintenance, Update, Telephone support, Project, Consultancy, Other
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={[chartDataSource, textDataSource]}>
                <KPI.LastValue title={getLastValueTitle(dateFrom, dateTo)} value={lastDataPoint?.label} color={color} />
                <KPI.Chart data={data} color={color} />
            </WidgetFrame.KPI>
        </>
    );
};

export { ProductiveHoursKPIWidget };
