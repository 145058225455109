import { useCallback, useState } from "react";

export const useExpandableRows = () => {
    const [expandedRows, setExpandedRow] = useState<Set<number>>(new Set());

    const getIsExpandedRow = useCallback((rowIndex: number) => expandedRows.has(rowIndex), [expandedRows]);

    const toggleExpandedRow = useCallback(
        (rowIndex: number) => {
            setExpandedRow((prev) => {
                const curr = new Set(prev);
                if (prev.has(rowIndex)) {
                    curr.delete(rowIndex);
                } else {
                    curr.add(rowIndex);
                }
                return curr;
            });
        },
        [setExpandedRow]
    );

    const resetExpandedRows = useCallback(() => {
        setExpandedRow(new Set());
    }, [setExpandedRow]);

    return { getIsExpandedRow, toggleExpandedRow, resetExpandedRows };
};
