import styled from "styled-components";

export const SearchResultMenuContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 1rem 0;

    white-space: pre-wrap;
`;
