import { ReactNode } from "react";
import styled from "styled-components";

const PageContentSectionStyled = styled.div`
    display: flex;
    flex-direction: column;

    gap: 1rem;

    @media print {
        // Needed by Firefox for 'break-inisde' to work
        overflow: hidden;
    }
`;

type Props = {
    children: ReactNode;
    className?: string;
};

const PageContentSection = (props: Props) => {
    return <PageContentSectionStyled className={props.className}>{props.children}</PageContentSectionStyled>;
};

export { PageContentSection };
