import { WidgetFrameTitle as _WidgetFrameTitle } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitle";
import { WidgetFrameTitleActions } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleActions";
import { WidgetFrameTitleBooleanFilters } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleBooleanFilters";
import { WidgetFrameTitleCheckbox } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleCheckbox";
import { WidgetFrameTitleInformation } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation";
import { WidgetFrameTitleLink } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleLink";
import { WidgetFrameTitleModal } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleModal";
import { WidgetFrameTitleSearch } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleSearch";
import { WidgetFrameTitleSelect } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleSelect";
import { WidgetFrameTitleSpacer } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleSpacer";

const WidgetFrameTitle = _WidgetFrameTitle as typeof _WidgetFrameTitle & {
    Actions: typeof WidgetFrameTitleActions;
    BooleanFilters: typeof WidgetFrameTitleBooleanFilters;
    Information: typeof WidgetFrameTitleInformation;
    Modal: typeof WidgetFrameTitleModal;
    Search: typeof WidgetFrameTitleSearch;
    Select: typeof WidgetFrameTitleSelect;
    Spacer: typeof WidgetFrameTitleSpacer;
    Checkbox: typeof WidgetFrameTitleCheckbox;
    Link: typeof WidgetFrameTitleLink;
};

WidgetFrameTitle.Actions = WidgetFrameTitleActions;
WidgetFrameTitle.BooleanFilters = WidgetFrameTitleBooleanFilters;
WidgetFrameTitle.Information = WidgetFrameTitleInformation;
WidgetFrameTitle.Modal = WidgetFrameTitleModal;
WidgetFrameTitle.Search = WidgetFrameTitleSearch;
WidgetFrameTitle.Select = WidgetFrameTitleSelect;
WidgetFrameTitle.Spacer = WidgetFrameTitleSpacer;
WidgetFrameTitle.Checkbox = WidgetFrameTitleCheckbox;
WidgetFrameTitle.Link = WidgetFrameTitleLink;

export { WidgetFrameTitle };
