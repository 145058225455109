import styled from "styled-components";

import { Loader } from "components/ui/Loader";
import { config } from "config";

const WelcomePageStyled = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const WelcomePage = () => {
    return (
        <WelcomePageStyled>
            <h1>Welcome to {config.APP_TITLE}</h1>
            <Loader size={"32px"} />
        </WelcomePageStyled>
    );
};

export { WelcomePage };
