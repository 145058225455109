import { useState } from "react";

import { UnitSettingsModal } from "components/layout/Navbar/Settings/UnitSettings/UnitSettingsModal";
import { Dialog } from "components/ui/Dialog";
import { LoaderContent } from "components/ui/LoaderContent";
import { MenuListItem, MenuListItemIcon } from "components/ui/MenuList";
import { notification } from "components/ui-deprecated";
import { useUserSettingUnit } from "deprecated/hooks/user.hooks";
import { OUTSIDE_CLICK_NO_CLOSE_CLASS } from "hooks/useOutsideClick";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";
import { useAppDispatch, useAppSelector } from "store";
import { UserSettingKeys } from "store/enums/user-settings.enum";
import { dataSourceApi, TAG_DATA_SOURCE } from "store/services/data-source.service";
import { usePutUserSettingsMutation } from "store/services/user.service";

type Props = {
    onSubmit?: () => void;
};

const UnitSettings = (props: Props) => {
    const userId = useAppSelector((state) => state.user.id);
    const userUnit = useUserSettingUnit();
    const dispatch = useAppDispatch();

    const [updateUnits, { isLoading: isUpdateUnitsLoading }] = usePutUserSettingsMutation();
    const sendUserEvent = useSendUserEvent();

    const [show, setShow] = useState<boolean>(false);

    const handleSubmit = async (unit: string) => {
        if (userId === null) return;

        const params = { name: UserSettingKeys["units"], value: unit };

        try {
            await updateUnits({ userId, params }).unwrap();
            dispatch(dataSourceApi.util.invalidateTags([TAG_DATA_SOURCE]));
            notification.success("Units preference updated!");
            setShow(false);
            props.onSubmit?.();

            sendUserEvent({
                type: "select:units",
                value: unit,
            });
        } catch {
            notification.danger("Settings update failed. Please try again.");
        }
    };

    return (
        <>
            <MenuListItem onClick={() => setShow(true)}>
                <MenuListItemIcon icon={"lely-icon-manage"} />
                Change Units
            </MenuListItem>
            <Dialog open={show} onOpenChange={() => setShow(false)}>
                <Dialog.Content className={OUTSIDE_CLICK_NO_CLOSE_CLASS} width={"sm"}>
                    <Dialog.Content.Title>Change Units</Dialog.Content.Title>
                    <LoaderContent isLoading={false} isFetching={isUpdateUnitsLoading}>
                        <UnitSettingsModal
                            initialValue={userUnit ?? null}
                            onSubmit={handleSubmit}
                            onCancel={() => setShow(false)}
                        />
                    </LoaderContent>
                </Dialog.Content>
            </Dialog>
        </>
    );
};

export { UnitSettings };
