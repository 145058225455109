import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { monthAgo, today, yearAgo } from "utils/date.utils";

export type GlobalVariablesDefaultState = {
    date: string;
    dateFrom: string;
    dateTo: string;
};
export type GlobalVariables = {
    default: GlobalVariablesDefaultState;
    serviceManager: GlobalVariablesDefaultState;
};
export type GlobalVariableContext = keyof GlobalVariables;

type State = {
    globals: GlobalVariables;
};

const initialState: State = {
    globals: {
        default: {
            date: today,
            dateFrom: monthAgo,
            dateTo: today,
        },
        serviceManager: {
            date: today,
            dateFrom: yearAgo,
            dateTo: today,
        },
    },
};

const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        setGlobalVariable: <T extends GlobalVariableContext, TVariable extends keyof GlobalVariables[T]>(
            state,
            { payload: { context, variable, value } }: PayloadAction<{ context: T; variable: TVariable; value: string }>
        ) => {
            state.globals[context][variable] = value;
        },
    },
});

export const { setGlobalVariable } = pagesSlice.actions;
export default pagesSlice.reducer;
