import { createRoot } from "react-dom/client";

import { App } from "App";

// Listen for preload errors, which happen when a new version of the app is deployed
// and the user is still on the old version.
// https://github.com/vitejs/vite/pull/12084
window.addEventListener("vite:preloadError", () => {
    // Refresh the application
    window.location.reload();
});

const container = document.getElementById("main");
const root = createRoot(container!);
root.render(<App />);
