import styled from "styled-components";

import { WidgetFrameContent } from "components/layout/WidgetFrame/WidgetFrameContent";
import { Dependency } from "components/ui/DataContent/data-content.types";
import { Loader } from "components/ui/Loader";
import { type TableExpandableProps, Row, TableExpandable } from "components/ui/table-final-saviour/Table";
import { Icon } from "components/ui-deprecated";

const WidgetFrameTableContent = styled(WidgetFrameContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    color: ${({ theme }) => theme.color.textGrayDairyDarker};
`;

type Props<T> = Omit<TableExpandableProps<T>, "data"> & {
    dataSource: { data?: T[] } & Dependency;
};

const WidgetFrameTableExpandable = <T extends Row>(props: Props<T>) => {
    const { dataSource, ...tableProps } = props;
    const { data, isLoading, isFetching } = dataSource;

    if (isLoading || isFetching) {
        return (
            <WidgetFrameTableContent>
                <Loader size={"24px"} />
            </WidgetFrameTableContent>
        );
    }

    if (!data) {
        return (
            <WidgetFrameTableContent>
                <Icon icon="exclamation-circle" />
                No data
            </WidgetFrameTableContent>
        );
    }

    if (data.length === 0) {
        return (
            <WidgetFrameTableContent>
                <Icon icon="exclamation-circle" />
                No rows found
            </WidgetFrameTableContent>
        );
    }

    return <TableExpandable<T> data={data} {...tableProps} />;
};

export { WidgetFrameTableExpandable };
