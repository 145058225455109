import styled, { css } from "styled-components";

import { SIDEBAR_TRIGGER_SUBTITLE_CLASS } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlTrigger/sidebar-trigger.consts";

const collapsedCSS = css`
    padding-left: 3.375rem;
`;

export const SidebarControlTrigger = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 1rem 2rem;

    color: ${({ theme }) => theme.color.white};

    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.grayDairy};

        & .${SIDEBAR_TRIGGER_SUBTITLE_CLASS} {
            color: ${({ theme }) => theme.color.grayLight};
        }
    }

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && collapsedCSS};
`;
