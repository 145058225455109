import { NotificationItem } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItem";
import { NotificationListEmpty } from "components/layout/Navbar/Notifications/NotificationList/NotificationListEmpty";
import { NotificationListHeader } from "components/layout/Navbar/Notifications/NotificationList/NotificationListHeader";
import { NotificationListHeaderButton } from "components/layout/Navbar/Notifications/NotificationList/NotificationListHeaderButton";
import { Loading } from "components/ui-deprecated";
import { NotificationMessage, useSetNotificationReadMutation } from "store/services/notification.service";

type Props = {
    data?: NotificationMessage[];
    isFetching: boolean;
};

const NotificationListUnread = (props: Props) => {
    const { data, isFetching } = props;
    const count = data?.length ?? 0;

    const [setNotificationRead, { isLoading: isSettingRead }] = useSetNotificationReadMutation();
    const handleReadNotification = async () => {
        try {
            // Would be much better if there was an endpoint for read all,
            // rather just for read one
            for (const message of data) {
                await setNotificationRead(message.id).unwrap();
            }
        } catch (err) {
            // TODO Better exception handling
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };

    return (
        <div>
            <NotificationListHeader>
                Unread notifications
                {count > 0 && (
                    <NotificationListHeaderButton onClick={handleReadNotification} disabled={isSettingRead}>
                        Mark all as read
                    </NotificationListHeaderButton>
                )}
            </NotificationListHeader>
            <Loading show={isFetching}>
                {count === 0 && <NotificationListEmpty>Empty</NotificationListEmpty>}
                {data?.map((message) => <NotificationItem key={message.id} isRead={false} data={message} />)}
            </Loading>
        </div>
    );
};

export { NotificationListUnread };
