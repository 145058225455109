import { config } from "config";
import {
    AuthError,
    AuthSessionExpiredError,
    FetchError,
    JSONParseError,
    ValidationError,
} from "services/auth/auth.errors";
import { authResponseErrorSchema, authResponseSuccessSchema } from "services/auth/auth.schemas";
import {
    AuthData,
    AuthResponse,
    AuthResponseError,
    AuthResponseSuccess,
    LoginRequestBody,
} from "services/auth/auth.types";

const STORAGE_KEY = "LSSA::authData";
const AUTH_REQUEST_URL = `${config.API_URL}api/oauth/token`;

// Storage methods

export const getStoredAuthData = (): AuthData | null => {
    const dataString = localStorage.getItem(STORAGE_KEY);
    if (!dataString) return null;

    const data = JSON.parse(dataString);
    if (!data) return null;

    // TODO validation
    return data as AuthData;
};

export const setStoredAuthData = (data: AuthData) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const removeStoredAuthData = () => {
    localStorage.removeItem(STORAGE_KEY);
};

// Common methods

const toRequestBody = (data: Record<string, string>): string => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return new URLSearchParams(formData as any).toString();
};

const parseResponseBody = (response: AuthResponseSuccess): AuthData => ({
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
    tokenType: response.token_type,
    expiresIn: response.expires_in,
});

const callRequest = async (body: Record<string, string>): Promise<AuthResponse> => {
    let response: Response;
    try {
        const headers: Record<string, string> = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        response = await fetch(AUTH_REQUEST_URL, {
            method: "POST",
            headers: headers,
            body: toRequestBody(body),
        });
    } catch (err) {
        throw new FetchError();
    }

    let responseBodyRaw: unknown;
    try {
        responseBodyRaw = await response.json();
    } catch (err) {
        throw new JSONParseError();
    }

    if (response.status === 200) {
        try {
            const responseBodySuccess = authResponseSuccessSchema.parse(responseBodyRaw);
            return responseBodySuccess;
        } catch (err) {
            throw new ValidationError();
        }
    } else if (response.status === 400) {
        let responseBodyError: AuthResponseError;
        try {
            responseBodyError = authResponseErrorSchema.parse(responseBodyRaw);
        } catch (err) {
            throw new ValidationError();
        }
        throw new AuthError(responseBodyError);
    } else if (response.status === 401) {
        throw new AuthSessionExpiredError();
    } else {
        throw new Error("Unknown error");
    }
};

// Auth methods

export const login = async (data: LoginRequestBody): Promise<AuthData> => {
    const requestBody = {
        grant_type: "password",
        client_id: config.CLIENT_ID,
        username: data.username,
        password: data.password,
        remember_me: data.rememberMe.toString(),
    };

    try {
        const authResponse = await callRequest(requestBody);
        const authData = parseResponseBody(authResponse as AuthResponseSuccess);

        setStoredAuthData(authData);
        return authData;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
    }
};

// Refresh

export const refreshAuth = async (): Promise<AuthData> => {
    const storedAuthData = getStoredAuthData();
    if (!storedAuthData) return;

    const requestBody = {
        grant_type: "refresh_token",
        client_id: config.CLIENT_ID,
        refresh_token: storedAuthData.refreshToken,
    };

    try {
        const authResponse = await callRequest(requestBody);
        const authData = parseResponseBody(authResponse as AuthResponseSuccess);

        setStoredAuthData(authData);
        return authData;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
    }
};
