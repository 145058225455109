import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { getPageRoutes } from "components/layout/Page/PageNavigation/PageRoutes/page-routes.utils";
import { SubPage } from "pages/pages.types";
import { SubRoute } from "routes/routes.types";

type Props<T = any> = {
    subPages: Record<string, SubPage>;
    subRoutes: Record<string, SubRoute>;
    subPageProps?: T;
};

const PageNavigationRoutes = <T = any,>(props: Props<T>) => {
    const { subPages, subRoutes, subPageProps } = props;

    const pageRoutes = getPageRoutes({ subPages, subRoutes });

    return (
        <Routes>
            {pageRoutes.map(({ path, Component, subPageRoutes }) => (
                <Fragment key={path}>
                    {/* Main subroute */}
                    <Route path={path} element={<Component {...subPageProps} />} />
                    {/* Nested subroutes */}
                    {subPageRoutes.map(({ path: subPath, Component }) => {
                        const fullPath = `${path}/${subPath}`;
                        return <Route key={fullPath} path={fullPath} element={<Component {...subPageProps} />} />;
                    })}
                </Fragment>
            ))}
            <Route path={"*"} element={<Navigate to={""} />} />
        </Routes>
    );
};

export { PageNavigationRoutes };
