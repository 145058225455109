import clsx from "clsx";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";

import { WidgetFrameDataContent } from "components/layout/WidgetFrame/WidgetFrameDataContent";
import { WidgetFrameError } from "components/layout/WidgetFrame/WidgetFrameError";
import { WidgetFrameNoDataFallback } from "components/layout/WidgetFrame/WidgetFrameNoDataFallback";
import { WIDGET_FRAME_CONTENT_CLASS } from "components/layout/WidgetFrame/widget-frame.consts";
import { Dependency } from "components/ui/DataContent/data-content.types";
import { KPI } from "components/ui/KPI";

export const WidgetFrameContentKPI = styled.div`
    overflow: hidden;
    height: 100%;
`;

type Props = {
    children: ReactNode;
    dependencies: (Dependency & { data?: unknown[] | unknown })[];
    className?: string;
    fallbackText?: string;
};

const WidgetFrameKPI = (props: Props) => {
    const { children, dependencies, fallbackText } = props;

    const hasData = dependencies.every((x) => {
        if (x.data === undefined) return false;
        if (Array.isArray(x.data)) return x.data.length > 0;
        return true;
    });

    return (
        <WidgetFrameDataContent dependencies={dependencies}>
            <WidgetFrameNoDataFallback hasData={hasData} fallbackText={fallbackText}>
                <ErrorBoundary FallbackComponent={WidgetFrameError}>
                    <WidgetFrameContentKPI className={clsx([WIDGET_FRAME_CONTENT_CLASS, props.className])}>
                        <KPI>{children}</KPI>
                    </WidgetFrameContentKPI>
                </ErrorBoundary>
            </WidgetFrameNoDataFallback>
        </WidgetFrameDataContent>
    );
};

export { WidgetFrameKPI };
