import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import { mfpPlotterReducer } from "pages/MFPPlotterPage/mfp-plotter.slice";
import { api, fmsReportApi } from "store/api";
import { administrationApi } from "store/services/administration/administration.service";
import { authReducer, logout } from "store/slices/auth.slice";
import fmsReport from "store/slices/fms-report.slice";
import pages from "store/slices/pages.slice";
import tipsForDevelopment from "store/slices/tips-for-development.slice";
import { userPersistConfig, userReducer } from "store/slices/user.slice";
import widget from "store/slices/widget.slice";

const rootReducer = combineReducers({
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    [fmsReportApi.reducerPath]: fmsReportApi.reducer,
    [administrationApi.reducerPath]: administrationApi.reducer,
    auth: authReducer,
    pages,
    tipsForDevelopment,
    mfpPlotter: mfpPlotterReducer,
    user: persistReducer(userPersistConfig, userReducer),
    widget,
    fmsReport,
});

export const store = configureStore({
    reducer: (rootState, action) => {
        let state = rootState;

        // Reset state on logout action
        if (logout.match(action)) {
            state = undefined;
        }

        return rootReducer(state, action);
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(api.middleware, fmsReportApi.middleware, administrationApi.middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
