import styled from "styled-components";

import { Icon } from "components/ui-deprecated";

const SearchClearButtonStyled = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;

    background: none;
    border: none;

    font-size: 1rem;
`;

type Props = {
    onClick: () => void;
};

const SearchClearButton = (props: Props) => {
    return (
        <SearchClearButtonStyled onClick={props.onClick}>
            <Icon icon={"lely-icon-close-large"} />
        </SearchClearButtonStyled>
    );
};

export { SearchClearButton };
