import dayjs from "dayjs";
import moment from "moment";

import { KPIColor } from "components/ui/KPI/kpi.consts";
import { KPITimeScale } from "components/ui/KPI/kpi.types";

type FormatKPIValueOptions = {
    numberPrecision?: number;
    suffix?: string;
};
export const formatKPIValue = (value: any, options?: FormatKPIValueOptions) => {
    if (value === null || value === undefined) return null;

    let formattedValue: string;
    if (typeof value === "number") {
        const { format: formatNumber } = new Intl.NumberFormat("en-US", {
            maximumFractionDigits: options?.numberPrecision ?? 2,
        });
        formattedValue = formatNumber(value);
    } else {
        formattedValue = value.toString();
    }

    if (options?.suffix) {
        formattedValue += `${options.suffix}`;
    }

    return formattedValue;
};

export const getLastValueTitle = (dateFrom: unknown, dateTo: unknown) => {
    if (!dateFrom || !dateTo) {
        console.error("Missing dateFrom or dateTo");
        return "";
    }

    const numOfSelectedDays = dayjs(dateTo as string).diff(dateFrom as string, "day");

    if (numOfSelectedDays > 90) {
        return "Last Selected Full Month";
    }

    return "Last Selected Full Week";
};

export const resolveKPIColor = (colorOption: KPIColor): string => {
    return KPIColor[colorOption ?? "blue"];
};

export const getKPITimeScale = (fromDate: string | number, toDate: string | number): KPITimeScale => {
    const from = moment(fromDate);
    const to = moment(toDate);

    return to.diff(from, "days") > 90 ? "month" : "week";
};

export const toKPITextData = (data: any[], options?: { valueKey?: string }): number | null => {
    if (!data?.length) return null;

    const valueKey = options?.valueKey ?? "TextValue";
    if (!(valueKey in data.at(0)!)) return null;

    return data.at(0)[valueKey];
};
