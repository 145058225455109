import styled from "styled-components";

import { sidebarCollapseButtonCSS } from "components/layout/Sidebar/SidebarCollapseButton/sidebar-collapse-button.styles";
import { Icon } from "components/ui-deprecated";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";

const SidebarCollapseButtonStyled = styled.button`
    ${sidebarCollapseButtonCSS};
`;

type Props = {
    collapsedSidebar: boolean;
    toggleCollapsedSidebar: () => void;
};

const SidebarCollapseButton = (props: Props) => {
    const sendUserEvent = useSendUserEvent();

    return (
        <SidebarCollapseButtonStyled
            type={"button"}
            onClick={() => {
                props.toggleCollapsedSidebar();
                sendUserEvent({
                    type: "click:sidebar-collapse",
                    value: props.collapsedSidebar ? "expand" : "collapse",
                });
            }}
        >
            <Icon icon={props.collapsedSidebar ? "angle-right" : "angle-left"} />
        </SidebarCollapseButtonStyled>
    );
};

export { SidebarCollapseButton };
