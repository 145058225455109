import { ChartDataset, ScriptableContext } from "chart.js";

export const createKPIDataset = <T>(options: {
    data: T[];
    color: string;
    label?: string;
    gradient?: boolean;
    keepPointsSameSize?: boolean;
}) => {
    const historyDataPointCount = options.data.length - 1;
    const pointBackgroundColor = new Array(historyDataPointCount).fill(options.color).concat("white");
    const pointBorderColor = new Array(historyDataPointCount).fill("white").concat(options.color);

    return {
        type: "line" as const,
        data: options.data,
        label: options.label,
        fill: options.gradient ? "start" : undefined,
        backgroundColor: (context) => {
            if (!options.gradient || !context.chart?.chartArea) return "";

            const ctx = context.chart.ctx;
            const chartArea = context.chart.chartArea;
            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            gradient.addColorStop(0, options.color + "ff");
            gradient.addColorStop(1, options.color + "20");
            return gradient;
        },
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        borderWidth: 2,
        borderColor: options.color,
        pointRadius: 4,
        pointBackgroundColor: pointBackgroundColor,
        pointBorderWidth: 2,
        pointBorderColor: pointBorderColor,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: options.color,
        pointHoverBorderColor: options.color,
    } satisfies ChartDataset<"line", T[]>;
};

export const createFillerDataset = <T = unknown>(options: { data: T[]; value: number; color: string }) => {
    const { data, value, color } = options;

    return {
        type: "line" as const,
        data: [...data.slice(0, -1).map(() => null), value, value],
        borderColor: color,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 1,
        showLine: false,
        fill: "start",
        backgroundColor: (context: ScriptableContext<"line">) => {
            if (!context.chart?.chartArea) return "";

            const ctx = context.chart.ctx;
            const chartArea = context.chart.chartArea;
            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            gradient.addColorStop(0, color + "ff");
            gradient.addColorStop(1, color + "cc");
            return gradient;
        },
    } satisfies ChartDataset<"line", number[]>;
};
