import { useEffect } from "react";

export const useContentScroll = (onScroll: () => void) => {
    const scrollSection = document.getElementById("scroll-content"); // scrollable section wrapping all widgets

    // Hide dropdown when main section is scrolled
    // This can be improved by calculating position of input and then moved according to scrolled distance
    // might be performance bottleneck for low-end devices
    useEffect(() => {
        if (scrollSection === null) return;
        scrollSection.addEventListener("wheel", onScroll);

        return () => {
            if (scrollSection === null) return;

            scrollSection.removeEventListener("wheel", onScroll);
        };
    }, [scrollSection, onScroll]);
};
