import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
    children: ReactNode;
};

const PageHeaderWidgetsStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    gap: 2rem;

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        gap: 1rem;
    }
`;

const PageHeaderWidgets = (props: Props) => {
    return <PageHeaderWidgetsStyled>{props.children}</PageHeaderWidgetsStyled>;
};

export { PageHeaderWidgets };
