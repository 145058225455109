import { Formik, Form as FormikForm } from "formik";
import { KeyboardEvent } from "react";

import type { FormProps } from "components/ui-deprecated/Form/types";
import "components/ui-deprecated/Form/Form.less";

// TODO To be deleted
export default function Form<T>({ children, ...props }: FormProps<T>) {
    return (
        <div className={"datamole-ui-components-form"}>
            <Formik {...(props as any)}>
                <FormikForm onKeyDown={onKeyDown}>{children}</FormikForm>
            </Formik>
        </div>
    );
}

function onKeyDown(keyEvent: KeyboardEvent<HTMLFormElement>) {
    if (keyEvent.key === "Enter" && String(keyEvent.target).indexOf("textarea") > -1) {
        keyEvent.preventDefault();
    }
}
