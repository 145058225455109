import { ReactNode } from "react";
import styled from "styled-components";

import { Icon } from "components/ui-deprecated";

const NoDataWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    gap: 0.5rem;
    color: ${({ theme }) => theme.color.textGrayDairyDarker};
`;

type Props = {
    children: ReactNode;
    hasData: boolean;
    fallbackText?: string;
};

const WidgetFrameNoDataFallback = (props: Props) => {
    const { hasData, children, fallbackText = "No data" } = props;

    if (!hasData) {
        return (
            <NoDataWrapper>
                <Icon icon="exclamation-circle" />
                {fallbackText}
            </NoDataWrapper>
        );
    }

    return children;
};

export { WidgetFrameNoDataFallback };
