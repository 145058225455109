import { ChartConfiguration } from "components/ui/Chart/chart.types";

type Legend = ChartConfiguration<any>["options"]["plugins"]["legend"];

export const legendPluginConfiguration: Legend = {
    display: true,
    position: "top",
    labels: {
        pointStyle: "rectRounded",
        usePointStyle: true,
        boxWidth: 10,
        boxHeight: 10,
        // Hide legend if dataset does not have a label
        filter: (legendItem) => legendItem.text !== undefined,
    },
};

type Options = {
    position?: "top" | "right";
};

export const createLegendConfiguration = (options?: Options): Legend => {
    const position = options?.position ?? "top";

    return {
        ...legendPluginConfiguration,
        position,
        reverse: position === "right",
    };
};
