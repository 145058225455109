import "components/ui-deprecated/SwitchCheckbox/SwitchCheckbox.less";

export type SwitchCheckboxProps = {
    name: string;
    checked: boolean;
    label: string;
    type: "switch" | "primary" | "success" | "info" | "warning" | "danger";
    onChange: (checked: boolean) => void;
};

SwitchCheckbox.defaultProps = {
    checked: false,
    type: "primary",
    onChange: () => undefined,
};

export default function SwitchCheckbox(props: SwitchCheckboxProps) {
    const { name, checked, label, type, onChange } = props;
    return (
        <div className={"datamole-ui-components-switchcheckbox"}>
            <label className={"switch"}>
                <input type={"checkbox"} checked={checked} name={name} onChange={() => onChange(!checked)} />
                <span className={`slider ${type}`}></span>
            </label>
            {/* FIXME */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <div className={"switchcheckbox-label"} htmlFor={name}>
                {label}
            </div>
        </div>
    );
}
