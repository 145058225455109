import { Input } from "@datamole/wds-component-input";
import { useField } from "formik";

import ErrorField from "components/ui-deprecated/Form/Fields/ErrorField";

export type TextFieldProps = {
    name: string;
    placeholder?: string;
    label: string;
    disabled: boolean;
    type: "text" | "password" | "email" | "phone";
};

TextField.defaultProps = {
    disabled: false,
    type: "text",
};

export default function TextField({ label, ...props }: TextFieldProps) {
    const [field, meta] = useField(props);
    return (
        <div className={"input-field"}>
            <label htmlFor={props.name}>{label}</label>
            <Input type={props.type} disabled={props.disabled} className={"form-control"} {...field} {...props} />
            {meta.touched && meta.error ? <ErrorField message={meta.error} /> : null}
        </div>
    );
}
