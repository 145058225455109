import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { KPI, toKPIChartDataPoints, getLastValueTitle } from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type DataRow = {
    ChartDate: string;
    ChartValue: number;
};

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const PMsOverdueKPIWidget = (props: Props) => {
    const { queryParameters } = props;
    const { dateFrom, dateTo } = queryParameters;
    const title = "Preventive Maintenance Overdue";

    const dataSourceParameters = { ...queryParameters, KPI: 1 };

    const chartDataSource = useDataSource<DataRow[]>("Dashboard_RepairsPMsRatio_Chart", dataSourceParameters);

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);

    return (
        <>
            <WidgetFrame.Title
                title={title}
                icon={"lely-icon-robot"}
                size={"sm"}
                link={generatePath(routes.preventiveMaintenanceOverdue)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"207487074"}>
                    Robots without preventive maintenance longer than:
                    <ul>
                        <li>Astronaut A2 - 61 days</li>
                        <li>A3 - 105 days</li>
                        <li>A4 - 146 days, A5 - 122 days</li>
                        <li>Vector - 183 days, Collector - 183 days</li>
                    </ul>
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={[chartDataSource]}>
                <KPI.LastValue title={getLastValueTitle(dateFrom, dateTo)} value={lastDataPoint?.label} />
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
};

export { PMsOverdueKPIWidget };
