type Props = {
    item: string;
    active: boolean;
    handleItemClick: () => void;
};

export function Item(props: Props) {
    const { item, active, handleItemClick } = props;

    return (
        <div className={`item ${active ? "active" : ""}`} onClick={handleItemClick} title={item}>
            <div className={"red-checkbox-container"}>
                <input className={"red-checkbox"} type={"checkbox"} value={item} checked={active} />
                <label
                    style={{
                        marginBottom: 0,
                        cursor: "pointer",
                    }}
                >
                    {item}
                </label>
            </div>
        </div>
    );
}
