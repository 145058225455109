import { HiddenColumns } from "components/components-deprecated/tables/data-source-query.interfaces";
import { DataFromAllDataSources, DataWrapper } from "deprecated/data-wrapper/data-wrapper.interfaces";

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const emptyData: DataWrapper = {
    data: {},
    length: 0,
    nested: null,
};

export const emptyHiddenColumns: HiddenColumns = {
    level0: [],
    level1: [],
    level2: [],
};

export const emptyDataFromAllDataSources: DataFromAllDataSources = {
    data: emptyData,
};
