import { userSchema } from "schemas/user.schema";
import { api as baseApi } from "store/api";
import { User } from "types/user.types";

export const TAG_USER = "USER";
export const TAG_IMPERSONATION_USERS = "USER";
export type UserImpersonation = { userId: string; name: string };

interface UserSettingsParams {
    userId: string;
    params: { name: string; value: any };
}

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_USER, TAG_IMPERSONATION_USERS],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUser: builder.query<User, void>({
                query: () => ({ url: "user", method: "GET" }),
                transformResponse: (response) => {
                    const result = userSchema.safeParse(response);
                    if (result.success) {
                        return result.data as User;
                    } else {
                        // eslint-disable-next-line no-console
                        console.error("Received user data in a wrong format");
                        throw (result as any).error;
                    }
                },
                providesTags: [TAG_USER],
            }),
            getImpersonationUsers: builder.query<UserImpersonation[], void>({
                query: () => ({ url: "impersonation/user", method: "GET" }),
                providesTags: [{ type: TAG_IMPERSONATION_USERS }],
            }),
            putUserSettings: builder.mutation<void, UserSettingsParams>({
                query: ({ userId, params }) => ({
                    url: `user/${userId}/setting`,
                    method: "PUT",
                    body: params,
                }),
                invalidatesTags: [TAG_USER],
            }),
        }),
    });

export { api as userApi };
export const { useGetUserQuery, useGetImpersonationUsersQuery, usePutUserSettingsMutation } = api;
