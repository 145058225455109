import { pageAtRouteMap } from "pages";
import { routeNames, routes } from "routes";
import { getAreaRoutes } from "routes/area-routes";
import { RouteName } from "routes/routes.types";
import { useAppSelector } from "store";

export const useUserPages = () => {
    const userAreas = useAppSelector((state) => state.user.areas);
    const developerMode = useAppSelector((state) => state.user.developerMode);

    let userRoutes: RouteName[];
    if (developerMode) {
        userRoutes = routeNames;
    } else {
        userRoutes = getAreaRoutes(userAreas);
    }

    const userPages = userRoutes
        // Check if page is defined for route
        .filter((routeName) => pageAtRouteMap[routeName])
        .map((routeName) => {
            const Component = pageAtRouteMap[routeName].component;
            return { path: routes[routeName].path, Component };
        });
    return userPages;
};
