import { NotificationCountIndicator } from "components/layout/Navbar/Notifications/NotificationCountIndicator";
import { NotificationListRead } from "components/layout/Navbar/Notifications/NotificationList/NotificationListRead";
import { NotificationListUnread } from "components/layout/Navbar/Notifications/NotificationList/NotificationListUnread";
import { NotificationMenu } from "components/layout/Navbar/Notifications/NotificationMenu";
import { NotificationsOpenButton } from "components/layout/Navbar/Notifications/NotificationsOpenButton";
import { Popover } from "components/ui/Popover";
import { Icon } from "components/ui-deprecated";
import { usePopover } from "hooks/usePopover";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";
import { useGetNotificationsQuery } from "store/services/notification.service";

const Notifications = () => {
    const { data: unreadNotifications, isFetching } = useGetNotificationsQuery(
        { isRead: false },
        {
            // Refetch every 10 minutes
            pollingInterval: 1000 * 60 * 10,
        }
    );
    const unreadNotificationCount = unreadNotifications?.length ?? 0;

    const { ref, isOpen, handleToggleOpen } = usePopover();
    const sendUserEvent = useSendUserEvent();

    return (
        <div ref={ref} style={{ position: "relative" }} title={"Notifications"}>
            <NotificationsOpenButton
                onClick={() => {
                    handleToggleOpen();

                    if (!isOpen) {
                        sendUserEvent({
                            type: "click:notifications",
                            value: String(unreadNotificationCount),
                        });
                    }
                }}
            >
                <Icon icon={"bell"} />
                {unreadNotificationCount > 0 && (
                    <NotificationCountIndicator>
                        {unreadNotificationCount < 10 ? unreadNotificationCount : "9+"}
                    </NotificationCountIndicator>
                )}
            </NotificationsOpenButton>
            {isOpen && (
                <Popover variant={"bottom"} style={{ right: 0 }}>
                    <NotificationMenu>
                        <NotificationListUnread data={unreadNotifications} isFetching={isFetching} />
                        <NotificationListRead />
                    </NotificationMenu>
                </Popover>
            )}
        </div>
    );
};

export { Notifications };
