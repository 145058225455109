import { Alert } from "@datamole/wds-component-alert";
import { Button } from "@datamole/wds-component-button";
import { useState } from "react";

import { Dialog } from "components/ui/Dialog";
import { Icon } from "components/ui-deprecated";
import { dataSourceIdMap } from "hooks/useDataSource";
import { InsightsWidgetExpandedDataRow } from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";
import { useAppDispatch, useAppSelector } from "store";
import { dataSourceApi, TAG_DATA_SOURCE } from "store/services/data-source.service";
import { useDeleteInsightMutation } from "store/services/insights.service";

type Props = {
    data: InsightsWidgetExpandedDataRow;
};

const DeleteInsightButton = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const userId = useAppSelector((state) => state.user.id);
    const dispatch = useAppDispatch();

    const [deleteInsight] = useDeleteInsightMutation();

    const handleSubmit = async () => {
        await deleteInsight({
            insightId: props.data.InsightId,
            insightCreatedDate: props.data.InsightCreatedDate,
            useful: false,
            userId,
        });
        setIsOpen(false);
        dispatch(
            dataSourceApi.util.invalidateTags([
                {
                    type: TAG_DATA_SOURCE,
                    id: JSON.stringify({
                        id: dataSourceIdMap["Insights_ExpandedDetail"],
                        parameters: [
                            {
                                name: "customerNumber",
                                value: props.data.CustomerNumber,
                            },
                        ],
                    }),
                },
            ])
        );
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Trigger asChild>
                <Button onClick={() => {}} size={"sm"}>
                    <Button.Icon>
                        <Icon icon={"trash-alt"} />
                    </Button.Icon>
                    <Button.Content>Delete</Button.Content>
                </Button>
            </Dialog.Trigger>
            <Dialog.Content>
                <Dialog.Content.Title>Delete Insight?</Dialog.Content.Title>
                <Dialog.Content.Body>
                    <Alert severity={"warning"}>
                        <Alert.Icon />
                        Are you sure you want to delete this insight? This action cannot be undone.
                    </Alert>
                </Dialog.Content.Body>
                <Dialog.Content.Actions>
                    <Button onClick={() => setIsOpen(false)}>
                        <Button.Icon>
                            <Icon icon={"cross"} />
                        </Button.Icon>
                        <Button.Content>No, cancel</Button.Content>
                    </Button>
                    <Button onClick={handleSubmit} variant={"primary"}>
                        <Button.Icon>
                            <Icon icon={"check"} />
                        </Button.Icon>
                        <Button.Content>Yes, delete</Button.Content>
                    </Button>
                </Dialog.Content.Actions>
            </Dialog.Content>
        </Dialog>
    );
};

export { DeleteInsightButton };
