import { TreeSelect } from "antd";
import { useField } from "formik";
import styled, { createGlobalStyle } from "styled-components";

import ErrorField from "components/ui-deprecated/Form/Fields/ErrorField";
import { OUTSIDE_CLICK_NO_CLOSE_CLASS } from "hooks/useOutsideClick";

// TODO: only once in whole project
import "antd/dist/antd.css";

const TreeSelectStyled = styled(TreeSelect)`
    width: 100%;

    .ant-select-selector {
        padding: 9px 20px;

        background: none !important;
        border: 2px solid #f3f6f9 !important;
        border-radius: 4px !important;
    }
`;

const TreeSelectCSS = createGlobalStyle`
    .ant-select-dropdown {
        pointer-events: auto ;
    }
`;

const Label = styled.label`
    font-weight: 500;
`;

type Option = {
    key: string;
    value: string;
    title: string;
    children?: Option[];
};

export type TreeMultiSelectProps = {
    name: string;
    label?: string;
    placeholder?: string;
    options: Option[];
};

export default function TreeMultiSelect({ label, placeholder, options, ...props }: TreeMultiSelectProps) {
    const [field, meta, helpers] = useField(props);

    return (
        <div className={"input-field"} onBlur={() => helpers.setTouched(true)}>
            {label && <Label>{label}</Label>}
            <TreeSelectStyled
                popupClassName={OUTSIDE_CLICK_NO_CLOSE_CLASS}
                treeData={options}
                value={field.value}
                placeholder={placeholder ?? "Select..."}
                onChange={(options: Option[]) => {
                    helpers.setValue(options);
                }}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                filterTreeNode={(search, option: Option) => {
                    return option.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
            />
            {meta.touched && meta.error ? <ErrorField message={meta.error} /> : null}
            <TreeSelectCSS />
        </div>
    );
}

export type { Option as TreeMultiSelectOption };
