import styled from "styled-components";

import { ApplicationSearch } from "components/layout/Navbar/ApplicationSearch/ApplicationSearch";
import { LogoutButton } from "components/layout/Navbar/LogoutButton";
import { NAVBAR_ID } from "components/layout/Navbar/Navbar.consts";
import { NavbarActions } from "components/layout/Navbar/NavbarActions";
import { Notifications } from "components/layout/Navbar/Notifications/Notifications";
import { Settings } from "components/layout/Navbar/Settings/Settings";
import { WelcomeMessage } from "components/layout/Navbar/WelcomeMessage";

const NavbarStyled = styled.div`
    grid-area: navbar;

    padding: 0.75rem 2rem;

    display: flex;
    align-items: center;
    gap: 2rem;

    background-color: ${({ theme }) => theme.color.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.dairyLight};
`;

const Navbar = () => {
    return (
        <NavbarStyled id={NAVBAR_ID}>
            <ApplicationSearch />
            <WelcomeMessage />
            <NavbarActions>
                <Notifications />
                <Settings />
                <LogoutButton />
            </NavbarActions>
        </NavbarStyled>
    );
};

export { Navbar };
