import styled from "styled-components";

export const CategorySelectTrigger = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    padding: 0.5rem 1rem;

    background: none;
    border: none;
    border-right: 1px solid ${({ theme }) => theme.color.grayDairyLight};

    color: ${({ theme }) => theme.color.lelyRed};

    & > div {
        display: flex;
        gap: 0.5rem;
    }
`;
