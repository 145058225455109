import { CSSProperties, ReactNode } from "react";
import "components/ui-deprecated/Flex/Flex.less";

export type FlexProps = {
    /**
     * Additional class of element.
     */
    className?: string;

    /**
     * Additional style for flex element.
     */
    style?: CSSProperties;

    /**
     * This establishes the main-axis, thus defining the direction flex items are placed in the flex container.
     * Flexbox is (aside from optional wrapping) a single-direction layout concept.
     * Think of flex items as primarily laying out either in horizontal rows or vertical columns.
     */
    flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";

    /**
     * By default, flex items will all try to fit onto one line.
     * You can change that and allow the items to wrap as needed with this property.
     */
    flexWrap?: "nowrap" | "wrap" | "wrapReverse";

    /**
     * This defines the alignment along the main axis. It helps distribute extra free space left over
     * when either all the flex items on a line are inflexible, or are flexible but have reached their maximum size.
     * It also exerts some control over the alignment of items when they overflow the line.
     */
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";

    /**
     * This defines the default behavior for how flex items are laid out along the cross axis on the current line.
     * Think of it as the justify-content version for the cross-axis (perpendicular to the main-axis).
     */
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";

    gap?: number;

    /**
     * Children contained in Flex component.
     */
    children?: ReactNode;

    /**
     * Click on element.
     */
    onClick?: () => any;
};

Flex.defaultProps = {
    className: "",
    style: {},
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    gap: 0,
    children: null,
    onClick: () => undefined,
};

export default function Flex(props: FlexProps) {
    const { children, className, style, onClick, ...flexStyle } = props;
    return (
        <div
            className={`datamole-ui-components-flex ${className}`}
            // FIXME
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ ...flexStyle, ...style }}
            onClick={onClick}
        >
            {children}
        </div>
    );
}
