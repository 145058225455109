import styled from "styled-components";

export const Panel = styled.div`
    position: relative;

    flex-grow: 1;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 5px #dedbd666;
`;
