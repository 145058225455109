import { TableHead } from "components/ui/table-final-saviour/Table/TableHead/TableHead";
import { TableHeadProps } from "components/ui/table-final-saviour/Table/TableHead/table-head.types";

const TableHeadExpandable = (props: TableHeadProps) => (
    <TableHead
        {...props}
        style={{
            ...props?.style,
            zIndex: 1,
        }}
    />
);

export { TableHeadExpandable };
