export const chartColorCategorical = {
    red60: "#F45643",
    teal40: "#005C5C",
    cyan60: "#0F92E7",
    purple30: "#491D8B",
    green50: "#00712A",
    red20: "#500800",
    magenta40: "#9E1752",
    cyan30: "#003A6D",
    magenta60: "#ED5295",
    yellow60: "#B28600",
    teal60: "#009D9A",
    cyan20: "#002749",
    orange40: "#803000",
    purple60: "#A56EFF",
    black: "#000000",
    neutral60: "#8F8B8B",
};

export const chartColor = {
    darkGreen: "#73bd07",
    orange: "#ff7921",
    gold: "#ffc700",
    red: "#fd3535",
    blue: "#4d33dc",
    teal: "#0099ba",
    darkTeal: "#0099bb",
    mfpRed: "#e20c19",
    mfpDarkGreen: "#44a12b",
    mfpLightGreen: "#b1c800",
    mfpYellow: "#fabb00",
    extraLightBlue: "#80cae3",
    extraDarkRed: "#850C18",
    extraLightOrange: "#ffb584",
    extraDarkViolet: "#4B244A",
    extraGrey: "#CFCBC3",
    darkGrey: "#333333",
    grey: "#766e68",
    lightGrey: "#ebebeb",
};

export const chartColorBimodality = {
    "0-10": "#00712A",
    "10-25": "#FFB489",
    "25-50": "#F45643",
    "50-100": "#C30A14",
};

export const chartColorHorizonPackageDistribution = {
    Control: chartColor.gold,
    Basic: chartColor.orange,
    Advance: chartColor.darkGreen,
};

export const ACID_COLOR = "#ce181e";
export const ALKALINE_COLOR = "#0072bc";
export const activityTimelineColors = [
    "#F45643", //$color-red-60
    "#005C5C", //$color-teal-40
    "#0F92F7", //$color-cyan-60
    "#491D8B", //$color-purple-30
    "#00712A", //$color-green-50
    "#500800", //$color-red-20
    "#9E1752", //$color-magenta-40
    "#003A6D", //$color-cyan-30
    "#ED5295", //$color-magenta-60
    "#B28600", //$color-yellow-60
    "#009D9A", //$color-teal-60
    "#002749", //$color-cyan-20
    "#803000", //$color-orange-40
    "#A56EFF", //$color-purple-60
    "#000000", //$color-black
    "#8F8B8B", //$color-neutral-60
];

export const gradientColors = {
    teal: {
        "99": "#D9FBFB",
        "90": "#9DEFEF",
        "80": "#3EDBD9",
        "70": "#01BCB9",
        "60": "#009D9A",
        "50": "#006E6A",
        "40": "#005C5C",
        "30": "#004144",
        "20": "#012A2F",
        "10": "#041819",
    },
};
