import styled, { css } from "styled-components";

export const menuListItemCSS = css`
    padding: 0.25rem 1rem;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.dairyLight};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 0.5rem 1rem;

        font-size: 1rem;
    }
`;

export const MenuListItem = styled.li`
    ${menuListItemCSS};
`;
