import { ExpandedContent as _ExpandedContent } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/ExpandedContent";
import { ExpandedContentSection } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/index";

const ExpandedContent = _ExpandedContent as typeof _ExpandedContent & {
    Section: typeof ExpandedContentSection;
};

ExpandedContent.Section = ExpandedContentSection;

export { ExpandedContent };
