import { useState } from "react";
import styled from "styled-components";

import { SearchBar } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchBar";
import { SearchCategorySelect } from "components/layout/Navbar/ApplicationSearch/SearchCategorySelect/SearchCategorySelect";
import { SearchCategory } from "components/layout/Navbar/ApplicationSearch/application-search.types";

const ApplicationSearchStyled = styled.div`
    position: relative;

    display: flex;
    align-items: center;

    width: 100%;

    border: 1px solid ${({ theme }) => theme.color.grayDairyLight};
    border-radius: 1.25rem;
`;

function ApplicationSearch() {
    const [category, setCategory] = useState<SearchCategory>("all");

    return (
        <ApplicationSearchStyled>
            <SearchCategorySelect selectedCategory={category} setSelectedCategory={setCategory} />
            <SearchBar category={category} />
        </ApplicationSearchStyled>
    );
}

export { ApplicationSearch };
