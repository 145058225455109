import { MenuListItem, MenuListItemIcon } from "components/ui/MenuList";
import { useAppDispatch, useAppSelector } from "store";
import { toggleDeveloperMode } from "store/slices/user.slice";

const DeveloperModeToggle = () => {
    const isDeveloper = useAppSelector((state) => state.user.primaryCombinedRole.name === "Developers");
    const developerMode = useAppSelector((state) => state.user.developerMode);
    const dispatch = useAppDispatch();

    if (!isDeveloper) return null;

    return (
        <MenuListItem onClick={() => dispatch(toggleDeveloperMode())}>
            <MenuListItemIcon icon={"lely-icon-objects"} />
            Developer Mode - {developerMode ? "Disable" : "Enable"}
        </MenuListItem>
    );
};

export { DeveloperModeToggle };
