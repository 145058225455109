import styled from "styled-components";

const ExpandableBackground = styled.div`
    padding-bottom: 1.5rem;
    padding-left: 4rem;

    background-color: ${(props) => props.theme.color.dairyLight};
`;

const ExpandableContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    background-color: ${(props) => props.theme.color.white};
    border-left: 1px solid ${(props) => props.theme.color.grayDairyLight};
    border-bottom: 1px solid ${(props) => props.theme.color.grayDairyLight};
`;

type Props = {
    children: React.ReactNode;
};

const ExpandedContent: React.FC<Props> = ({ children }) => {
    return (
        <ExpandableBackground>
            <ExpandableContentWrapper>{children}</ExpandableContentWrapper>
        </ExpandableBackground>
    );
};

export { ExpandedContent };
