import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    KPIDataSourceChartData,
    KPI,
    KPIDataSourceTextData,
    toKPIChartDataPoints,
    toKPITextData,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const CriticalAgreementsKPIWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = { ...queryParameters };

    const chartDataSource = useDataSource<KPIDataSourceChartData[]>(
        "CriticalAgreements_KPIChart",
        dataSourceParameters
    );
    const textDataSource = useDataSource<KPIDataSourceTextData[]>("CriticalAgreements_KPIText", dataSourceParameters);
    const dataSources = [chartDataSource, textDataSource];

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);
    const additionalValue = toKPITextData(textDataSource?.data ?? []);

    return (
        <>
            <WidgetFrame.Title
                title={"Critical Agreements"}
                icon={"lely-icon-graph-down"}
                size={"sm"}
                link={generatePath(routes.profitabilityCriticalAgreements)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"333119575"}>
                    Agreements that have negative profit from service agreement fees and service orders in the past 12
                    completed months.
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={dataSources}>
                <KPI.LastValue title="Last Selected Full Month" value={lastDataPoint?.label} />
                {additionalValue && <KPI.AdditionalValue title={"All Agreements"} value={additionalValue} />}
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
};

export { CriticalAgreementsKPIWidget };
