import styled from "styled-components";

export const NotificationCountIndicator = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;

    width: 20px;
    height: 20px;
    padding: 2px;

    background-color: ${({ theme }) => theme.color.lelyRed};
    border-radius: 10px;

    color: ${({ theme }) => theme.color.white};
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    opacity: 0.8;
`;
