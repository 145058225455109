import { ComponentType } from "react";

import { TableCell as TableCellDefault, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import {
    TableHeaderCell as TableHeaderCellDefault,
    TableHeaderCellProps,
} from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { DataColumnDataType } from "components/ui/table-final-saviour/Table/columns/table-columns.types";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";
import { readCellValue } from "components/ui/table-final-saviour/Table/table.utils";
import { splitAndCapitalize } from "utils/string.utils";

type GetCellValueOptions<T extends Row> = {
    column: DataColumn<T>;
    row: T;
    value: T[keyof T];
};

export interface DataColumn<T extends Row> extends Column<T>, Pick<TableCellProps<T>, "align" | "fontStyle" | "width"> {
    _type: "data";
    dataKey: keyof T;
    dataType: DataColumnDataType;
    title?: string;
    getCellValue?: (options: GetCellValueOptions<T>) => React.ReactNode;
    components?: {
        TableCell?: ComponentType<TableCellProps<T>>;
        TableHeaderCell?: ComponentType<TableHeaderCellProps<T>>;
    };
}

export const createDataColumn = <T extends Row>(options: Omit<DataColumn<T>, "_type">): DataColumn<T> => {
    const { dataKey, components } = options;

    const TableCell: ComponentType<TableCellProps<T>> = components?.TableCell ?? TableCellDefault;
    const TableHeaderCell: ComponentType<TableHeaderCellProps<T>> =
        components?.TableHeaderCell ?? TableHeaderCellDefault;

    const getCellValue = options?.getCellValue ?? readCellValue;

    return {
        _type: "data",
        ...options,
        components: {
            TableCell: (props) => (
                <TableCell align={options?.align} width={options?.width} {...props}>
                    {getCellValue({
                        column: props.column as DataColumn<T>,
                        row: props.row,
                        value: props.row[dataKey],
                    })}
                </TableCell>
            ),
            TableHeaderCell: (props) => (
                <TableHeaderCell align={options?.align} width={options?.width} {...props}>
                    {options?.title ?? splitAndCapitalize(String(dataKey))}
                </TableHeaderCell>
            ),
        },
    };
};
