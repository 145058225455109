import clsx from "clsx";

import styles from "components/ui/Dialog/Dialog.module.css";

type Props = {
    children: React.ReactNode;
    className?: string;
};

const DialogContentBody = (props: Props) => {
    return <div className={clsx(styles.DialogContentBody, props.className)}>{props.children}</div>;
};

export { DialogContentBody };
