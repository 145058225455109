import { ReactNode } from "react";
import styled from "styled-components";

import { WidgetSizeSettings } from "components/layout/WidgetFrame/widget.types";

type JustifyContentValue = "flex-start" | "center" | "flex-end";
type Props = {
    children: ReactNode;
    colSpan?: WidgetSizeSettings;
    justifyContent?: JustifyContentValue;
};

const getColSpan =
    (breakpoint: keyof WidgetSizeSettings) =>
    ({ $colSpan }: { $colSpan?: WidgetSizeSettings }) => {
        if (!$colSpan || !$colSpan[breakpoint]) return undefined;

        return `span ${$colSpan[breakpoint]}`;
    };

const PageHeaderWidgetFrameStyled = styled.div<{
    $colSpan?: WidgetSizeSettings;
    $justifyContent?: JustifyContentValue;
}>`
    display: flex;
    justify-content: ${({ $justifyContent }) => $justifyContent ?? "flex-start"};

    grid-column: ${getColSpan("md")};

    @container (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        grid-column: ${getColSpan("sm")};
    }

    @container (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        grid-column: ${getColSpan("xs")};
    }

    @container (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-column: ${getColSpan("lg")};
    }

    @container (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        grid-column: ${getColSpan("xl")};
    }
`;

const PageHeaderWidgetFrame = (props: Props) => {
    const colSpan = props.colSpan ?? { md: 3 };

    return (
        <PageHeaderWidgetFrameStyled $colSpan={colSpan} $justifyContent={props.justifyContent}>
            {props.children}
        </PageHeaderWidgetFrameStyled>
    );
};

export { PageHeaderWidgetFrame };
