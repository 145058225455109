import clsx from "clsx";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";

import { WidgetFrameError } from "components/layout/WidgetFrame/WidgetFrameError";
import { WIDGET_FRAME_CONTENT_CLASS } from "components/layout/WidgetFrame/widget-frame.consts";

export const WidgetFrameContentStyled = styled.div`
    height: 100%;
    min-height: 3rem;

    padding: 0 1.5rem 1.5rem 1.5rem;
`;

type Props = {
    children: ReactNode;
    className?: string;
};

const WidgetFrameContent = (props: Props) => {
    return (
        <WidgetFrameContentStyled className={clsx([WIDGET_FRAME_CONTENT_CLASS, props.className])}>
            <ErrorBoundary FallbackComponent={WidgetFrameError}>{props.children}</ErrorBoundary>
        </WidgetFrameContentStyled>
    );
};

export { WidgetFrameContent };
