import { SEARCH_QUERY_MIN_CHARS } from "components/layout/Navbar/ApplicationSearch/application-search.consts";
import {
    CustomerExtendedRow,
    CustomerRow,
    DeviceRow,
    FarmRow,
    SearchResults,
    TechnicianRow,
} from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { useDataSource } from "hooks/useDataSource";

// Helpers for constuction of maps for faster lookup

const toDevicesByCustomerMap = (devices: DeviceRow[] = []) => {
    return devices.reduce((acc, device) => {
        const arrayAtKey = acc[device.CustomerNumber] ?? [];
        return { ...acc, [device.CustomerNumber]: [...arrayAtKey, device] };
    }, {});
};

const toFarmsByCustomerMap = (farms: FarmRow[] = []) => {
    return farms.reduce((acc, farm) => {
        const arrayAtKey = acc[farm.CustomerNumber] ?? [];
        return { ...acc, [farm.CustomerNumber]: [...arrayAtKey, farm] };
    }, {});
};

const toCustomerByNumberMap = (customers: CustomerRow[] = []) => {
    return customers.reduce((acc, customer) => ({ ...acc, [customer.CustomerNumber]: customer }), {});
};

const addDeviceCustomers = (
    customerMap: Record<number, CustomerRow>,
    devicesByCustomerMap: Record<number, DeviceRow[]>
) => {
    return Object.keys(devicesByCustomerMap).reduce((acc, customerNumber) => {
        if (customerNumber in acc) return acc;

        const customer = {
            CustomerNumber: customerNumber,
            Name: devicesByCustomerMap[customerNumber][0].CustomerName,
            City: devicesByCustomerMap[customerNumber][0].CustomerCity,
        };

        return { ...acc, [customerNumber]: customer };
    }, customerMap);
};

const addFarmCustomers = (customerMap: Record<number, CustomerRow>, farmsByCustomerMap: Record<number, FarmRow[]>) => {
    return Object.keys(farmsByCustomerMap).reduce((acc, customerNumber) => {
        if (customerNumber in acc) return acc;

        const customer = {
            CustomerNumber: customerNumber,
            Name: farmsByCustomerMap[customerNumber][0].Name,
            City: farmsByCustomerMap[customerNumber][0].City,
        };

        return { ...acc, [customerNumber]: customer };
    }, customerMap);
};

/**
 * Fetches search results for the given query for categories: customer, device, farm, technician
 * By default, customer result contains also devices and farms (for structured results in the search menu)
 *
 * @param query
 */
export const useSearchResults = (query: string): { data: SearchResults; isLoading: boolean; isFetching: boolean } => {
    const skip = query.length < SEARCH_QUERY_MIN_CHARS;

    const trimmedQuery = query.trim();

    const dataSourceCustomer = useDataSource<CustomerRow[]>("SearchCustomer", { query: trimmedQuery }, { skip });
    const dataSourceDevice = useDataSource<DeviceRow[]>("SearchDevice", { query: trimmedQuery }, { skip });
    const dataSourceFarm = useDataSource<FarmRow[]>("SearchFarm", { query: trimmedQuery }, { skip });
    const dataSourceTechnician = useDataSource<TechnicianRow[]>("SearchTechnician", { query: trimmedQuery }, { skip });

    const devicesByCustomer = toDevicesByCustomerMap(dataSourceDevice.data);
    const farmsByCustomer = toFarmsByCustomerMap(dataSourceFarm.data);

    let customersByNumber = toCustomerByNumberMap(dataSourceCustomer.data);
    // Found devices or farms may belong to customers that are not in the customer result list
    customersByNumber = addDeviceCustomers(customersByNumber, devicesByCustomer);
    customersByNumber = addFarmCustomers(customersByNumber, farmsByCustomer);

    // Devices and farms are returned twice (in relevant customer or as a separate result)
    // This shouldn't have a significat impact on performance
    const customers = Object.keys(customersByNumber).map((customerNumber) => ({
        ...customersByNumber[customerNumber],
        devices: devicesByCustomer[customerNumber] ?? [],
        farms: farmsByCustomer[customerNumber] ?? [],
    })) satisfies CustomerExtendedRow[];

    const devices = dataSourceDevice.data ?? [];
    const farms = dataSourceFarm.data ?? [];
    const technicians = dataSourceTechnician.data ?? [];

    return {
        data: { customers, devices, farms, technicians },
        isLoading:
            dataSourceCustomer.isLoading ||
            dataSourceDevice.isLoading ||
            dataSourceFarm.isLoading ||
            dataSourceTechnician.isLoading,
        isFetching:
            dataSourceCustomer.isFetching ||
            dataSourceDevice.isFetching ||
            dataSourceFarm.isFetching ||
            dataSourceTechnician.isLoading,
    };
};
