import { TimeScaleOptions, TimeScaleTickOptions } from "chart.js";
import dayjs from "dayjs";

import { ChartConfiguration } from "components/ui/Chart/chart.types";
import { KPITimeScale } from "components/ui/KPI/kpi.types";
import { Language, localeDateFNSMap } from "i18n/languages.enum";

type XAxisConfiguration = ChartConfiguration<"line" | "bar">["options"]["scales"]["x"];

/**
 * @param options.offset - If true, extra space is added to the both edges and the axis is scaled to fit into the chart area.
 */

export const createTimeXAxis = (options?: {
    stacked?: boolean;
    locale?: Language;
    ticksSource?: TimeScaleTickOptions["source"];
    bounds?: TimeScaleOptions["bounds"];
    skipFirstTick?: boolean;
    axisLabel?: string;
    offset?: boolean;
    useISOWeek?: boolean;
    timeScale?: KPITimeScale;
}): XAxisConfiguration => {
    const configuration: XAxisConfiguration = {
        type: "time",
        adapters: {
            date: {
                locale: localeDateFNSMap[options?.locale],
            },
        },
        time: {
            tooltipFormat: "MMM dd, yyyy",
            displayFormats: {
                day: "d MMM",
                month: "MMM yyyy",
            },
            minUnit: "day",
            isoWeekday: options?.useISOWeek,
        },
        ticks: {
            autoSkip: true,
            maxRotation: 0,
            // The source should stay set to `data` most of the times as Chart.js usually can't automatically determine the best tick values.
            // The most problematic charts are bar charts or charts which can receive dayily, weekly and monthly data in the same data set.
            source: options?.ticksSource ?? "data",
            padding: 8,
            callback(tickValue) {
                if (options?.timeScale === "month") {
                    return dayjs(tickValue).format("MMM");
                } else {
                    return dayjs(tickValue).format("D MMM");
                }
            },
        },
        grid: {
            // hide axis ticks outside of the chart
            tickLength: 0,
            display: false,
        },
        title: {
            display: options?.axisLabel ? true : false,
            text: options?.axisLabel,
        },
        stacked: options?.stacked ? true : false,
        bounds: options?.bounds ?? "data",
        afterUpdate(axis) {
            if (options?.skipFirstTick) {
                axis.ticks = axis.ticks.slice(1);
            }
        },
    };

    /**
     * If `true`, extra space is added to the both edges and the axis is scaled to fit into the chart area.
     * This is set to `false` for every chart except bar chart by default, for bar chart it defaults to `true`
     * Explicitly setting the offset to `undefined` is parsed as `false` by Chart.js instead
     */
    if (options?.offset === true) {
        configuration.offset = options.offset;
    }

    return configuration;
};
