import { FormControl } from "@datamole/wds-component-form-control";
import { Input } from "@datamole/wds-component-input";
import { uniq } from "lodash";
import { useState } from "react";

import Portal from "components/ui-deprecated/Portal/Portal";
import Option from "components/ui-deprecated/SmartSelect/Option";
import type { Data, Row } from "deprecated/data-utils";

import "components/ui-deprecated/SmartSelect/SmartSelect.less";

type Item =
    | {
          type: "item" | "history";
          position: number;
          index: number;
          row: Row;
      }
    | {
          type: "title";
          position: number;
          text: string;
      }
    | {
          type: "text";
          position: number;
          text: string;
      };
const OPTION_HEIGHT = 28;
const SCROLL_HEIGHT = 300;

export type SmartSelectProps = {
    id: string;
    data: Data;
    valueField: string;
    // If not set, value field is used.
    storeField: null | string;
    selectFields: Array<string>;
    label: null | string;
    icon: null | string;
    value: string;
    placeholder: string;
    size: "normal" | "small";
    onChange: (row: Row) => void;
};

SmartSelect.defaultProps = {
    label: null,
    icon: null,
    placeholder: "",
    size: "normal",
    storeField: null,
};

export default function SmartSelect(props: SmartSelectProps) {
    const { data, label, size, placeholder, selectFields } = props;
    const [value, setValue] = useState<null | string>(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const [activeItem, setActiveItem] = useState(-1);
    const [scrollTop, setScrollTop] = useState(0);
    const actualValue = getValue(props, value);
    const items = getItemsToRender(props, actualValue);
    const formControl = (
        <Input
            placeholder={placeholder}
            value={actualValue}
            onChange={(e) => handleValue(data, selectFields, e.target.value, setActiveItem, setValue, setScrollTop)}
            onKeyDown={(e) =>
                handleKey(props, e.key, actualValue, activeItem, setShowDropDown, setActiveItem, setValue)
            }
            onFocus={() =>
                handleFocus(props, actualValue, true, setShowDropDown, setActiveItem, setValue, setScrollTop)
            }
            onBlur={() =>
                handleFocus(props, actualValue, false, setShowDropDown, setActiveItem, setValue, setScrollTop)
            }
        />
    );
    return (
        <div className={`datamole-ui-components-smart-select ${size}`}>
            {label !== null && <FormControl.Label>{label}</FormControl.Label>}
            {formControl}
            {showDropDown && (
                <Portal zIndex={5000}>
                    <div
                        style={{
                            position: "relative",
                        }}
                    >
                        <div
                            onScroll={(e) => handleScroll(e.target as any, setScrollTop)}
                            style={{
                                maxHeight: `${SCROLL_HEIGHT}px`,
                            }}
                            className={"select-options"}
                        >
                            {renderItems(props, items, activeItem, scrollTop, setShowDropDown, setActiveItem, setValue)}
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    );
}

function getStorageKey(id: string): string {
    return `DatamoleUI-SmartSelect-${id}`;
}

function getSelectValue(row: Row, selectFields: Array<string>): string {
    return selectFields.map((field) => row[field]).join(" - ");
}

function getValue(props: SmartSelectProps, stateValue: null | string): string {
    const { data, value, valueField, selectFields } = props;

    if (stateValue === null) {
        const row = data.reduce((selectedRow, row) => (String(row[valueField]) === value ? row : selectedRow), null);

        if (row) {
            return getSelectValue(row, selectFields);
        } else {
            return "";
        }
    } else {
        return stateValue;
    }
}

// Gets store field. If the corresponding property `storeField` is not set, `valueField` is used instead.
function getStoreField(props: SmartSelectProps): string {
    const { storeField, valueField } = props;

    if (typeof storeField !== "string") {
        return valueField;
    } else {
        return storeField;
    }
}

// Gets the array of "store-field" values stored in local storage. Checks if the store field matches
// and the format is compatible. If some of these checks fails, the value is removed from the storage.
function getHistoryFromStorage(props: SmartSelectProps): Array<unknown> {
    const { id } = props;

    try {
        const stored = localStorage.getItem(getStorageKey(id));

        if (typeof stored === "string") {
            const storeField = getStoreField(props);
            const parsed = JSON.parse(stored);

            if (parsed.storeField === storeField && Array.isArray(parsed.values)) {
                return parsed.values;
            } else {
                // Invalid store field (or legacy format of storage).
                localStorage.removeItem(getStorageKey(id));
                return [];
            }
        } else {
            // Smart select does not have history yet.
            return [];
        }
    } catch (e) {
        localStorage.removeItem(getStorageKey(id));
        return [];
    }
}

// Gets actual data with indices filtered using history.
function getHistory(props: SmartSelectProps): Array<{
    index: number;
    row: Row;
}> {
    const { data } = props;
    const storeField = getStoreField(props);
    const items = getHistoryFromStorage(props);

    if (items.length > 0) {
        // Filter the data to contain only items stored by the store field.
        // NOTE: This is called on every re-rendering, however, memoizing is not trivial here.
        //       This function depends on both data and history, the former cannot be memoized by deep equality
        //       for its size, whereas the latter cannot be memoized by reference because the array comes from
        //       local storage and JSON.parse which always return a new reference.
        return data
            .map((row, index) => ({
                index,
                row,
            }))
            .filter(({ row }) => items.some((item) => item === row[storeField]))
            .sort((a, b) => items.indexOf(a.row[storeField]) - items.indexOf(b.row[storeField]));
    } else {
        // No need to run filtering.
        return [];
    }
}

// Adds the item to the history.
function setHistory(props: SmartSelectProps, index: number) {
    const { id, data } = props;
    const items = getHistoryFromStorage(props);
    const storeField = getStoreField(props);
    const item = data[index][storeField];
    const values = uniq([item, ...items]).slice(0, 5);
    const stored = {
        storeField,
        values,
    };
    localStorage.setItem(getStorageKey(id), JSON.stringify(stored));
}

function getItems(
    data: Data,
    selectFields: Array<string>,
    value: string
): Array<{
    index: number;
    row: Row;
}> {
    const dataProcessed = data
        .map((row, index) => ({
            row,
            index,
        }))
        .sort((a, b) => getSelectValue(a.row, selectFields).localeCompare(getSelectValue(b.row, selectFields)));

    if (value !== "") {
        return dataProcessed.filter(
            ({ row }) => getSelectValue(row, selectFields).toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
        );
    } else {
        return dataProcessed;
    }
}

function getItemsToRender(props: SmartSelectProps, value: string): Array<Item> {
    const { data, selectFields } = props;
    const items = getItems(data, selectFields, value);
    const history = getHistory(props);
    let position = -OPTION_HEIGHT;
    let itemsToRender = [];

    if (history.length > 0 && value === "") {
        position += OPTION_HEIGHT;
        itemsToRender = itemsToRender.concat([
            {
                type: "title",
                position,
                text: "History",
            },
            ...history.map((item) => {
                position += OPTION_HEIGHT;
                return {
                    type: "history",
                    position,
                    ...item,
                };
            }),
        ]);
    }

    if (items.length > 0 && value === "" && history.length > 0) {
        position += OPTION_HEIGHT;
        itemsToRender.push({
            type: "title",
            position,
            text: "All",
        });
    }

    if (items.length === 0) {
        position += OPTION_HEIGHT;
        itemsToRender.push({
            type: "text",
            position,
            text: "No items",
        });
    }

    itemsToRender = itemsToRender.concat(
        items.map((item) => {
            position += OPTION_HEIGHT;
            return {
                type: "item",
                position,
                ...item,
            };
        })
    );
    return itemsToRender;
}

function handleScroll(element: HTMLElement, setScrollTop: (arg0: number) => void) {
    setScrollTop(element.scrollTop);
}

function handleFocus(
    props: SmartSelectProps,
    value: string,
    showDropDown: boolean,
    setShowDropDown: (arg0: boolean) => void,
    setActiveItem: (arg0: number) => void,
    setValue: (value: null | string) => void,
    setScrollTop: (arg0: number) => void
) {
    const { data, selectFields } = props;

    if (showDropDown) {
        const items = getItems(data, selectFields, value);
        setShowDropDown(showDropDown);
        setActiveItem(items[0] ? items[0].index : 0);
        setValue("");
        setScrollTop(0);
    } else {
        setShowDropDown(showDropDown);
        setActiveItem(-1);
        setValue(null);
    }
}

function handleValue(
    data: Data,
    selectFields: Array<string>,
    value: string,
    setActiveItem: (arg0: number) => void,
    setValue: (value: null | string) => void,
    setScrollTop: (arg0: number) => void
) {
    const items = getItems(data, selectFields, value);
    setValue(value);
    setActiveItem(items[0] ? items[0].index : 0);
    setScrollTop(0);
}

function handleKey(
    props: SmartSelectProps,
    key: string,
    value: string,
    activeItem: number,
    setShowDropDown: (arg0: boolean) => void,
    setActiveItem: (arg0: number) => void,
    setValue: (value: null | string) => void
) {
    const { data, selectFields } = props;
    const items = getItems(data, selectFields, value);

    switch (key) {
        case "ArrowDown":
            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if (item.index === activeItem) {
                    if (items[i + 1]) {
                        setActiveItem(items[i + 1].index);
                    }

                    break;
                }
            }

            break;

        case "ArrowUp":
            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if (item.index === activeItem) {
                    if (items[i - 1]) {
                        setActiveItem(items[i - 1].index);
                    }

                    break;
                }
            }

            break;

        case "Enter":
            if (activeItem > -1) {
                handleSelect(props, activeItem, setShowDropDown, setActiveItem, setValue);
            }

            break;
    }
}

function handleSelect(
    props: SmartSelectProps,
    index: number,
    setShowDropDown: (arg0: boolean) => void,
    setActiveItem: (arg0: number) => void,
    setValue: (value: null | string) => void
) {
    const { data, selectFields, onChange } = props;
    const row = data[index];
    setShowDropDown(false);
    setActiveItem(-1);
    setValue(selectFields.map((field) => row[field]).join(" - "));
    // TODO: async
    setHistory(props, index);
    onChange(row);
}

function renderItems(
    props: SmartSelectProps,
    items: Array<Item>,
    activeItem: number,
    scrollTop: number,
    setShowDropDown: (arg0: boolean) => void,
    setActiveItem: (arg0: number) => void,
    setValue: (value: null | string) => void
) {
    const { selectFields } = props;
    const positionFrom = scrollTop - OPTION_HEIGHT;
    const positionTo = scrollTop + SCROLL_HEIGHT;
    const itemsToRender = items.filter((item) => item.position > positionFrom && item.position < positionTo);
    return (
        <div
            style={{
                position: "relative",
                height: `${items.length * OPTION_HEIGHT}px`,
            }}
        >
            {itemsToRender.map((item) => {
                if (item.type === "title") {
                    return (
                        <div
                            key={`title${item.text}`}
                            style={{
                                position: "absolute",
                                top: `${item.position}px`,
                                padding: "0px 16px",
                                lineHeight: `${OPTION_HEIGHT}px`,
                                fontWeight: "bold",
                            }}
                        >
                            {item.text}
                        </div>
                    );
                } else if (item.type === "text") {
                    return (
                        <div
                            key={`text${item.text}`}
                            style={{
                                position: "absolute",
                                top: `${item.position}px`,
                                padding: "0px 16px",
                                lineHeight: `${OPTION_HEIGHT}px`,
                                textAlign: "center",
                                width: "100%",
                            }}
                        >
                            {item.text}
                        </div>
                    );
                } else {
                    return (
                        <Option
                            key={`${item.type}${item.index}`}
                            top={item.position}
                            row={item.row}
                            active={activeItem === item.index}
                            selectFields={selectFields}
                            onMouseEnter={() => setActiveItem(item.index)}
                            onMouseLeave={() => setActiveItem(-1)}
                            onMouseDown={() =>
                                handleSelect(props, item.index, setShowDropDown, setActiveItem, setValue)
                            }
                        />
                    );
                }
            })}
        </div>
    );
}
