import { memo } from "react";
import styled from "styled-components";

import {
    tableHeaderCellBaseCSS,
    TableHeaderCellBaseCSSOptions,
} from "components/ui/table-final-saviour/Table/TableHeaderCell/table-header-cell.styles";
import { Row, TableHeaderCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

type TableHeaderCellCSSOptions = TableHeaderCellBaseCSSOptions;

const TableHeaderCellStyled = styled.th<TableHeaderCellBaseCSSOptions>`
    ${tableHeaderCellBaseCSS};
`;

interface Props<T extends Row> extends TableHeaderCellBaseProps<T> {
    align?: TableHeaderCellBaseCSSOptions["$align"];
    width?: TableHeaderCellBaseCSSOptions["$width"];
}

const TableHeaderCell = <T extends Row>(props: Props<T>) => {
    return (
        <TableHeaderCellStyled className={props.className} $align={props.align} $width={props.width}>
            {props.children}
        </TableHeaderCellStyled>
    );
};

const TableHeaderCellMemo = memo(TableHeaderCell);

export { TableHeaderCellMemo as TableHeaderCell, TableHeaderCellStyled };
export type { Props as TableHeaderCellProps, TableHeaderCellCSSOptions };
