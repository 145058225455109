import { api as baseApi } from "store/api";

const api = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        putUserFeedback: builder.mutation<any, any>({
            query: ({ customerNo, params }) => ({
                url: `feedback/customer/${customerNo}`,
                method: "put",
                body: params,
            }),
        }),
    }),
});

export const { usePutUserFeedbackMutation } = api;
