import * as DialogPrimitive from "@radix-ui/react-dialog";
import clsx from "clsx";
import { forwardRef } from "react";
import styled from "styled-components";

import styles from "components/ui/Dialog/Dialog.module.css";
import { Icon } from "components/ui-deprecated";

type DialogWidth = "xd" | "sm" | "md" | "lg";

const widthMap: Record<DialogWidth, number> = {
    xd: 320,
    sm: 480,
    md: 800,
    lg: 1200,
};

const DialogPrimitiveContentStyled = styled(DialogPrimitive.Content)<
    DialogPrimitive.DialogContentProps & React.RefAttributes<HTMLDivElement> & { $width?: number }
>`
    ${({ $width }) => ($width ? `min-width: ${$width}px;` : "")}
`;

const DialogContent = forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitiveContentStyled> & { width?: DialogWidth }
>((props, ref) => {
    const { className, children, width, ...otherProps } = props;

    return (
        <DialogPrimitive.Portal>
            <DialogPrimitive.Overlay className={styles.DialogOverlay}>
                <DialogPrimitiveContentStyled
                    $width={widthMap[width]}
                    {...otherProps}
                    ref={ref}
                    className={clsx(className, styles.DialogContent)}
                >
                    {children}
                    <DialogPrimitive.Close className={styles.DialogClose}>
                        <Icon icon={"times"} className={styles.DialogCloseIcon} />
                    </DialogPrimitive.Close>
                </DialogPrimitiveContentStyled>
            </DialogPrimitive.Overlay>
        </DialogPrimitive.Portal>
    );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

export { DialogContent };
export type { DialogWidth };
