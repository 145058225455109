import { ChartData } from "components/ui/Chart/chart.types";

export const createRadarChartDataset = <T = unknown>(options: {
    label?: string;
    data: T;
    color: string;
    fill?: boolean;
}): ChartData<"radar", T>["datasets"][number] => ({
    type: "radar" as const,
    data: options.data,
    label: options.label,
    borderColor: options.color,
    fill: options.fill ?? true,
    backgroundColor: options.color + "33",
});
