import styled from "styled-components";

import { Link } from "components/ui/Link";
import { menuListItemCSS } from "components/ui/MenuList/MenuListItem";

export const MenuListLinkItem = styled(Link)`
    ${menuListItemCSS};

    color: ${({ theme }) => theme.color.textGray};

    &:hover {
        color: ${({ theme }) => theme.color.textGray};
    }
`;
