import { ExpandedContentSectionContent as _ExpandedContentSectionContent } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/Content/ExpandedContentSectionContent";
import { ExpandedContentSectionContentTitle } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/Content/ExpandedContentSectionTitle";

const ExpandedContentSectionContent = _ExpandedContentSectionContent as typeof _ExpandedContentSectionContent & {
    Title: typeof ExpandedContentSectionContentTitle;
};

ExpandedContentSectionContent.Title = ExpandedContentSectionContentTitle;

export { ExpandedContentSectionContent };
