import { ChartType } from "chart.js";

import { WidgetFrameContent } from "components/layout/WidgetFrame/WidgetFrameContent";
import { WidgetFrameDataContent } from "components/layout/WidgetFrame/WidgetFrameDataContent";
import { WidgetFrameNoDataFallback } from "components/layout/WidgetFrame/WidgetFrameNoDataFallback";
import { Chart } from "components/ui/Chart";
import { ChartProps } from "components/ui/Chart/Chart";
import { Dependency } from "components/ui/DataContent/data-content.types";

type Props<TType extends ChartType = ChartType> = ChartProps<TType> & {
    dependencies: Dependency[];
};

const WidgetFrameChart = <TType extends ChartType = ChartType>(props: Props<TType>) => {
    const { dependencies, data: chartData, ...chartProps } = props;

    const hasData = chartData.datasets.length > 0;

    return (
        <WidgetFrameContent>
            <WidgetFrameDataContent dependencies={dependencies}>
                <WidgetFrameNoDataFallback hasData={hasData}>
                    <Chart data={chartData} {...chartProps} />
                </WidgetFrameNoDataFallback>
            </WidgetFrameDataContent>
        </WidgetFrameContent>
    );
};

export { WidgetFrameChart };
