import styled, { css } from "styled-components";

const collapsedCSS = css`
    & > .title {
        display: none;
    }
`;

export const SidebarControlTriggerContent = styled.div`
    display: flex;
    align-content: center;
    gap: 1rem;

    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.0125rem;
    line-height: 1rem;

    white-space: nowrap;
    overflow: hidden;

    & > .datamole-ui-components-icon {
        font-size: 1rem;
    }

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && collapsedCSS};
`;
