import { api as baseApi } from "store/api";
import { GUID } from "types/common.types";

type SetInsightBody = {
    useful: boolean;
    insightId: string;
    insightCreatedDate: string;
    removeLines?: boolean;
};

type SetInsightLineBody = {
    useful: boolean;
    insightId: string;
    insightCreatedDate: string;
    insightLineId?: string;
};

type DeleteInsightLineParams = { useful: boolean; insightId: string; insightCreatedDate: string };

const TAG_INSIGHT = "TAG_INSIGHT";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_INSIGHT],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            setInsight: builder.mutation<void, { userId: GUID } & SetInsightBody>({
                query: ({ userId, ...data }) => ({
                    url: `insights/${userId}`,
                    method: "put",
                    body: data,
                }),
                invalidatesTags: () => [TAG_INSIGHT],
            }),
            setInsightLine: builder.mutation<void, { userId: GUID } & SetInsightLineBody>({
                query: ({ userId, ...data }) => ({
                    url: `insights/${userId}/line`,
                    method: "put",
                    body: data,
                }),
                invalidatesTags: () => [TAG_INSIGHT],
            }),
            deleteInsight: builder.mutation<void, { userId: GUID } & DeleteInsightLineParams>({
                query: ({ userId, ...data }) => ({
                    url: `insights/${userId}?${new URLSearchParams({
                        useful: String(data.useful),
                        insightId: data.insightId,
                        insightCreatedDate: data.insightCreatedDate,
                    }).toString()}`,
                    method: "delete",
                }),
                invalidatesTags: () => [TAG_INSIGHT],
            }),
        }),
    });

export const { useSetInsightMutation, useSetInsightLineMutation, useDeleteInsightMutation } = api;
