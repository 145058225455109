import { UserAreaBoundary } from "components/UserAreaBoundary";
import { Page } from "components/layout/Page";
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { RepeatVisitCasesKPIWidget } from "components/widgets/kpi/RepeatVisitCasesKPIWidget";
import { colSpan, height } from "components/widgets/widget.utils";
import { InsightsWidget } from "pages/DashboardPage/tss/widgets/InsightsWidget";
import { ServiceHistoryWidget } from "pages/DashboardPage/tss/widgets/ServiceHistoryTechnicianWidget";
import { AbnormalPMTimesKPIWidget } from "pages/DashboardPage/tss/widgets/kpi/AbnormalPMTimesKPIWidget";
import { ManHoursHighKPIWidget } from "pages/DashboardPage/tss/widgets/kpi/ManHoursHighKPIWidget";
import { PMsOverdueKPIWidget } from "pages/DashboardPage/tss/widgets/kpi/PMsOverdueKPIWidget";
import { ProductiveHoursKPIWidget } from "pages/DashboardPage/tss/widgets/kpi/ProductiveHoursKPIWidget";
import { RepairsPerAstronautKPIWidget } from "pages/DashboardPage/tss/widgets/kpi/RepairsPerAstronautKPIWidget";
import { CriticalAlarmsPerAstronautPast12MonthsKPIWidget } from "pages/DashboardPage/widgets/kpi/CriticalAlarmsPerAstronautPast12MonthsKPIWidget";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

export function TSSSubpage({ queryParameters }: Props) {
    return (
        <Page.Content.Widgets>
            <UserAreaBoundary areas={["TSS_TeamLead_Core"]}>
                <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                    <PMsOverdueKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                    <RepeatVisitCasesKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                    <RepairsPerAstronautKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                    <AbnormalPMTimesKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                    <ManHoursHighKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpi} height={height.kpi}>
                    <ProductiveHoursKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpiHalf} height={height.kpi}>
                    <CriticalAlarmsPerAstronautPast12MonthsKPIWidget queryParameters={queryParameters} />
                </WidgetFrame>
            </UserAreaBoundary>
            <WidgetFrame colSpan={colSpan.tableFull}>
                <InsightsWidget queryParameters={{}} />
            </WidgetFrame>
            <UserAreaBoundary areas={["TSS_Technician_Core"]}>
                <WidgetFrame colSpan={colSpan.tableFull}>
                    <ServiceHistoryWidget queryParameters={queryParameters} />
                </WidgetFrame>
            </UserAreaBoundary>
        </Page.Content.Widgets>
    );
}
