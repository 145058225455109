import styled from "styled-components";

import { config } from "config";

const LoginPageTitleStyled = styled.div`
    font-size: 37px;
    line-height: 40px;

    & > div:last-child {
        font-size: 48px;
        line-height: 48px;
        font-weight: 500;
    }
`;

const LoginPageTitle = () => {
    return (
        <LoginPageTitleStyled>
            <div>Welcome to</div>
            <div>{config.APP_NAME}</div>
        </LoginPageTitleStyled>
    );
};

export { LoginPageTitle };
