import { css } from "styled-components";

const collapsedCSS = css`
    padding-left: 3.375rem;

    & .title {
        display: none;
    }
`;

export const sidebarNavigationItemCSS = css`
    padding: 1rem 2rem;

    display: flex;
    align-items: center;

    background-color: ${({ theme }) => theme.color.lelyRed};

    color: ${({ theme }) => theme.color.white};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.0125rem;
    text-decoration: none;

    cursor: pointer;

    transition: background-color 0.25s;

    &:hover {
        background-color: ${({ theme }) => theme.color.lelyRedDark};

        color: ${({ theme }) => theme.color.white};
    }

    &.active {
        background-color: ${({ theme }) => theme.color.lelyRedDark};

        color: ${({ theme }) => theme.color.white};

        font-weight: 700;
    }

    & > .datamole-ui-components-icon {
        margin-right: 1rem;

        font-size: 1rem;
    }

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && collapsedCSS};
`;
