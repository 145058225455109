import styled from "styled-components";

import { KPIColor } from "components/ui/KPI/kpi.consts";
import { formatKPIValue, resolveKPIColor } from "components/ui/KPI/kpi.utils";
import { notNil } from "utils/validation.utils";

const KPILastValueStyled = styled.div`
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const TitleStyled = styled.div`
    margin-bottom: 0.25rem;

    font-size: 0.75rem;
    line-height: 1;
`;

const ValueStyled = styled.div`
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 600;
`;

const Circle = styled.div<{ $color: string }>`
    display: inline-block;
    border-radius: 50%;
    height: 0.6rem;
    width: 0.6rem;
    border: 3px solid ${({ $color }) => $color};
    margin-right: 0.5rem;
`;

type Props = {
    title: string;
    value: number | string;
    color?: KPIColor;
};

export const KPILastValue = (props: Props) => {
    const { title, value: rawValue } = props;
    const color = resolveKPIColor(props?.color);

    let value: string;
    if (notNil(rawValue)) {
        value = typeof rawValue === "number" ? formatKPIValue(rawValue) : rawValue;
    } else {
        value = "No data";
    }

    return (
        <KPILastValueStyled>
            <TitleStyled>
                <Circle $color={color} />
                {title}
            </TitleStyled>
            <ValueStyled>{value}</ValueStyled>
        </KPILastValueStyled>
    );
};
