import styled from "styled-components";

export const NavbarButton = styled.button`
    flex-shrink: 0;

    height: 40px;
    width: 40px;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: 1px solid ${({ theme }) => theme.color.grayDairyLight};
    border-radius: 4px;

    &:hover {
        background-color: #f7f6f4;
    }

    & > svg {
        font-size: 1rem;
        color: ${({ theme }) => theme.color.textGray};
    }
`;
