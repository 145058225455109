import { SearchResultListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultListItem";
import { CustomerRow } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = {
    data: CustomerRow;
    onClick: () => void;
};

export const CustomerListItem = (props: Props) => {
    const { data } = props;

    return (
        <SearchResultListItem
            label={[data.Name, data.City].join(" - ")}
            chip={data.CustomerNumber}
            title={"Customer"}
            url={generatePath(routes.customerDetail, {
                params: {
                    customerNumber: data.CustomerNumber,
                },
            })}
            icon={"lely-icon-human"}
            onClick={props.onClick}
        />
    );
};
