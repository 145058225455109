import { Chart as ChartJS, ChartType, registerables } from "chart.js";
import { useLayoutEffect, useRef } from "react";

import { ChartConfiguration, ChartData } from "components/ui/Chart/chart.types";
import { CHART_FONT } from "components/ui/Chart/chart.utils";

import "chartjs-adapter-date-fns";

ChartJS.register(...registerables);
ChartJS.defaults.font = {
    family: CHART_FONT,
    size: 12,
    lineHeight: 1,
    weight: null,
    style: "normal",
};
ChartJS.defaults.color = "#000000";

type Props<TType extends ChartType = ChartType> = {
    configuration: ChartConfiguration<TType>;
    data: ChartData<TType>;
};

const Chart = <TType extends ChartType = ChartType>(props: Props<TType>) => {
    const { configuration, data } = props;
    const chartRef = useRef<HTMLCanvasElement>(null);

    useLayoutEffect(() => {
        if (!chartRef.current) return;
        const ch = new ChartJS(chartRef.current, { ...configuration, data });

        return () => {
            ch.destroy();
        };
    }, [configuration, data]);

    return <canvas ref={chartRef} />;
};

export { Chart };
export type { Props as ChartProps };
