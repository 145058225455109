import styled from "styled-components";

import { PAGE_MAX_WIDTH } from "components/layout/Page/page.utils";

export const FmsReportNavbarContent = styled.div`
    width: ${PAGE_MAX_WIDTH}px;
    padding: 0 3rem;

    display: flex;
    align-items: center;
    gap: 1rem;
`;
