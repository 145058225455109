import { useEffect, useState } from "react";

import { SIZE_SMALL } from "components/layout/layout.consts";

const SIDEBAR_COLLAPSED_THRESHOLD = SIZE_SMALL;

export const useSidebarCollapse = () => {
    const [isUserCollapsed, setIsUserCollapsed] = useState<boolean | null>(null);
    const [isWidthCollapsed, setIsWidthCollapsed] = useState<boolean>(false);
    const sidebarCollapsed = isUserCollapsed === null ? isWidthCollapsed : isUserCollapsed;

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth;
            const shouldBeWidthCollapsed = width < SIDEBAR_COLLAPSED_THRESHOLD;

            if (shouldBeWidthCollapsed !== isWidthCollapsed) {
                setIsWidthCollapsed(shouldBeWidthCollapsed);
                setIsUserCollapsed(shouldBeWidthCollapsed);
            }
        }

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isWidthCollapsed]);

    return {
        sidebarCollapsed,
        closeSidebar: () => setIsUserCollapsed(true),
        toggleCollapsedSidebar: () => setIsUserCollapsed((prev) => prev === null || prev === false),
    };
};
