import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { config } from "config";
import { getFmsReportToken } from "services/fms-report/fms-report.service";

const prepareHeaders = (headers: Headers) => {
    const fmsReportToken = getFmsReportToken();

    if (fmsReportToken) {
        headers.set("authorization", fmsReportToken);
    }

    return headers;
};

export const fmsReportBaseQuery = fetchBaseQuery({
    baseUrl: `${config.API_URL}api/FarmScanReport/`,
    prepareHeaders,
});
