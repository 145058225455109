import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import duration from "dayjs/plugin/duration";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(utc);

export const dateFormat = "YYYY-MM-DD";

export const today = dayjs().startOf("day");
export const yesterday = today.subtract(1, "day");
export const startOfWeekDay = today.startOf("week");
export const weekAgo = today.subtract(1, "week");
export const startOfMonthDay = today.startOf("month");
export const monthAgo = today.subtract(1, "month");
export const startOfYearDay = today.startOf("year");
export const isTodayLastDayOfMonth = today.date() === today.endOf("month").date();

export { dayjs, Dayjs };
