import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

const smSizeCSS = css`
    height: 1.5rem;
    margin: -0.125rem 0;
`;

const WidgetFrameTitleActionsStyled = styled.div`
    flex-grow: 1;

    height: 2rem;
    min-width: max-content;
    margin: -0.25rem 0;

    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    ${({ theme: { widgetSize } }) => widgetSize === "sm" && smSizeCSS};
`;

type Props = PropsWithChildren;

const WidgetFrameTitleActions = (props: Props) => {
    return <WidgetFrameTitleActionsStyled>{props.children}</WidgetFrameTitleActionsStyled>;
};

export { WidgetFrameTitleActions };
