import styled from "styled-components";

import { useGetUserQuery } from "store/services/user.service";

const WelcomeMessageStyled = styled.div`
    flex-shrink: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: none;
    }
`;

const WelcomeMessage = () => {
    const { data: user, isFetching } = useGetUserQuery();
    const text = isFetching ? "Loading user..." : `Welcome, ${user?.name}`;

    return <WelcomeMessageStyled>{text}</WelcomeMessageStyled>;
};

export { WelcomeMessage };
