import { useState } from "react";
import styled from "styled-components";

import { LoginError } from "pages/LoginPage/LoginError";
import { LoginForm } from "pages/LoginPage/LoginForm";
import { LoginPageLogo } from "pages/LoginPage/LoginPageLogo";
import { LoginPageTitle } from "pages/LoginPage/LoginPageTitle";
import backgroundImage from "pages/LoginPage/login-background.jpg";
import { FormValues, LoginErrorType } from "pages/LoginPage/login-page.types";
import { AuthError } from "services/auth/auth.errors";
import { login } from "services/auth/auth.service";
import { useAppDispatch } from "store";
import { authenticate } from "store/slices/auth.slice";

const LoginPageStyled = styled.div`
    .image {
        height: 100%;
        width: 100%;
        position: fixed;
        background-image: url(${backgroundImage});
        background-size: cover;
        background-position: center;
        top: 0;
        left: 0;
    }

    .panel {
        min-height: 100vh;

        width: calc(19vw + 400px);
        position: relative;

        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        & > div {
            display: flex;
            flex-flow: column;
            gap: 48px;

            padding-top: 48px;
            padding-bottom: 48px;
            padding-right: 48px;
        }

        padding-left: 14vw;
        padding-right: 5vw;

        color: white;
        background-color: ${({ theme }) => theme.color.lelyRed};

        // red triangle
        &::after {
            content: "";

            position: fixed;
            top: 0;
            left: calc(19vw + 400px);

            height: 100%;

            border-left: 15vw solid ${({ theme }) => theme.color.lelyRed};
            border-top: 50vh solid transparent;
            border-bottom: 50vh solid transparent;
        }
    }
`;

const LoginPage = () => {
    const [error, setError] = useState<LoginErrorType | null>(null);
    const dispatch = useAppDispatch();

    const handleLogin = async (data: FormValues) => {
        setError(null);

        try {
            const res = await login(data);
            dispatch(authenticate(res));
        } catch (err) {
            if (err instanceof AuthError) {
                if (err.data.error_description === "Your user name or password is invalid.") {
                    setError("invalidLogin");
                } else if (err.data.error_description === "Too many login attempts. Please try again later.") {
                    setError("tooManyLoginAttempts");
                } else if (err.data.error_description === "Your password has expired.") {
                    setError("expiredPassword");
                } else {
                    setError("serverError");
                }
            } else {
                setError("serverError");
            }
        }
    };

    return (
        <LoginPageStyled>
            <div className="image" />
            <div className="panel">
                <div>
                    <LoginPageLogo />
                    <LoginPageTitle />
                    <div>
                        {error && <LoginError type={error} />}
                        <LoginForm onSubmit={handleLogin} />
                    </div>
                </div>
            </div>
        </LoginPageStyled>
    );
};

export { LoginPage };
