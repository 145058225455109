import styled, { css } from "styled-components";

export const smSizeCSS = css`
    padding: 0.75rem 1.5rem 1rem 1.5rem;

    gap: 0.75rem;

    & > .icon-wrapper {
        height: 1.25rem;
    }

    & > .icon-wrapper > .icon {
        height: 1rem;
        font-size: 1rem;
    }

    & > .title-wrapper > .title {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
    }

    & > .content {
        gap: 1rem;
    }
`;

export const WidgetFrameTitleStyled = styled.div`
    position: relative;

    width: 100%;
    padding: 1rem 1.5rem 1rem 1.5rem;

    display: grid;
    grid-template-columns: max-content minmax(min-content, max-content) minmax(min-content, 1fr);
    align-items: flex-start;

    @container page (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        grid-template-columns: max-content 1fr;
    }

    gap: 1rem;

    & > .icon-wrapper {
        height: 1.5rem;
        display: flex;
        align-items: center;

        .icon {
            height: 1.25rem;
            color: ${({ theme }) => theme.color.textGrayDairyDarker};
            font-size: 1.25rem;
        }
    }

    & > .title-wrapper {
        .title {
            color: ${({ theme }) => theme.color.textGray};

            font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: 500;
        }

        .title-link {
            color: ${({ theme }) => theme.color.textGray};

            white-space: nowrap;
            text-decoration: underline;

            &:hover {
                color: ${({ theme }) => theme.color.textGray};

                text-decoration: none;
            }

            span {
                white-space: pre-line;
            }

            svg {
                margin-left: 0.5em;
                vertical-align: unset;

                color: ${({ theme }) => theme.color.textGrayDairyDarker};

                font-size: 0.75em;
            }
        }

        .subtitle {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: ${({ theme }) => theme.color.textGrayDairyDarker};
        }
    }

    & > .content {
        display: flex;
        align-items: flex-start;
        gap: 1.25rem;

        &:empty {
            display: none;
        }

        @container page (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            grid-column: span 2;
        }
    }

    ${({ theme: { widgetSize } }) => widgetSize === "sm" && smSizeCSS};
`;

export const IconWrapperStyled = styled.div`
    height: 1.5rem;
    width: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        height: 1.5rem;
        width: 1.5rem;
    }
`;
