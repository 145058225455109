import { forwardRef } from "react";
import styled from "styled-components";

import { TableHeadProps } from "components/ui/table-final-saviour/Table/TableHead/table-head.types";

type Props = TableHeadProps;

const Separator = styled.tr`
    width: 100%;
    height: 2px;

    background-color: ${({ theme }) => theme.color.lelyRed};
`;

const TableHead = forwardRef<HTMLTableSectionElement, Props>((props, ref) => {
    const { children, columns, ...otherProps } = props;

    return (
        <thead {...otherProps} ref={ref}>
            {children}
            <Separator>
                <td colSpan={columns.length} />
            </Separator>
        </thead>
    );
});

TableHead.displayName = "TableHead";

export { TableHead };
