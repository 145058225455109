import dayjs from "dayjs";

import { KPIChartDataPoint } from "components/ui/KPI/charts/KPIChart/kpi-chart.types";
import { formatKPIValue } from "components/ui/KPI/kpi.utils";

type ToKPIChartDataPointsOptions = {
    dateKey?: string;
    valueKey?: string;
    getLabel?: (value: any, options?: { suffix?: string }) => string;
};
type ToKPIChartDataPointsReturn = {
    data: KPIChartDataPoint[];
    lastDataPoint: KPIChartDataPoint | null;
};
export const toKPIChartDataPoints = <T extends Record<string, any>>(
    data: T[],
    options?: ToKPIChartDataPointsOptions
): ToKPIChartDataPointsReturn => {
    if (data.length === 0) return { data: [], lastDataPoint: null };

    const dateKey = options?.dateKey ?? "ChartDate";
    const valueKey = options?.valueKey ?? "ChartValue";
    const firstRecord = data.at(0);
    if (!firstRecord) return { data: [], lastDataPoint: null };

    const hasDateInFirstRecord = dateKey in firstRecord;
    const hasValueInFirstRecord = valueKey in firstRecord;
    if (!hasDateInFirstRecord || !hasValueInFirstRecord) return { data: [], lastDataPoint: null };

    const dataPoints = data
        .map((item) => {
            const value = item[valueKey];

            let label: string;
            if (options?.getLabel) {
                label = options.getLabel(value);
            } else {
                label = formatKPIValue(value);
            }

            return { date: item[dateKey], value, label };
        })
        .sort((a, b) => dayjs(a.date).diff(b.date));

    const lastDataPoint = dataPoints.at(-1);
    return { data: dataPoints, lastDataPoint };
};
