import styled from "styled-components";

import { SearchResultListItemChip } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultListItemChip";
import { Link } from "components/ui/Link";
import { Icon } from "components/ui-deprecated";

const SearchResultListItemStyled = styled(Link)`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    padding: 0.25rem 1.5rem;

    font-weight: 500;
    text-decoration: none;

    color: ${({ theme }) => theme.color.textGray};

    &:hover {
        background-color: ${({ theme }) => theme.color.dairyLight};
        color: inherit;
    }

    & > span {
        color: ${({ theme }) => theme.color.grayDairyDarker};
    }
`;

type Props = {
    label: string;
    chip?: string | number;
    title?: string;
    url: string;
    icon: string;
    onClick: () => void;
    className?: string;
};

const SearchResultListItem = (props: Props) => {
    return (
        <SearchResultListItemStyled
            to={props.url}
            onClick={props.onClick}
            className={props.className}
            title={props.title}
        >
            <Icon icon={props.icon} />
            {props.label}
            {props.chip && <SearchResultListItemChip>{props.chip}</SearchResultListItemChip>}
        </SearchResultListItemStyled>
    );
};

export { SearchResultListItem };
