import * as PopoverPrimitive from "@radix-ui/react-popover";

import { PopoverContent } from "components/ui/DatePicker/Popover/PopoverContent";

const Popover = PopoverPrimitive.Root as typeof PopoverPrimitive.Root & {
    Content: typeof PopoverContent;
    Trigger: typeof PopoverPrimitive.Trigger;
};

Popover.Content = PopoverContent;
Popover.Trigger = PopoverPrimitive.Trigger;

export { Popover };
