import styled from "styled-components";

import { NavbarButton } from "components/layout/Navbar/NavbarButton";

export const NotificationsOpenButton = styled(NavbarButton)`
    position: relative;

    font-size: 1.25rem;

    cursor: pointer;

    svg {
        color: ${({ theme }) => theme.color.lelyRed};
        transition: 0.2s;
    }

    &:hover {
        svg {
            animation: notification-hover 0.5s;
        }
    }

    @keyframes notification-hover {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(-15deg);
        }
        50% {
            transform: rotate(+15deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
`;
