import { WidgetFrame as _WidgetFrame } from "components/layout/WidgetFrame/WidgetFrame";
import { WidgetFrameChart } from "components/layout/WidgetFrame/WidgetFrameChart";
import { WidgetFrameContent } from "components/layout/WidgetFrame/WidgetFrameContent";
import { WidgetFrameDataContent } from "components/layout/WidgetFrame/WidgetFrameDataContent";
import { WidgetFrameKPI } from "components/layout/WidgetFrame/WidgetFrameKPI";
import { WidgetFrameNoDataFallback } from "components/layout/WidgetFrame/WidgetFrameNoDataFallback";
import { WidgetFrameTable } from "components/layout/WidgetFrame/WidgetFrameTable";
import { WidgetFrameTableExpandable } from "components/layout/WidgetFrame/WidgetFrameTableExpandable";
import { WidgetFrameTitle } from "components/layout/WidgetFrame/WidgetFrameTitle";

const WidgetFrame = _WidgetFrame as typeof _WidgetFrame & {
    Content: typeof WidgetFrameContent;
    DataContent: typeof WidgetFrameDataContent;
    KPI: typeof WidgetFrameKPI;
    NoDataFallback: typeof WidgetFrameNoDataFallback;
    Table: typeof WidgetFrameTable;
    TableExpandable: typeof WidgetFrameTableExpandable;
    Chart: typeof WidgetFrameChart;
    Title: typeof WidgetFrameTitle;
};

WidgetFrame.Content = WidgetFrameContent;
WidgetFrame.DataContent = WidgetFrameDataContent;
WidgetFrame.KPI = WidgetFrameKPI;
WidgetFrame.NoDataFallback = WidgetFrameNoDataFallback;
WidgetFrame.Table = WidgetFrameTable;
WidgetFrame.TableExpandable = WidgetFrameTableExpandable;
WidgetFrame.Chart = WidgetFrameChart;
WidgetFrame.Title = WidgetFrameTitle;

export type { WidgetFrameProps } from "components/layout/WidgetFrame/WidgetFrame";
export { WidgetFrame };
