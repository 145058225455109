import type { KPIChartDataPoint, KPIChartThreshold } from "components/ui/KPI/charts/KPIChart/kpi-chart.types";
import { KPIColor } from "components/ui/KPI/kpi.consts";
import { resolveKPIColor } from "components/ui/KPI/kpi.utils";

type ThresholdCondition = "lower" | "upper";
type Threshold = { type: "threshold"; value: number; regionIfEqual: ThresholdCondition };
type Region = { type: "region"; color: KPIColor };
export type Regions = [Region, ...(Threshold | Region)[], Region];

/**
 * find in which region the value is and return the region
 *
 * on index `1` is the threshold with the biggest value
 * on index `options.regions - 1` is the threshold with the smallest value
 * regions has format
 *
 * ```
 * [
 *    region,
 *    threshold,
 *    region,
 *    threshold,
 *    ...,
 *    region
 * ]
 * ```
 *
 * if value is equal to threshold value, the regionIfEqual is used to determine the region - lower or upper
 *
 * Example: `i` is an index in `regions` array:
 *  - lower - `regions[i + 1]`
 *  - upper - `regions[i - 1]`
 *
 * @param options.regions - regions and thresholds in order __from biggest to smallest__ value, on index 0 and __even indexes are regions__, on __odd indexes are thresholds__
 */
export const getActiveRegion = (options: { regions: Regions; lastDataPoint: KPIChartDataPoint }): Region | null => {
    const { regions, lastDataPoint } = options;

    if (!lastDataPoint) return null;

    const region = regions.find((region, index) => {
        // only iterate over regions
        if (region.type === "threshold") {
            return false;
        }

        // value is smaller than the smallest threshold, therefore it is in the last region
        if (index === regions.length - 1) {
            return true;
        }

        /**
         * current region is above the next threshold and under the previous threshold
         *
         * [
         *  ...
         *  prevThreshold, // index - 1
         *  currentRegion, // index
         *  nextThreshold, // index + 1
         *  ...
         * ]
         */
        const nextThreshold = regions[index + 1] as Threshold;

        if (nextThreshold.value === lastDataPoint.value && nextThreshold.regionIfEqual === "upper") {
            return true;
        }

        // if value is bigger than the biggest threshold it is in the first region
        if (index === 0) {
            return nextThreshold.value < lastDataPoint.value;
        }

        const prevThreshold = regions[index - 1] as Threshold;

        if (prevThreshold.value === lastDataPoint.value && prevThreshold.regionIfEqual === "lower") {
            return true;
        }

        return nextThreshold.value < lastDataPoint.value && lastDataPoint.value < prevThreshold.value;
    }) as Region;

    return region;
};

export const getThresholdsFromRegions = (options: { regions: Regions; lastDataPoint: KPIChartDataPoint }) => {
    const { regions, lastDataPoint } = options;

    if (!lastDataPoint) return [];

    return regions.reduce((acc, curr, index) => {
        if (curr.type === "region") return acc;

        const isValueHigherThanThreshold =
            curr.value > lastDataPoint.value || (curr.value === lastDataPoint.value && curr.regionIfEqual === "lower");

        const color = isValueHigherThanThreshold
            ? (regions[index - 1] as Region).color
            : (regions[index + 1] as Region).color;

        acc.push({
            value: curr.value,
            color: resolveKPIColor(color),
        });

        return acc;
    }, []);
};

export const filterThresholds = (props: {
    dataMin: number;
    dataMax: number;
    lastValue: number;
    thresholds?: KPIChartThreshold[];
}): KPIChartThreshold[] => {
    const { lastValue, dataMin, dataMax } = props;

    const thresholdsSorted = props.thresholds?.sort((a, b) => a.value - b.value) ?? [];
    const thresholds = thresholdsSorted.filter(({ value }) => value >= dataMin && value <= dataMax);

    const thresholdSmaller = thresholdsSorted.findLast(({ value }) => value < lastValue);
    if (thresholdSmaller) {
        thresholds.push(thresholdSmaller);
    }

    const thresholdBigger = thresholdsSorted.find(({ value }) => value > lastValue);
    if (thresholdBigger) {
        thresholds.push(thresholdBigger);
    }

    return thresholds;
};
