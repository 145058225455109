import { PageNavigation as _PageNavigation } from "components/layout/Page/PageNavigation/PageNavigation";
import { PageNavigationRoutes } from "components/layout/Page/PageNavigation/PageRoutes/PageNavigationRoutes";

const PageNavigation = _PageNavigation as typeof _PageNavigation & {
    Routes: typeof PageNavigationRoutes;
};

PageNavigation.Routes = PageNavigationRoutes;

export { applyPageNavigationContext } from "components/layout/Page/PageNavigation/page-navigation.utils";
export type {
    PageNavigationContext,
    PageNavigationItem,
} from "components/layout/Page/PageNavigation/page-navigation.types";
export { PageNavigation };
