import styled from "styled-components";

export const WidgetFrameTitleInformationTooltip = styled.div`
    position: absolute;
    bottom: calc(100% + 0.5rem);
    right: -0.5rem;
    z-index: 1;

    width: max-content;
    max-width: 20rem;
    padding: 0.5rem 0.75rem;

    opacity: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    background-color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
    box-shadow: 0 2px 5px ${({ theme }) => theme.color.grayDairyLight};

    font-size: 0.75rem;
    line-height: 0.875rem;

    pointer-events: none;

    transition: all ease-in 0.25s;

    & > * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &::after {
        content: "";

        position: absolute;
        bottom: -0.75rem;
        right: 0.75rem;

        transform-origin: 0 0;
        transform: rotate(-45deg);

        width: 0;
        height: 0;

        display: inline-block;

        border: 0.5em solid ${({ theme }) => theme.color.white};
        border-color: transparent transparent ${({ theme }) => theme.color.white} ${({ theme }) => theme.color.white};
        box-shadow: 0 2px 5px ${({ theme }) => theme.color.grayDairyLight};
    }
`;
