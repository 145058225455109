import { Page } from "components/layout/Page";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { FeedingSection } from "pages/DashboardPage/fms/sections/FeedingSection";
import { ManagementSoftwareSection } from "pages/DashboardPage/fms/sections/ManagementSoftwareSection";
import { MilkingSection } from "pages/DashboardPage/fms/sections/MilkingSection";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

export function FMSSubpage({ queryParameters }: Props) {
    return (
        <Page.Content.Sections>
            <MilkingSection queryParameters={queryParameters} />
            <FeedingSection queryParameters={queryParameters} />
            <ManagementSoftwareSection queryParameters={queryParameters} />
        </Page.Content.Sections>
    );
}
