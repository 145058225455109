import { PageRoute } from "components/layout/Page/PageNavigation/PageRoutes/page-routes.types";
import { SubPage } from "pages/pages.types";
import { SubRoute } from "routes/routes.types";

export const getPageRoutes = <
    TRoute extends Record<TKey, SubRoute>,
    TPage extends Record<TKey, SubPage>,
    TKey extends string,
>({
    subPages,
    subRoutes,
}: {
    subPages: TPage;
    subRoutes: TRoute;
}): PageRoute[] => {
    const routeNames = Object.keys(subRoutes) as TKey[];
    const routeNamesWithPage = routeNames.filter((routeName) => subPages[routeName] !== undefined);
    return routeNamesWithPage.map((routeName) => {
        const route = subRoutes[routeName];
        const path = route.path;

        const page = subPages[routeName];
        const Component = page.Component;

        const subPageRoutes = getPageRoutes({ subPages: page.subPages ?? {}, subRoutes: route.subRoutes ?? {} });

        return { path, Component, subPageRoutes };
    });
};
