import { TableCellExpandAction } from "components/ui/table-final-saviour/Table/TableCell/TableCellExpandAction";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";

type Options = {
    getIsExpandedRow: (rowIndex: number) => boolean;
    toggleExpandedRow: (rowIndex: number) => void;
};

export const createActionExpandColumn = <T extends Row>(options: Options): Column<T> => {
    const { getIsExpandedRow, toggleExpandedRow } = options;

    return {
        _type: "action-expand",
        components: {
            TableHeaderCell: (props) => <TableHeaderCell {...props} width={64} />,
            TableCell: (props) => (
                <TableCellExpandAction
                    {...props}
                    isExpanded={getIsExpandedRow(props.rowIndex)}
                    onExpand={() => toggleExpandedRow(props.rowIndex)}
                />
            ),
        },
    };
};
