import styled from "styled-components";

import { Icon } from "components/ui-deprecated";

const ErrorPageStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    padding: 3rem;

    background-color: ${({ theme }) => theme.color.dairyLight};
`;

const Container = styled.div`
    max-width: 600px;
    padding: 1rem 1.5rem;

    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.grayDairy};
    border-radius: 0.5rem;
    box-shadow: 0px 2px 5px #dedbd666;
`;

const Title = styled.div`
    margin-bottom: 1rem;

    display: flex;
    align-items: center;

    font-size: 1.25rem;
    font-weight: 500;
    color: ${({ theme }) => theme.color.textGray};
`;

const IconStyled = styled(Icon)`
    margin-right: 0.5rem;

    color: ${({ theme }) => theme.color.red};
`;

const Content = styled.div``;

const ErrorPage = () => {
    return (
        <ErrorPageStyled>
            <Container>
                <Title>
                    <IconStyled icon={"exclamation-circle"} />
                    Error Occurred
                </Title>
                <Content>
                    The application encountered an unexpected error.
                    <br />
                    Please try again later or contact support at{" "}
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>.
                </Content>
            </Container>
        </ErrorPageStyled>
    );
};

export { ErrorPage };
