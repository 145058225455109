import styled from "styled-components";

import { NavLink } from "components/ui/NavLink";

const PageNavigationTabItem = styled(NavLink)`
    padding: 0.5rem 1rem;

    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    color: ${({ theme }) => theme.color.textGrayDairyDarker};

    cursor: pointer;
    transition: none;

    &:hover {
        background-color: ${({ theme }) => theme.color.dairyLight};
        color: unset;
    }

    &.active {
        background-color: ${({ theme }) => theme.color.lelyRed};

        color: ${({ theme }) => theme.color.white};
        font-weight: 500;

        &:hover {
            background-color: ${({ theme }) => theme.color.lelyRedDark};
        }
    }
`;

export { PageNavigationTabItem };
