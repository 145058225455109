import { Button } from "@datamole/wds-component-button";

import { BooleanFilter } from "components/ui/BooleanFilters/boolean-filters.types";
import { ButtonGroup } from "components/ui/ButtonGroup";

type Props<T extends string> = {
    filters: BooleanFilter<T>[];
    activeFilters: T[];
    setActiveFilter: (filter: T) => void;
};

const BooleanFilters = (props: Props<any>) => {
    const { filters, activeFilters, setActiveFilter } = props;

    return (
        <ButtonGroup>
            {filters.map((filter) => (
                <Button
                    key={filter.key}
                    variant={activeFilters.includes(filter.key) ? "primary" : "outline"}
                    onClick={() => setActiveFilter(filter.key)}
                >
                    {filter.title}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export { BooleanFilters };
export type { Props as BooleanFiltersProps };
