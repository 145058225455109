import { utcFormat } from "d3-time-format";
import moment from "moment";

import { Units } from "deprecated/hooks/user.hooks";
import { store } from "store";

export type FormatConfig = {
    // String is defined by: https://github.com/d3/d3-format

    /**
     * Format specifier for all numbers. If null, no formatting is performed on numbers.
     * If "default", our default number formatter is used.
     * If "localeString", browser implementation of `toLocaleString` is used.
     * If string is `/(.*)\.([0-9]*)f(.*)/`, the string before, toFixed(number) and string after will be used.
     * If string is `/(.*)\.([0-9]*)e(.*)/`, the string before, toExponential(number) and
     * string after will be used if is there exponential necessary.
     */
    formatOfNumbers?: null | "default" | "localeString" | "ok" | string;

    /**
     * Format specifier for all strings. If null, string is displayed as it is.
     * If string, then this string is used as a replacement for the value.
     */
    formatOfStrings?: null | string;

    /**
     * Format specifier for all dates. If null, ISO string of the date is used.
     * If string, formatting is performed according to d3 (https://github.com/d3/d3-time-format)
     * time format string specification.
     */
    formatOfDates?: null | string;

    /**
     * Format specifier for all booleans. If null, no formatting is performed on booleans
     * (that is, either "true" or "false" is displayed).
     * If "number", true is is displayed as "1" and false is displayed as "0".
     */
    formatOfBooleans?: null | "ok" | "number" | string;

    /**
     * Format specifier for null value. If null, empty value is displayed.
     * If "string", literal value "null" is displayed.
     */
    formatOfNull?: null | "string";

    /**
     * Format specifier for undefined value. If null, empty value is displayed.
     * If "string", literal value "undefined" is displayed.
     */
    formatOfUndefined?: null | "string";

    /**
     * Format specifier for all objects. If null, implementation of `toString` method is used
     * (in most cases, this will display "[object Object]").
     * If "json", the object is stringified into JSON format.
     */
    formatOfObjects?: null | "json";

    /**
     * Format specifier for all arrays. If null, implementation of `toString` method is used
     * (in most cases, this will display comma-separated values without spaces after commas).
     * If "json", the object is stringified into JSON format.
     * If "comma", comma-separated values with a space after commas are displayed.
     * If string, the array is joined to string and the value of this string is used as the separator.
     */
    formatOfArrays?: null | "json" | "comma" | string;

    /**
     * Format specifier for fields. This overrides default format for specified fields.
     * The format should be valid format according to field's data type.
     */
    formatOfFields?: Record<string, null | string>;
};
export type Formatter = (value: unknown, field?: string) => string;
export const defaultConfig: FormatConfig = {
    formatOfNumbers: ".2f",
    formatOfStrings: null,
    formatOfDates: "%Y-%m-%d %H:%M:%S",
    formatOfBooleans: null,
    formatOfNull: null,
    formatOfUndefined: null,
    formatOfObjects: "json",
    formatOfArrays: "comma",
    formatOfFields: {},
};

/**
 * Example of using:
 * import { format } from "core/data-utils";
 *
 * let myFormat = format({ formatOfNumbers: ".1f", formatOfDates: "%Y-%m-%dT%H:%M:%S.%LZ" });
 *
 * myFormat(0.00001423);
 * myFormat(new Date());
 */

export default function format(config: FormatConfig): Formatter {
    const {
        formatOfNumbers,
        formatOfDates,
        formatOfStrings,
        formatOfBooleans,
        formatOfObjects,
        formatOfArrays,
        formatOfUndefined,
        formatOfNull,
        formatOfFields,
    } = { ...defaultConfig, ...config };
    let numberFormatter: (value: number) => string;
    let stringFormatter: (value: string) => string;
    let dateFormatter: (value: Date) => string;
    let booleanFormatter: (value: boolean) => string;
    let undefinedFormatter: (value: unknown) => string;
    let nullFormatter: (value: null) => string;
    let arrayFormatter: (value: ReadonlyArray<any>) => string;
    let objectFormatter: (value: any) => string;

    const formatter = (value: any, field?: string): string => {
        if (typeof field !== "undefined" && typeof formatOfFields[field] !== "undefined") {
            if (typeof value === "number") {
                return format({
                    formatOfNumbers: formatOfFields[field],
                })(value);
            }

            if (typeof value === "string") {
                return format({
                    formatOfStrings: formatOfFields[field],
                })(value);
            }

            if (value instanceof Date) {
                return format({
                    formatOfDates: formatOfFields[field],
                })(value);
            }

            if (typeof value === "boolean") {
                switch (formatOfFields[field]) {
                    case "number":
                        return format({
                            formatOfBooleans: "number",
                        })(value);

                    case "ok":
                        return format({
                            formatOfBooleans: "ok",
                        })(value);

                    default:
                        return format({
                            formatOfBooleans: null,
                        })(value);
                }
            }
        }

        if (typeof value === "number") {
            return numberFormatter(value);
        } else if (typeof value === "string") {
            const format = "YYYY-MM-DDTHH:mm:ss";
            const secondaryFormat = "YYYY-MM-DDTHH:mm:ssZ";

            if (moment(value, format, true).isValid() || moment(value, secondaryFormat, true).isValid()) {
                return dateFormatter(new Date(value));
            }

            return stringFormatter(value);
        } else if (typeof value === "undefined") {
            return undefinedFormatter(value);
        } else if (typeof value === "boolean") {
            return booleanFormatter(value);
        } else if (value instanceof Date) {
            return dateFormatter(new Date(value));
        } else if (value === null) {
            return nullFormatter(value);
        } else if (Array.isArray(value)) {
            return arrayFormatter(value);
        } else if (typeof value === "object") {
            return objectFormatter(value);
        } else {
            throw new Error("Unknown type of value to format.");
        }
    };

    switch (formatOfNumbers) {
        case null:
            numberFormatter = (value) => value.toString();

            break;

        case "ok":
            numberFormatter = (value) => (value > 0 ? "✓" : "✘");

            break;

        case "default":
            numberFormatter = defaultNumberFormatter;
            break;

        case "localeString":
            numberFormatter = (value) => value.toLocaleString();

            break;

        case "hoursAndMinutes":
            numberFormatter = (value) => numberToHoursAndMinutes(value);

            break;

        default:
            numberFormatter = regexNumberFormatter(formatOfNumbers);
    }

    switch (formatOfStrings) {
        case null:
            stringFormatter = (value) => value;

            break;

        default:
            stringFormatter = () => formatOfStrings;
    }

    switch (formatOfDates) {
        case null:
            dateFormatter = (value) => value.toISOString();

            break;

        default:
            dateFormatter = (value) => utcFormat(formatOfDates)(value);
    }

    switch (formatOfBooleans) {
        case null:
            booleanFormatter = (value) => (value ? "true" : "false");

            break;

        case "ok":
            booleanFormatter = (value) => (value ? "✓" : "✘");

            break;

        default:
            booleanFormatter = (value) => (value ? "1" : "0");
    }

    switch (formatOfNull) {
        case null:
            nullFormatter = () => "";

            break;

        default:
            nullFormatter = () => "null";
    }

    switch (formatOfUndefined) {
        case null:
            undefinedFormatter = () => "";

            break;

        default:
            undefinedFormatter = () => "undefined";
    }

    switch (formatOfArrays) {
        case null:
            arrayFormatter = (value) => value.toString();

            break;

        case "json":
            arrayFormatter = (value) => JSON.stringify(value);

            break;

        case "comma":
            arrayFormatter = (value) => value.map((value) => formatter(value)).join(", ");

            break;

        default:
            arrayFormatter = (value) => value.map((value) => formatter(value)).join(formatOfArrays);
    }

    switch (formatOfObjects) {
        case "json":
            objectFormatter = (value) => JSON.stringify(value);

            break;

        default:
            objectFormatter = (value) => value.toString();

            break;
    }

    return formatter;
}

function defaultNumberFormatter(value: number): string {
    if (value === 0) {
        return "0";
    }

    if (value > 0.002 && value < 100000) {
        return value.toLocaleString();
    }

    if (value < -0.002 && value > -100000) {
        return value.toLocaleString();
    }

    return value.toExponential(4);
}

const toFixedRegex = /(.*)\.([0-9]*)f(.*)/;
const toExponentialRegex = /(.*)\.([0-9]*)e(.*)/;

let units = Units.Metric;
store.subscribe(() => {
    units = store.getState().user.units;
});

function regexNumberFormatter(formatOfNumber: string): (value: number) => string {
    const resultToFixed = toFixedRegex.exec(formatOfNumber);
    const resultToExponential = toExponentialRegex.exec(formatOfNumber);

    if (resultToFixed) {
        return (value: number): string => {
            const decPlaces = value % 1 === 0 ? 0 : resultToFixed[2];
            return `${resultToFixed[1]}
            ${value.toLocaleString("en", {
                // FIXME
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                maximumFractionDigits: parseInt(decPlaces),
                // FIXME
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                minimumFractionDigits: parseInt(decPlaces),
            })}
            ${units === Units["Imperial"] ? resultToFixed[3].replace(/kg/g, "lbs") : resultToFixed[3]}`;
        };
    } else if (resultToExponential) {
        return (value: number): string => {
            const defaultString = value.toString();

            if (defaultString.indexOf("e") > -1) {
                return `${resultToExponential[1]}${value.toExponential(parseInt(resultToExponential[2]))}${
                    resultToFixed !== null
                        ? units === Units["Imperial"]
                            ? resultToFixed[3].replace(/kg/g, "lbs")
                            : resultToFixed[3]
                        : ""
                }`;
            } else {
                return `${resultToExponential[1]}${value.toFixed(parseInt(resultToExponential[2]))}${
                    resultToFixed !== null
                        ? units === Units["Imperial"]
                            ? resultToFixed[3].replace(/kg/g, "lbs")
                            : resultToFixed[3]
                        : ""
                }`;
            }
        };
    } else {
        throw new Error(`Defined format of number "${formatOfNumber}" doesn't match format rules.`);
    }
}

function numberToHoursAndMinutes(value: number): string {
    let hours = Math.sign(value) * Math.floor(Math.abs(value));
    let minutes = Math.round(60 * (Math.abs(value) - Math.abs(hours)));
    if (minutes == 60) {
        hours++;
        minutes = 0;
    }
    const hoursStr = String(hours).concat("h ");
    const minutesStr = minutes > 9 ? String(minutes).concat("m") : "0".concat(String(minutes), "m");

    return hoursStr.concat(minutesStr);
}
