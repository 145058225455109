import clsx from "clsx";

import styles from "components/ui/DatePicker/Controls/Controls.module.css";
import { Popover } from "components/ui/DatePicker/Popover";
import Icon from "components/ui-deprecated/Icon/Icon";

type Props = {
    children: string | null;
};

const ControlsTrigger = (props: Props) => {
    const label = props.children ?? "Pick a date";

    return (
        <Popover.Trigger asChild>
            <button className={clsx(styles.control, styles.controlTrigger)} title={label} type="button">
                <Icon icon={"calendar-alt"} className={styles.controlTriggerIcon} />
                {label}
            </button>
        </Popover.Trigger>
    );
};

export { ControlsTrigger };
