import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export type LinkProps = {
    // Link Href
    to: string;
    // Class name
    className: string;
    // Child elements of Link, e.g. inner text
    children?: ReactNode;
    // OnClick
    onClick: () => void;
};

Link.defaultProps = {
    to: "/",
    children: "",
    className: "",
    onClick: () => undefined,
};

/** Provides accessible navigation. **/

export default function Link(props: LinkProps) {
    const { to, className, children, onClick } = props;
    return (
        <NavLink to={to} className={className} onClick={onClick} rel={"referrer"}>
            {children}
        </NavLink>
    );
}
