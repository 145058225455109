import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Language } from "i18n/languages.enum";
import {
    getFmsReportLanguage,
    getFmsReportUnits,
    setFmsReportLanguage,
    setFmsReportUnits,
} from "services/fms-report/fms-report.service";
import { FmsReportUnit } from "store/services/fms-report-data-source.service";

type State = {
    language: Language;
    units: FmsReportUnit;
};

const language = getFmsReportLanguage();
const units = getFmsReportUnits();

const initialState: State = {
    language: language ?? "en",
    units: units ?? "Metric",
};

const fmsReportSlice = createSlice({
    name: "fmsReport",
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<Language>) => {
            state.language = action.payload;
            setFmsReportLanguage(action.payload);
        },
        setUnits: (state, action: PayloadAction<FmsReportUnit>) => {
            state.units = action.payload;
            setFmsReportUnits(action.payload);
        },
    },
});

export const { setLanguage, setUnits } = fmsReportSlice.actions;
export default fmsReportSlice.reducer;
