import { useAppSelector } from "store";
import { Units } from "store/slices/user.slice";

export const useUserSettingUnit = () => {
    const units = useAppSelector((state) => state.user.units);
    return units;
};

export const useUserSettingLang = () => {
    const lang = useAppSelector((state) => state.user.language);
    return lang;
};

export { Units };
