import styles from "components/ui/DatePicker/Presets/Preset.module.css";
import { PresetDate } from "components/ui/DatePicker/Presets/presets.types";

type Props = {
    preset: PresetDate;
    onClick: () => void;
    isSelected?: boolean;
    disabled?: boolean;
};

const Preset = (props: Props) => {
    const { preset } = props;

    return (
        <button
            className={styles.preset}
            onClick={props.onClick}
            aria-selected={props.isSelected}
            disabled={props.disabled}
        >
            {preset.title}
        </button>
    );
};

export { Preset };
