import { useState } from "react";
import styled from "styled-components";

import { SearchInput } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchInput/SearchInput";
import { SearchResultMenu } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultMenu";
import { SearchCategory } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { useSearchResults } from "components/layout/Navbar/ApplicationSearch/hooks/useSearchResults";
import { useShowResults } from "components/layout/Navbar/ApplicationSearch/hooks/useShowResults";
import { Popover } from "components/ui/Popover";
import { useDebounce } from "hooks/useDebounce";

const SearchBarStyled = styled.div`
    position: relative;

    width: 100%;
`;

type Props = {
    category: SearchCategory;
};

const SearchBar = (props: Props) => {
    const [query, setQuery] = useState("");

    const queryDebounced = useDebounce(query, 250);
    const { data, isFetching } = useSearchResults(queryDebounced);

    // TODO Apply user roles
    // Show only search results relevant to the current user's role
    // const roleData = useApplyActiveRole(data);

    const { ref, showResults, handleOpen } = useShowResults(query);

    const handleResetQuery = () => {
        setQuery("");
    };

    return (
        <SearchBarStyled ref={ref}>
            <SearchInput value={query} setValue={setQuery} onFocus={handleOpen} />
            {showResults && (
                <Popover variant={"bottom"} $zIndex={100}>
                    <SearchResultMenu
                        data={data}
                        query={query}
                        category={props.category}
                        isLoading={isFetching || query !== queryDebounced}
                        onClick={handleResetQuery}
                    />
                </Popover>
            )}
        </SearchBarStyled>
    );
};

export { SearchBar };
