import styled from "styled-components";

export const InputRowInline = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > label {
        margin: 0;
    }
`;
