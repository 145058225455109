import { RouteParamsValidationError } from "routes/routes.utils";

const unknownErrorMessage = "Unknown error";
export const getErrorMessage = (error: unknown): string | null => {
    if (!error) return null;

    if (typeof error !== "object") return unknownErrorMessage;

    if ("status" in error && typeof error.status === "number") {
        const { status } = error;
        if (status === 400) {
            return "Bad request (invalid parameters)";
        } else if (status === 401) {
            return "Authorization error (lacking permissions to access target data source)";
        } else if (status === 504) {
            return "Timeout error (request took too long to complete)";
        }
    }

    if (error instanceof RouteParamsValidationError) {
        return error.message;
    }

    return unknownErrorMessage;
};
