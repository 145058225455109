import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { Icon } from "components/ui-deprecated";
import { useDebounce } from "hooks/useDebounce";

const smSizeCSS = css`
    height: 1.5rem;
    margin: -0.125rem 0;
    padding: 0 0.25rem;

    gap: 0.25rem;
`;

const WidgetFrameTitleSearchStyled = styled.div`
    height: 2rem;
    width: 100%;
    margin: -0.25rem 0;
    padding: 0 0.5rem;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    background-color: ${({ theme }) => theme.color.dairyLight};
    border-radius: 1rem;

    cursor: text;

    & > .icon {
        width: 1.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 0;

        color: ${({ theme }) => theme.color.lelyRed};
    }

    & > input {
        width: 100%;
        padding: 0;

        background-color: unset;
        border: none;
        outline: none;

        font-size: 0.875rem;
        line-height: 1rem;
    }

    ${({ theme: { widgetSize } }) => widgetSize === "sm" && smSizeCSS};
`;

type Props = {
    value?: string;
    setValue?: (value: string) => void;
};

const WidgetFrameTitleSearch = (props: Props) => {
    const { value, setValue } = props;

    const inputRef = useRef<HTMLInputElement>();

    const [inputValue, setInputValue] = useState(value ?? "");
    const debouncedInputValue = useDebounce(inputValue, 1000);

    useEffect(() => {
        setValue(debouncedInputValue);
    }, [setValue, debouncedInputValue]);

    useEffect(() => {
        if (value === inputValue) return;

        setInputValue(value ?? "");
    }, [value]);

    return (
        <WidgetFrameTitleSearchStyled onClick={() => inputRef.current.focus()}>
            <span className={"icon"}>
                <Icon icon={"search"} />
            </span>
            <input
                ref={inputRef}
                type={"text"}
                placeholder={"Search..."}
                // Default value (20) limits the min-width of the input
                size={1}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
            />
        </WidgetFrameTitleSearchStyled>
    );
};

export { WidgetFrameTitleSearch };
