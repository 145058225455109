import styled from "styled-components";

import { formatKPIValue } from "components/ui/KPI/kpi.utils";
import { notNil } from "utils/validation.utils";

const KPIAdditionalValueStyled = styled.div`
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const TitleStyled = styled.div`
    margin-bottom: 0.25rem;

    font-size: 0.75rem;
    line-height: 1;
`;

const ValueStyled = styled.div`
    font-size: 1.25rem;
    line-height: 1;
`;

type Props = {
    title: string;
    value?: number | string | null;
};

export const KPIAdditionalValue = (props: Props) => {
    const { title, value: rawValue } = props;

    let value: string;
    if (notNil(rawValue)) {
        value = typeof rawValue === "number" ? formatKPIValue(rawValue) : rawValue;
    } else {
        value = "No data";
    }

    return (
        <KPIAdditionalValueStyled>
            <TitleStyled>{title}</TitleStyled>
            <ValueStyled>{value}</ValueStyled>
        </KPIAdditionalValueStyled>
    );
};
