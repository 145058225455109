import styled, { css } from "styled-components";

type Props = {
    variant: "bottom" | "right";
    $zIndex?: number;
    $isPortal?: boolean;
};

const bottomVariantCSS = css<Props>`
    top: ${({ $isPortal }) => ($isPortal ? "0" : "100%")};

    width: 100%;
    min-width: max-content;
    margin-top: 0.5rem;

    overflow-y: auto;
`;

const rightVariantCSS = css<Props>`
    left: calc(100% + 0.5rem);
    top: 0;
`;

export const Popover = styled.div<Props>`
    position: absolute;
    z-index: ${({ $zIndex }) => $zIndex ?? 100};

    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid #dedbd666;
    border-radius: 4px;
    box-shadow: 0 2px 5px #dedbd666;

    ${({ variant }) => variant === "bottom" && bottomVariantCSS};
    ${({ variant }) => variant === "right" && rightVariantCSS};
`;
