import { Page } from "components/layout/Page";
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { colSpan, height } from "components/widgets/widget.utils";
import { CriticalAlarmsPerAstronautPast12MonthsKPIWidget } from "pages/DashboardPage/fms/widgets/CriticalAlarmsPerAstronautPast12MonthsKPIWidget";
import { MilkingsKPIFulfillmentWidget } from "pages/DashboardPage/fms/widgets/MilkingsKPIFulfillmentWidget";

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

const MilkingSection = (props: Props) => {
    return (
        <Page.Content.Section>
            <Page.Content.Section.Title>Milking</Page.Content.Section.Title>
            <Page.Content.Widgets>
                <WidgetFrame colSpan={colSpan.chartFull} height={height.chart}>
                    <MilkingsKPIFulfillmentWidget queryParameters={props.queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.kpiHalf} height={height.kpi}>
                    <CriticalAlarmsPerAstronautPast12MonthsKPIWidget queryParameters={props.queryParameters} />
                </WidgetFrame>
            </Page.Content.Widgets>
        </Page.Content.Section>
    );
};

export { MilkingSection };
