import { Flex, Icon } from "components/ui-deprecated";

export function ErrorWidget() {
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Flex
                style={{
                    height: "100%",
                }}
                justifyContent={"center"}
                alignItems={"center"}
            >
                Oops something went wrong{" "}
                <span
                    style={{
                        paddingLeft: "8px",
                    }}
                />{" "}
                <Icon icon={"sad-tear"} />
            </Flex>
        </div>
    );
}
