import styled, { type DefaultTheme } from "styled-components";

import Icon, { type LelyIconName, type FontAwesomeIconName } from "components/ui-deprecated/Icon/Icon";

type Variant = "success" | "danger" | "warning" | "info" | "default";
const colorMap = (theme: DefaultTheme) => ({
    success: {
        background: theme.color.green,
        text: theme.color.textWhite,
        border: theme.color.green,
    },
    danger: {
        background: theme.color.red,
        text: theme.color.textWhite,
        border: theme.color.red,
    },
    warning: {
        background: theme.color.orange,
        text: theme.color.textWhite,
        border: theme.color.orange,
    },
    info: {
        background: theme.color.blue,
        text: theme.color.textWhite,
        border: theme.color.blue,
    },
    default: {
        background: theme.color.dairyLight,
        text: theme.color.textBlack,
        border: theme.color.grayLight,
    },
});

const ChipStyled = styled.div<{ $variant: Variant }>`
    width: fit-content;
    height: fit-content;
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    display: flex;

    background: ${({ theme, $variant }) => colorMap(theme)[$variant].background};
    color: ${({ theme, $variant }) => colorMap(theme)[$variant].text};
    border: ${({ theme, $variant }) => `1px solid ${colorMap(theme)[$variant].border}`};

    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
`;

const ChipIcon = styled(Icon)`
    margin-right: 0.25rem;
    margin-left: -0.125rem;

    font-size: 0.625rem;
    height: 0.625rem !important;
    width: 0.625rem !important;
`;

type Props = {
    label: string;
    variant?: Variant;
    icon?: FontAwesomeIconName | LelyIconName;
    className?: string;
};

export const Chip = (props: Props) => {
    const { label, variant, icon, className } = props;

    return (
        <ChipStyled className={className} $variant={variant ?? "default"}>
            {icon && <ChipIcon icon={icon} />}

            {label}
        </ChipStyled>
    );
};

export { type Variant as ChipVariant };
