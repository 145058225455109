import { ReactNode, useCallback, useRef } from "react";
import styled from "styled-components";

import { Button } from "components/ui/Button";
import { useKeyPress } from "hooks/useKeyPress";
import { useOutsideClick } from "hooks/useOutsideClick";

const SettingsAbsoluteContainer = styled.div<{ $show: boolean }>`
    display: ${({ $show }) => ($show ? "block" : "none")};

    position: absolute;
    top: 66px;
    right: -400px;
    z-index: 50;

    width: 400px;
    // Full page height - top navbar height
    height: calc(100% - 66px);

    animation: slide 0.25s forwards;

    @keyframes slide {
        100% {
            right: 0;
        }
    }
`;

const SettingsFixedContainer = styled.div`
    position: fixed;
    overflow: hidden;

    width: 400px;
    // Full page height - top navbar height
    height: calc(100% - 66px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: rgba(50, 50, 50, 0.1);
    backdrop-filter: blur(10px);
`;

const SettingsContent = styled.div`
    overflow: hidden;
`;

const Actions = styled.div`
    padding: 16px 24px;
`;

type Props = {
    children: ReactNode;
    show: boolean;
    onClose: () => void;
};

// use this on the button that toggles the settings
// prevents re-opening the settings when clicking the button
export const PAGE_SETTINGS_TOGGLE_BUTTON_ID = "page-settings-toggle-button";

const PageSettings = (props: Props) => {
    const { children, show, onClose } = props;

    const ref = useRef();

    const handleClose = useCallback(() => {
        if (show) {
            onClose();
        }
    }, [show, onClose]);

    useOutsideClick(ref, (clickedElement) => {
        if (clickedElement.id === PAGE_SETTINGS_TOGGLE_BUTTON_ID) {
            return;
        }

        handleClose();
    });

    useKeyPress("Escape", handleClose);

    return (
        <SettingsAbsoluteContainer ref={ref} $show={show}>
            <SettingsFixedContainer>
                <SettingsContent>{children}</SettingsContent>
                <Actions>
                    <Button onClick={onClose}>Close</Button>
                </Actions>
            </SettingsFixedContainer>
        </SettingsAbsoluteContainer>
    );
};

export { PageSettings };
