import { createGlobalStyle } from "styled-components";

import { printCSS } from "styles/print.style";

export const GlobalStyles = createGlobalStyle`
    body {
        background-color:${({ theme }) => theme.color.grayDairyLight};
      
        color: ${({ theme }) => theme.color.textGray};
	  
        font-family: "Ubuntu", sans-serif;
        font-size: 14px;
        line-height: 24px;

	    // TODO Scrollbars
    	// scrollbar-color: ${({ theme }) => theme.color.lelyRed} ${({ theme }) => theme.color.white};
		//
        // ::-webkit-scrollbar {
		//     height: 8px;
		//     width: 8px;
        // }
		//
        // ::-webkit-scrollbar-track {
		//     border-radius: 4px;
		//     // background-color: ${({ theme }) => theme.color.grayDairyLight};
        // }
		//
        // ::-webkit-scrollbar-thumb {
        //     background-color:  ${({ theme }) => theme.color.lelyRed};
        //     border: 3px solid ${({ theme }) => theme.color.lelyRed};
        //     border-radius: 4px;
		//  
		//   	&:hover {
		// 		background-color:  ${({ theme }) => theme.color.lelyRedDark};
		// 		border-color:  ${({ theme }) => theme.color.lelyRedDark};
		// 	}
        // }

        // ::-webkit-scrollbar-corner {
        // 	@apply app-bg-gray-100;
        // }
		//
		// ::-webkit-scrollbar {
		// 	width: 14px;
		//  
		// 	background-color: ${({ theme }) => theme.color.grayDairyLight};
		// }
		//
		// ::-webkit-scrollbar-track-piece {
		// 	border: 4px solid ${({ theme }) => theme.color.white};
		// }
		//
		// ::-webkit-scrollbar-thumb {
		// 	background-clip: padding-box;
		// 	background-color: ${({ theme }) => theme.color.lelyRed};
		//
		// 	border: 4px solid transparent;
		// 	border-left-color: ${({ theme }) => theme.color.white};;
		// 	border-right-color: ${({ theme }) => theme.color.white};;
		// 	border-radius: 8px;
		// }
    }

    #amb-cnf-embedded-container-1 {
		padding: 0 !important;
	  	bottom: 1rem !important;

	  	& > i {
			width: 2rem;
			height: 2rem;
	
			display: flex !important;
			align-items: center;
			justify-content: center;
	
			background-color: ${({ theme }) => theme.color.lelyRed};
			border-start-start-radius: 0.5rem !important;
			border-end-start-radius: 0.5rem !important;
			box-shadow: 0 0 0.25rem #c30a1466;
	
			color: #fff !important;
	
			font-size: 1rem !important;
	
			&:hover {
			    background-color:${({ theme }) => theme.color.lelyRedDark};
			    box-shadow: 0 0 1rem #c30a1466;
			}
		}
    }


    ${printCSS};
`;
