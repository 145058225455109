import { useRef } from "react";
import { Link as RouterLink, type LinkProps } from "react-router-dom";

import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";

type CustomLinkProps = LinkProps & React.RefAttributes<HTMLAnchorElement>;

export const Link: React.FC<CustomLinkProps> = ({ children, ...props }) => {
    const ref = useRef(null);
    const sendUserEvent = useSendUserEvent();

    const handleClick = async (event) => {
        if (props?.onClick !== undefined) props.onClick(event);

        const target = props.to;
        let targetPath: string;
        if (typeof target === "string") {
            targetPath = target;
        } else {
            targetPath = target.pathname;
        }

        sendUserEvent({
            type: "navigation",
            value: targetPath,
        });
    };

    return (
        <RouterLink {...props} onClick={handleClick} ref={ref}>
            {children}
        </RouterLink>
    );
};
