import styled from "styled-components";

import { TableCell } from "components/ui/table-final-saviour/Table/TableCell";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { Column, Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

const TableCellStyled = styled(TableCell)`
    height: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    min-width: min-content;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: fit-content;
`;

type Options<T extends Row> = {
    title?: string;
    width?: number;
    getButtons: (props: TableCellBaseProps<T>) => React.ReactNode;
};

export const createActionButtonsColumn = <T extends Row>(options: Options<T>): Column<T> => {
    const { getButtons } = options;

    return {
        _type: "action-buttons",
        components: {
            TableHeaderCell: (props) => (
                <TableHeaderCell {...props} width={options.width}>
                    {options.title}
                </TableHeaderCell>
            ),
            TableCell: (props) => {
                return (
                    <TableCellStyled {...props} width={options.width}>
                        <FlexContainer>
                            <ActionButtonContainer>{getButtons(props)}</ActionButtonContainer>
                        </FlexContainer>
                    </TableCellStyled>
                );
            },
        },
    };
};
