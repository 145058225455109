import { KPI as _KPI } from "components/ui/KPI/KPI";
import { KPIAdditionalValue } from "components/ui/KPI/KPIAdditionalValue";
import { KPILastValue } from "components/ui/KPI/KPILastValue";
import { KPIChart } from "components/ui/KPI/charts/KPIChart/KPIChart";
import { createKPIChartConfiguration } from "components/ui/KPI/charts/KPIChart/kpi-chart.configuration";
import { createKPIDataset } from "components/ui/KPI/charts/KPIChart/kpi-chart.dataset";
import {
    getActiveRegion,
    getThresholdsFromRegions,
    Regions,
} from "components/ui/KPI/charts/KPIChart/kpi-chart.threshold";
import { KPIChartDataPoint, KPIChartThreshold } from "components/ui/KPI/charts/KPIChart/kpi-chart.types";
import { toKPIChartDataPoints } from "components/ui/KPI/charts/KPIChart/kpi-chart.utils";
import { type KPIDoubleChartDataPoint, KPIDoubleChart } from "components/ui/KPI/charts/KPIDoubleChart/KPIDoubleChart";
import { toKPIDoubleChartDataPoints } from "components/ui/KPI/charts/KPIDoubleChart/kpi-double-chart.utils";
import { KPIDataSourceChartData, KPIDataSourceTextData } from "components/ui/KPI/kpi.types";
import { formatKPIValue, resolveKPIColor, toKPITextData, getLastValueTitle } from "components/ui/KPI/kpi.utils";

const KPI = _KPI as typeof _KPI & {
    LastValue: typeof KPILastValue;
    AdditionalValue: typeof KPIAdditionalValue;
    Chart: typeof KPIChart;
    DoubleChart: typeof KPIDoubleChart;
};

KPI.LastValue = KPILastValue;
KPI.AdditionalValue = KPIAdditionalValue;
KPI.Chart = KPIChart;
KPI.DoubleChart = KPIDoubleChart;

export {
    KPI,
    createKPIChartConfiguration,
    createKPIDataset,
    resolveKPIColor,
    formatKPIValue,
    toKPIChartDataPoints,
    toKPITextData,
    getActiveRegion,
    getLastValueTitle,
    getThresholdsFromRegions,
    toKPIDoubleChartDataPoints,
};
export type {
    KPIDataSourceChartData,
    KPIChartDataPoint,
    KPIDataSourceTextData,
    Regions,
    KPIDoubleChartDataPoint,
    KPIChartThreshold,
};
