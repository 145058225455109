import styled from "styled-components";

export const NotificationItemAction = styled.div`
    color: ${({ theme }) => theme.color.lelyRed};
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: ${({ theme }) => theme.color.lelyRedDark};
    }
`;
