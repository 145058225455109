import { PageContentSection as _PageContentSection } from "components/layout/Page/PageContent/PageContentSection/PageContentSection";
import { PageContentSectionText } from "components/layout/Page/PageContent/PageContentSection/PageContentSectionText";
import { PageContentSectionTitle } from "components/layout/Page/PageContent/PageContentSection/PageContentSectionTitle";

const PageContentSection = _PageContentSection as typeof _PageContentSection & {
    Text: typeof PageContentSectionText;
    Title: typeof PageContentSectionTitle;
};

PageContentSection.Text = PageContentSectionText;
PageContentSection.Title = PageContentSectionTitle;

export { PageContentSection };
