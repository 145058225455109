import styled from "styled-components";

import { Loader } from "components/ui/Loader";

const PageLoaderStyled = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PageLoader = () => (
    <PageLoaderStyled>
        <Loader size={"32px"} />
    </PageLoaderStyled>
);
