import { useState } from "react";
import { DayPicker, DayPickerRangeProps, DayPickerSingleProps } from "react-day-picker";
import stylesBase from "react-day-picker/dist/style.module.css";

import styles from "components/ui/DatePicker/Calendar/Calendar.module.css";
import { dayjs } from "components/ui/DatePicker/date.utils";
import Icon from "components/ui-deprecated/Icon/Icon";

const fromYear = 2017;
const toYear = new Date().getFullYear() + 1;

type Props = Pick<
    DayPickerSingleProps | DayPickerRangeProps,
    "mode" | "selected" | "disabled" | "onSelect" | "onDayClick" | "onDayMouseEnter" | "onDayFocus"
> & { useISOWeek?: boolean };

const Calendar = (props: Props) => {
    const { mode, selected, disabled, onSelect, onDayClick, onDayMouseEnter, onDayFocus, useISOWeek } = props;

    let initialMonth = new Date();
    if (selected instanceof Date) {
        initialMonth = selected;
    } else if (typeof selected === "object" && "to" in selected) {
        // Initial month should be the month before the "to" date on range mode with 2 months wide calendar
        initialMonth = dayjs(selected.to).subtract(1, "month").toDate();
    }

    const [month, setMonth] = useState(initialMonth);

    return (
        <DayPicker
            // FIXME Should be fixed in later PR
            mode={mode as any}
            selected={selected as any}
            disabled={disabled}
            onSelect={onSelect as any}
            onDayClick={onDayClick}
            onDayMouseEnter={onDayMouseEnter}
            onDayFocus={onDayFocus}
            // Displayed month logic
            month={month}
            onMonthChange={(month) => setMonth(month)}
            // Styling and configuration
            classNames={{
                ...stylesBase,
                root: styles.root,
                months: styles.months,
                month: styles.month,
                table: styles.table,
                head_row: styles.headRow,
                head_cell: styles.headCell,
                row: styles.row,
                day: styles.day,
                day_today: styles.dayToday,
                day_outside: styles.dayOutside,
                day_disabled: styles.dayDisabled,
                day_range_middle: styles.dayRangeMiddle,
                day_range_start: styles.dayRangeStart,
                day_range_end: styles.dayRangeEnd,
                weeknumber: styles.weeknumber,
                caption: styles.caption,
                caption_dropdowns: styles.captionDropdowns,
                dropdown: styles.dropdown,
                caption_label: styles.captionLabel,
                nav: styles.nav,
                nav_button: styles.navButton,
                nav_button_previous: styles.navButtonPrevious,
                nav_button_next: styles.navButtonNext,
            }}
            components={{
                IconLeft: () => <Icon icon={"chevron-left"} />,
                IconRight: () => <Icon icon={"chevron-right"} />,
                IconDropdown: () => <Icon icon={"chevron-down"} />,
            }}
            captionLayout={"dropdown-buttons"}
            initialFocus={true}
            showOutsideDays={true}
            showWeekNumber={true}
            ISOWeek={useISOWeek}
            fromYear={fromYear}
            toYear={toYear}
            numberOfMonths={mode === "range" ? 2 : 1}
            formatters={{
                formatWeekNumber: (weekNumber) => `W${weekNumber}`,
            }}
        />
    );
};

export { Calendar };
export type { Props as CalendarProps };
