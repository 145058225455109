import * as PopoverPrimitive from "@radix-ui/react-popover";
import { forwardRef } from "react";

import styles from "components/ui/DatePicker/Popover/PopoverContent.module.css";

const PopoverContent = forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>((props, ref) => {
    const { align = "center", sideOffset = 4, ...rest } = props;

    return (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
                ref={ref}
                className={styles.popoverContent}
                align={align}
                sideOffset={sideOffset}
                {...rest}
            />
        </PopoverPrimitive.Portal>
    );
});
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { PopoverContent };
