import * as Sentry from "@sentry/react";
import { useState } from "react";

import { InputsContainer } from "components/layout/Feedback/feedback.styled-components";
import { Button } from "components/ui/Button";
import { Dialog } from "components/ui/Dialog";
import { InputRadio } from "components/ui/input/InputRadio";
import { InputRow } from "components/ui/input/InputRow";
import { InputRowInline } from "components/ui/input/InputRowInline";
import { InputText } from "components/ui/input/InputText";
import { InputTextArea } from "components/ui/input/InputTextArea";
import { useAppSelector } from "store";
import { splitAndCapitalize } from "utils/string.utils";

const feedbackLabel = {
    like: "What did you like?",
    idea: "What is your suggestion?",
    bug: "What did you find?",
};

type FeedbackType = "like" | "idea" | "bug";
type Props = {
    onClose: () => void;
    type?: FeedbackType;
};

const FeedbackModal = (props: Props) => {
    const { onClose } = props;

    const [type, setType] = useState<FeedbackType | null>(props.type ?? null);
    const [message, setMessage] = useState("");
    const [summary, setSummary] = useState("");
    const [email, setEmail] = useState("");

    const userName = useAppSelector((state) => state.user.name);

    const handleSendFeedback = () => {
        Sentry.withScope(function (scope) {
            // Generate a random issue id to be able to track the feedback
            const issueId = Math.random().toString(16).substring(2, 10).toUpperCase();

            // Add tags to the scope to be able to filter the feedback
            // The tags have to be set before calling `Sentry.captureMessage`
            scope.setTag("feedback.id", issueId);
            scope.setTag("feedback.type", type);

            if (email) {
                const user = scope.getUser();
                scope.setUser({ ...user, email: email });
            }

            const maxTitleLength = 60; // Without the type prefix
            let messageTrimmed = message.replace(/\n/g, " ").substring(0, maxTitleLength).trim();
            // Add ellipsis if the original message was longer than 20 characters
            messageTrimmed = messageTrimmed.length < message.length ? `${messageTrimmed}...` : messageTrimmed;
            const messageTitle = `${splitAndCapitalize(type)} - ${summary ? summary : messageTrimmed}`;
            const eventId = Sentry.captureMessage(messageTitle);

            const commentsTitle = summary ? `${summary}\n\n` : "";
            const comments = `${commentsTitle}${message}`;
            const userFeedback = {
                event_id: eventId,
                name: userName,
                email: email,
                message: comments,
            };

            Sentry.sendFeedback(userFeedback, { includeReplay: true });
        });

        setType("like");
        setMessage("");
        setSummary("");
        setEmail("");
        onClose();
    };

    return (
        <Dialog.Content width={"sm"}>
            <Dialog.Content.Title>Feedback</Dialog.Content.Title>
            <Dialog.Content.Body>
                <InputsContainer>
                    <div>
                        <InputRowInline>
                            <InputRadio
                                type="radio"
                                id={"type-like"}
                                name="type"
                                value={"like"}
                                checked={type === "like"}
                                onChange={() => setType("like")}
                            />
                            <label htmlFor="type-like">I like something</label>
                        </InputRowInline>
                        <InputRowInline>
                            <InputRadio
                                type="radio"
                                id={"type-idea"}
                                name="type"
                                value={"idea"}
                                checked={type === "idea"}
                                onChange={() => setType("idea")}
                            />
                            <label htmlFor="type-idea">I have an idea</label>
                        </InputRowInline>
                        <InputRowInline>
                            <InputRadio
                                type="radio"
                                id={"type-bug"}
                                name="type"
                                value={"bug"}
                                checked={type === "bug"}
                                onChange={() => setType("bug")}
                            />
                            <label htmlFor="type-bug">Something is not working</label>
                        </InputRowInline>
                    </div>
                    {type && (
                        <>
                            <InputRow>
                                <label htmlFor="message" style={{ display: "block" }}>
                                    {feedbackLabel[type]}
                                </label>
                                <InputTextArea
                                    id={"message"}
                                    name={"message"}
                                    value={message}
                                    required={true}
                                    onChange={(event) => setMessage(event.target.value)}
                                    placeholder={"Type your message here"}
                                    rows={4}
                                />
                            </InputRow>
                            <InputRow>
                                <label htmlFor="summary">Feedback summary (optional):</label>
                                <InputText
                                    id={"summary"}
                                    name={"summary"}
                                    value={summary}
                                    required={false}
                                    onChange={(event) => setSummary(event.target.value)}
                                    placeholder={"Summarize your feedback in one sentence"}
                                />
                            </InputRow>
                            <InputRow>
                                <label htmlFor="email">
                                    Please enter your email if you want us to get back to you:
                                </label>
                                <InputText
                                    type={"email"}
                                    id={"email"}
                                    name={"email"}
                                    value={email}
                                    required={false}
                                    onChange={(event) => setEmail(event.target.value)}
                                    placeholder={"Enter your email address here"}
                                />
                            </InputRow>
                        </>
                    )}
                </InputsContainer>
            </Dialog.Content.Body>
            <Dialog.Content.Actions>
                <Button variant={"text"} onClick={onClose}>
                    Close
                </Button>
                <Button onClick={handleSendFeedback} disabled={message === ""}>
                    Send
                </Button>
            </Dialog.Content.Actions>
        </Dialog.Content>
    );
};

export { FeedbackModal };
