import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type ChartData,
    createPieChartConfiguration,
    createTooltipConfiguration,
    addTooltipLabelSuffix,
    chartColorHorizonPackageDistribution,
} from "components/ui/Chart";
import { useDataSource } from "hooks/useDataSource";

const { format: formatNumber } = new Intl.NumberFormat();

type DataRow = {
    Date: string;
    ProductType: keyof typeof chartColorHorizonPackageDistribution;
    ProductTypeCount: number;
};

type ChartDataPoint = {
    count: number;
    value: string;
};

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

// Source: HorizonUsage_HorizonPackageDistribution_PieChart.json
// id: 42750c7c-34ec-4aec-9bdd-3057b40ef50a
const HorizonPackageDistributionWidget = (props: Props) => {
    const { queryParameters } = props;
    const title = "Horizon package distribution";

    const dataDataSource = useDataSource<DataRow[]>("HorizonUsage_HotizonPackageDistribution_PieChart", {
        ...queryParameters,
    });

    const data = dataDataSource?.data ?? [];
    const total = data.reduce((acc, curr) => acc + curr.ProductTypeCount, 0);

    const chartConfiguration = createPieChartConfiguration({
        plugins: {
            tooltip: createTooltipConfiguration<ChartDataPoint>({
                intersect: true,
                getLabel: (tooltipItem) => {
                    const count = formatNumber(tooltipItem.raw.count);

                    return addTooltipLabelSuffix(tooltipItem, `% (Count: ${count})`);
                },
            }),
        },
    });
    const chartData: ChartData<"pie"> = {
        labels: data.map(({ ProductType }) => ProductType),
        datasets: [
            {
                label: "Package distribution",
                data: data.map(({ ProductTypeCount }) => {
                    const percentage = (ProductTypeCount / total) * 100;

                    return {
                        count: ProductTypeCount,
                        value: percentage.toFixed(2),
                    };
                }) satisfies ChartDataPoint[],
                backgroundColor: data.map(({ ProductType }) => chartColorHorizonPackageDistribution[ProductType]),
            },
        ],
    };

    return (
        <>
            <WidgetFrame.Title title={title} icon={"lely-icon-chart"}>
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"1199013929"} />
            </WidgetFrame.Title>
            <WidgetFrame.Chart configuration={chartConfiguration} data={chartData} dependencies={[dataDataSource]} />
        </>
    );
};

export { HorizonPackageDistributionWidget };
