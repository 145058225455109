import { WidgetSizeSettings } from "components/layout/WidgetFrame/widget.types";

export const colSpan = {
    chartFull: { md: 12 },
    chartHalf: { md: 6, sm: 12 },
    twoThirds: { md: 8, sm: 12 },
    oneThird: { md: 4, sm: 12 },
    info: { md: 6, xs: 12 },
    infoFull: { md: 12 },
    kpi: { md: 4, sm: 6, xs: 12 },
    kpiHalf: { md: 6, xs: 12 },
    tableFull: { md: 12 },
    tableHalf: { xs: 12, sm: 12, md: 6 },
} satisfies Record<string, WidgetSizeSettings>;

export const height = {
    kpi: 200,
    chart: 500,
    chartSm: 300,
    chartLg: 600,
    activityTimeline: 650,
};
