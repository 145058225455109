import { UserAreaBoundary } from "components/UserAreaBoundary";
import { Page } from "components/layout/Page";
import { DateRangeWidget } from "components/widgets/selects/DateRangeWidget";
import { usePagesState } from "hooks/usePagesState";
import { FMSSubpage } from "pages/DashboardPage/fms/FMSSubpage";
import { ServiceManagerSubpage } from "pages/DashboardPage/sm/ServiceManagerSubpage";
import { TSSSubpage } from "pages/DashboardPage/tss/TSSSubpage";

const DashboardPage = () => {
    const [dateFrom, setDateFrom] = usePagesState("dateFrom");
    const [dateTo, setDateTo] = usePagesState("dateTo");

    const queryParameters = { dateFrom, dateTo };

    return (
        <>
            <Page.StickyHeader>
                <Page.Header.Widgets>
                    <Page.Header.WidgetFrame colSpan={{ md: 12 }}>
                        <DateRangeWidget
                            onDateToChange={(value) => setDateTo(value)}
                            onDateFromChange={(value) => setDateFrom(value)}
                            dateTo={dateTo}
                            dateFrom={dateFrom}
                        />
                    </Page.Header.WidgetFrame>
                </Page.Header.Widgets>
            </Page.StickyHeader>
            <Page>
                <Page.Header>
                    <Page.Header.Widgets>
                        <Page.Header.WidgetFrame colSpan={{ md: 9 }}>
                            <Page.Header.Title value={"Dashboard"} />
                        </Page.Header.WidgetFrame>
                        <Page.Header.WidgetFrame>
                            <DateRangeWidget
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                onDateFromChange={setDateFrom}
                                onDateToChange={setDateTo}
                            />
                        </Page.Header.WidgetFrame>
                    </Page.Header.Widgets>
                </Page.Header>
                <Page.Content>
                    <UserAreaBoundary areas={["ServiceManager_Core"]}>
                        <ServiceManagerSubpage queryParameters={queryParameters} />
                    </UserAreaBoundary>
                    <UserAreaBoundary areas={["TSS_Core"]}>
                        <TSSSubpage queryParameters={queryParameters} />
                    </UserAreaBoundary>
                    <UserAreaBoundary areas={["FMS_Core"]}>
                        <FMSSubpage queryParameters={queryParameters} />
                    </UserAreaBoundary>
                </Page.Content>
            </Page>
        </>
    );
};

export { DashboardPage };
