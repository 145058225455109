import { PageContent as _PageContent } from "components/layout/Page/PageContent/PageContent";
import { PageContentSection } from "components/layout/Page/PageContent/PageContentSection";
import { PageContentSections } from "components/layout/Page/PageContent/PageContentSections";
import { PageContentWidgets } from "components/layout/Page/PageContent/PageContentWidgets";

const PageContent = _PageContent as typeof _PageContent & {
    Section: typeof PageContentSection;
    Sections: typeof PageContentSections;
    Widgets: typeof PageContentWidgets;
};

PageContent.Section = PageContentSection;
PageContent.Sections = PageContentSections;
PageContent.Widgets = PageContentWidgets;

export { PageContent };
