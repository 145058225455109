import styled from "styled-components";

import { ClusterFilter } from "components/layout/Sidebar/SidebarControls/ClusterFilter";
import { SidebarControlsSeparator } from "components/layout/Sidebar/SidebarControls/SidebarControlsSeparator";
import { UserRoleSelect } from "components/layout/Sidebar/SidebarControls/UserRoleSelect";

const SidebarControlsStyled = styled.div``;

const SidebarControls = () => {
    return (
        <SidebarControlsStyled>
            <UserRoleSelect />
            <ClusterFilter />
            <SidebarControlsSeparator />
        </SidebarControlsStyled>
    );
};

export { SidebarControls };
