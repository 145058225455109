import { useContext } from "react";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import { getDataRow, getHeadersForLevel } from "components/components-deprecated/tables/tables.helper";
import { take, skip, map } from "deprecated/data-wrapper";
import { DataWrapper } from "deprecated/data-wrapper/data-wrapper.interfaces";
import { useAppSelector } from "store";

export interface RowProps {
    level: number;
    data: DataWrapper;
}

const Rows: (props: RowProps) => JSX.Element = (props: RowProps) => {
    const {
        hiddenColumns,
        formatter,
        fieldsToLinks,
        expandable,
        doubleExpandable,
        expandedQueryParameters,
        expandableStaticData,
        pageSize,
        currentPage,
        customColumnTypes,
        setContextFromColumns,
        widerColumns,
        innerTableLayout,
    } = useContext(TableContext);

    const { data, level } = props;

    const headers = data !== undefined ? getHeadersForLevel(data, hiddenColumns, level) : [];

    const isPrintMode = false;
    const isPrePrintMode = false;

    return (
        <div className={"basic-table-content"}>
            {isPrePrintMode || isPrintMode
                ? map(data, (row) => {
                      return getDataRow(
                          row,
                          headers,
                          formatter,
                          fieldsToLinks,
                          expandable,
                          expandedQueryParameters,
                          expandableStaticData,
                          customColumnTypes,
                          setContextFromColumns,
                          doubleExpandable,
                          widerColumns,
                          innerTableLayout
                      );
                  })
                : map(take(skip(data, pageSize * currentPage), pageSize), (row) => {
                      return getDataRow(
                          row,
                          headers,
                          formatter,
                          fieldsToLinks,
                          expandable,
                          expandedQueryParameters,
                          expandableStaticData,
                          customColumnTypes,
                          setContextFromColumns,
                          doubleExpandable,
                          widerColumns,
                          innerTableLayout
                      );
                  })}
        </div>
    );
};

export default Rows;
