import { NavbarButton } from "components/layout/Navbar/NavbarButton";
import { AdministrationLink } from "components/layout/Navbar/Settings/AdministrationLink";
import { DeveloperModeToggle } from "components/layout/Navbar/Settings/DeveloperModeToggle";
import { Impersonation } from "components/layout/Navbar/Settings/Impersonation";
import { UnitSettings } from "components/layout/Navbar/Settings/UnitSettings";
import { MenuList } from "components/ui/MenuList";
import { Popover } from "components/ui/Popover";
import { Icon } from "components/ui-deprecated";
import { usePopover } from "hooks/usePopover";

const Settings = () => {
    const { ref, isOpen, handleToggleOpen, handleClose } = usePopover();

    return (
        <div ref={ref} style={{ position: "relative" }}>
            <NavbarButton onClick={handleToggleOpen} title={"Settings"}>
                <Icon icon={"cog"} />
            </NavbarButton>
            {isOpen && (
                <Popover variant={"bottom"} style={{ right: 0 }}>
                    <MenuList>
                        <AdministrationLink />
                        <Impersonation onSubmit={handleClose} />
                        <UnitSettings onSubmit={handleClose} />
                        <DeveloperModeToggle />
                    </MenuList>
                </Popover>
            )}
        </div>
    );
};

export { Settings };
