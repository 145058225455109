import { matchPath, useLocation } from "react-router-dom";

import { PageNavigationItemIcon } from "components/layout/Page/PageNavigation/PageNavigationItemIcon";
import { PageNavigationSubTabs } from "components/layout/Page/PageNavigation/PageNavigationSubTabs";
import { PageNavigationTabItem } from "components/layout/Page/PageNavigation/PageNavigationTabItem";
import { PageNavigationTabs } from "components/layout/Page/PageNavigation/PageNavigationTabs";
import { PageNavigationItem } from "components/layout/Page/PageNavigation/page-navigation.types";
import { Route } from "routes/routes.types";

type Props = {
    items: PageNavigationItem[];
    /**
     * The route should end with `/*`, so it can match all sub routes
     */
    route: Route;
};

const PageNavigation = (props: Props) => {
    const { items, route } = props;
    // Remove the `/*` from the route
    const path = route.path.slice(0, -2);
    if (route.path.slice(-2) !== "/*")
        throw new Error("The route should end with `/*`, so it can match all sub routes");

    const location = useLocation();

    const selectedItem = items.find((item) => {
        if (item._type !== "linkGroup") return false;

        let itemPath = path;
        if (item.to !== "") {
            itemPath = `${path}/${item.to}/*`;
        }

        const match = matchPath({ path: itemPath }, location.pathname);
        return match;
    });

    return (
        <nav>
            <PageNavigationTabs>
                {items.map((item, index) => {
                    const title = item.title;

                    return (
                        <PageNavigationTabItem
                            key={index}
                            to={{ pathname: item.to, search: location.search }}
                            end={item._type === "link"}
                        >
                            {title}
                            {item.icon && <PageNavigationItemIcon icon={item.icon} />}
                        </PageNavigationTabItem>
                    );
                })}
            </PageNavigationTabs>
            {selectedItem && selectedItem._type === "linkGroup" && (
                <PageNavigationSubTabs parentLink={selectedItem.to} items={selectedItem.links} />
            )}
        </nav>
    );
};

export { PageNavigation };
