import { MouseEventHandler } from "react";

import type { Row } from "deprecated/data-utils";

const OPTION_HEIGHT = 28;

export type OptionProps = {
    top: number;
    row: Row;
    active: boolean;
    selectFields: Array<string>;
    onMouseEnter: MouseEventHandler<HTMLDivElement>;
    onMouseLeave: MouseEventHandler<HTMLDivElement>;
    onMouseDown: MouseEventHandler<HTMLDivElement>;
};

export default function Option({
    top,
    row,
    active,
    selectFields,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
}: OptionProps) {
    return (
        <div
            style={{
                position: "absolute",
                top: `${top}px`,
                padding: "0px 16px",
                height: `${OPTION_HEIGHT}px`,
                width: "100%",
                lineHeight: `${OPTION_HEIGHT}px`,
                cursor: "pointer",
                background: active ? "rgba(0,0,0,0.1)" : "none",
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
        >
            {selectFields.map((field) => row[field]).join(" - ")}
        </div>
    );
}
