import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import { getCellContent } from "components/components-deprecated/tables/tables.helper";
import { Row } from "components/components-deprecated/tables/tables.interfaces";
import { Flex } from "components/ui-deprecated";
import { Formatter } from "deprecated/data-utils/format/format";

const StandardRow: React.FC<{
    row: Row;
    headers: Array<string>;
    formatter: Formatter;
    fieldsToLinks: { [key: string]: string };
    customColumnTypes: { [key: string]: string };
    setContextFromColumns: { [key: string]: string };
    widerColumns: Array<string>;
}> = ({ row, headers, formatter, fieldsToLinks, customColumnTypes, setContextFromColumns, widerColumns }) => {
    return (
        <Flex className={"basic-table-row"}>
            {headers.map((field) => (
                <Flex
                    className={`basic-table-cell ${widerColumns.includes(field) ? "wider" : ""}`}
                    key={field}
                    // FIXME
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    flexGrow={1}
                >
                    {(() => {
                        return getCellContent(
                            row,
                            field,
                            formatter,
                            fieldsToLinks,
                            customColumnTypes,
                            setContextFromColumns
                        );
                    })()}
                </Flex>
            ))}
        </Flex>
    );
};

export default StandardRow;
