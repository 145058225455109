import { TableWidget } from "components/components-deprecated/TableWidget";
import { WidgetFrame } from "components/layout/WidgetFrame";
import {
    WidgetWithOptionalQueryParameters,
    WidgetWithQueryParameters,
} from "components/layout/WidgetFrame/widget.types";
import { processData, toDataWrapper } from "deprecated/data-wrapper/data.utils";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath, toDeviceTypeParam } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo"> &
    WidgetWithOptionalQueryParameters<"customerNumber" | "deviceSerialNumber" | "technicianCode">;

const ServiceHistoryWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = {
        ...queryParameters,
        customerNumber: queryParameters.customerNumber ?? "",
        deviceSerialNumber: queryParameters.deviceSerialNumber ?? "",
        technicianCode: queryParameters.technicianCode ?? "",
    };

    const dataSource = useDataSource("ServiceHistoryTechnician", dataSourceParameters);
    const { data } = dataSource;

    const dataProcessed = processData(data, {
        renameColumnMap: {
            Date: "Date",
            DeviceType: "Device Type",
            OrderNumber: "Order Number",
            Label: "Order Type",
            Duration: "Duration",
            Diagnosis1: "Diagnosis 1",
            CustomerName: "Customer",
            DeviceLDNSN: "LDN / SN",
            Diagnosis2: "Diagnosis 2",
            Diagnosis3: "Diagnosis 3",
            Diagnosis4: "Diagnosis 4",
        },
        addLinkColumnMap: {
            CustomerNameLink: (row) =>
                generatePath(routes.customerDetail, {
                    params: {
                        customerNumber: row.CustomerNumber,
                    },
                    searchParams: {
                        farmLicenseKey: row.FarmLicenseKey,
                    },
                }),
            SerialNumberLink: (row) =>
                generatePath(routes.customerDeviceDetail, {
                    params: {
                        customerNumber: row.CustomerNumber,
                        deviceType: toDeviceTypeParam(row.DevicePageType),
                        deviceSerialNumber: row.DeviceSerialNumber,
                    },
                }),
        },
    });

    return (
        <>
            <WidgetFrame.Title title={"Service History"} icon={"lely-icon-tasks"}>
                <WidgetFrame.Title.Information pageId={"233996303"} lastImportType={"middleware"} />
            </WidgetFrame.Title>
            <WidgetFrame.Content>
                <WidgetFrame.DataContent dependencies={[dataSource]}>
                    <TableWidget
                        data={{ data: toDataWrapper(dataProcessed) }}
                        format={{
                            formatOfBooleans: ".0f",
                            formatOfDates: "%d/%m/%Y",
                            formatOfFields: {
                                "Order Number": null,
                            },
                        }}
                        expandable={true}
                        expandedQueryParameters={{
                            level0: dataSourceParameters,
                            level1: {
                                dataSource: "ServiceHistoryExpandedDetail",
                                parameters: {
                                    orderNumber: "Order Number",
                                },
                            },
                        }}
                        search={true}
                        fieldsToLinks={{
                            Customer: "CustomerNameLink",
                            "LDN / SN": "SerialNumberLink",
                        }}
                        hiddenColumns={{
                            level0: [
                                "CustomerNameLink",
                                "SerialNumberLink",
                                "TechnicianName",
                                "TechnicianCode",
                                "Expanded",
                                "CustomerNumber",
                                "FarmCount",
                                "FarmLicenseKey",
                                "DevicePageType",
                                "DeviceSerialNumber",
                                "TechnicianName",
                                "DeviceLDNSN",
                                "Diagnosis 2",
                                "Diagnosis 3",
                                "Diagnosis 4",
                                "DescriptionOfWork",
                            ],
                            level1: [],
                            level2: [],
                        }}
                        innerTableLayout={{
                            Parts: ["Parts Name", "Parts No.", "Parts Serial No.", "Parts Total", "Parts Event"],
                            Expenses: ["Expenses Name", "Expenses Total", "Expenses Event"],
                            Descriptions: [
                                "Descriptions Diagnosis 2",
                                "Descriptions Diagnosis 3",
                                "Descriptions Diagnosis 4",
                                "Descriptions Description of Work",
                            ],
                        }}
                    />
                </WidgetFrame.DataContent>
            </WidgetFrame.Content>
        </>
    );
};

export { ServiceHistoryWidget };
