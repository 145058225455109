import styled from "styled-components";

import { Icon } from "components/ui-deprecated";

const SearchIconStyled = styled.span`
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.25rem;

    background-color: ${({ theme }) => theme.color.lelyRed};
    border-radius: 100%;

    color: ${({ theme }) => theme.color.white};
    font-size: 1rem;
`;

const SearchIcon = () => {
    return (
        <SearchIconStyled>
            <Icon icon={"search"} />
        </SearchIconStyled>
    );
};

export { SearchIcon };
