import type { Moment } from "moment";
import moment from "moment";

export default function datamoleMoment(date?: any): Moment {
    if (typeof date === "string") {
        date = date.substr(0, 19);
    }

    return moment(date).utcOffset(0, true);
}
