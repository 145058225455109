import { css } from "styled-components";

export const printCSS = css`
    @page {
        size: 21cm 29.7cm;
        margin: 1cm 1.5cm;
    }

    @media print {
        html,
        body {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }
    }
`;
