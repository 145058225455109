import { forwardRef, ReactNode } from "react";
import styled from "styled-components";

const PageContentStyled = styled.div`
    padding: 2rem 3rem 0 3rem;

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        padding: 2rem 1rem 0 1rem;
    }
`;

type Props = {
    children: ReactNode;
    className?: string;
};

const PageContent = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <PageContentStyled ref={ref} className={props.className}>
            {props.children}
        </PageContentStyled>
    );
});

PageContent.displayName = "PageContent";

export { PageContent };
