import { useCallback, useEffect, useRef, useState } from "react";

import { SEARCH_QUERY_MIN_CHARS } from "components/layout/Navbar/ApplicationSearch/application-search.consts";
import { useOutsideClick } from "hooks/useOutsideClick";

export const useShowResults = (query: string) => {
    const [showResults, setShowResults] = useState(false);
    const ref = useRef(null);

    const handleOpen = useCallback(() => {
        if (query.length < SEARCH_QUERY_MIN_CHARS) return;

        setShowResults(true);
    }, [query]);

    const handleClose = useCallback(() => {
        setShowResults(false);
    }, []);

    useOutsideClick(ref, handleClose);

    // Close search results if search query is too short
    useEffect(() => {
        if (query.length < SEARCH_QUERY_MIN_CHARS) {
            handleClose();
        } else {
            handleOpen();
        }
    }, [query, handleOpen, handleClose]);

    return { ref, showResults, handleOpen, handleClose };
};
