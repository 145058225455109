import { NotificationItem } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItem";
import { NotificationListHeader } from "components/layout/Navbar/Notifications/NotificationList/NotificationListHeader";
import { NotificationListHeaderButton } from "components/layout/Navbar/Notifications/NotificationList/NotificationListHeaderButton";
import { Loading } from "components/ui-deprecated";
import { useLazyGetNotificationsQuery } from "store/services/notification.service";

const NotificationListRead = () => {
    const [trigger, { data, isLoading }] = useLazyGetNotificationsQuery();

    const handleLoadNotifications = () => {
        trigger({ isRead: true });
    };

    return (
        <div>
            <NotificationListHeader>
                Read notifications
                {data === undefined && (
                    <NotificationListHeaderButton onClick={handleLoadNotifications}>Show</NotificationListHeaderButton>
                )}
            </NotificationListHeader>

            {data?.map((message) => <NotificationItem key={message.id} isRead={true} data={message} />)}

            {/* TODO Nicer indication of loading/fetching, requires a better <Loading /> component */}
            {isLoading && <Loading show={isLoading} />}
        </div>
    );
};

export { NotificationListRead };
