import { BooleanFilterItemStyled } from "components/components-deprecated/tables/BooleanFilters/booleanFilters.styled";
import { BooleanFilters } from "components/components-deprecated/tables/BooleanFilters/booleanFilters.types";

type BooleanFilterItemProps = {
    index?: number;
    booleanFilters: BooleanFilters;
    item: string;
    setBooleanFilters: (booleanFilters: BooleanFilters, column: string, checked: boolean) => void;
    setIndexOfExpandedContent?: (index: null | number) => void;
};

const BooleanFilterItem = (props: BooleanFilterItemProps) => {
    const { booleanFilters, item, setBooleanFilters, setIndexOfExpandedContent } = props;
    return (
        <BooleanFilterItemStyled>
            <label>
                <input
                    name={item}
                    type={"checkbox"}
                    checked={booleanFilters[item]}
                    onChange={(e) => {
                        setBooleanFilters(booleanFilters, item, e.target.checked);

                        if (setIndexOfExpandedContent) {
                            // TODO likely deprecated as it seems to used only with expandable table
                            setIndexOfExpandedContent(null);
                        }
                    }}
                />
                <span>{item}</span>
            </label>
        </BooleanFilterItemStyled>
    );
};

export { BooleanFilterItem };
