import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dayjs } from "components/ui/DatePicker/date.utils";
import {
    AllMilkingsRow,
    FarmRow,
    FilterKey,
    MFPPlotterURLSearchParam,
    MilkingDateRow,
} from "pages/MFPPlotterPage/mfp-plotter.types";
import { dateFormat } from "utils/date.utils";
import { getURLSearchParam as _getURLSearchParam, setURLSearchParam as _setURLSearchParam } from "utils/url.utils";

type State = {
    farmLicenseKey: string | null;
    date: string | null;
    datesWithMilkings: string[];
    milkings: AllMilkingsRow[];
    filteredMilkings: AllMilkingsRow[];
    selectedMilking: AllMilkingsRow | null;
    selectedMilkingIndex: number | null;
    filterKey: FilterKey | null;
    filterValue: string | null;
    milkingsRobots: number[];
    milkingsCows: number[];
    milkingsTags: number[];
};

const getURLSearchParam = _getURLSearchParam<MFPPlotterURLSearchParam>;
const setURLSearchParam = _setURLSearchParam<MFPPlotterURLSearchParam>;

const resetMilkingData = (state: State) => {
    state.milkings = [];
    state.filteredMilkings = [];
    state.selectedMilking = null;
    state.selectedMilkingIndex = null;
};

const filterMilkings = (state: State) => {
    const { milkings, filterKey, filterValue } = state;
    if (filterKey === null || filterValue === null) {
        state.filteredMilkings = milkings;
    } else {
        state.filteredMilkings = milkings.filter((milking) => String(milking[filterKey]) === filterValue);
    }

    // Update selected milking index based on new filtered milkings
    if (state.selectedMilking) {
        state.selectedMilkingIndex = state.filteredMilkings.findIndex(
            (milkings) => milkings.visitId === state.selectedMilking.visitId
        );
    }
};

const selectFirstMilking = (state: State) => {
    const firstMilking = state.filteredMilkings.at(0);
    if (firstMilking) {
        state.selectedMilking = firstMilking;
        state.selectedMilkingIndex = 0;
    } else {
        state.selectedMilking = null;
        state.selectedMilkingIndex = null;
    }

    setURLSearchParam("visitId", state.selectedMilking ? state.selectedMilking.visitId.toString() : null);
};

const checkSelectedMilkingPresent = (state: State) => {
    const { filteredMilkings, selectedMilking } = state;

    const selectedMilkingPresent = filteredMilkings.some((milking) => milking.visitId === selectedMilking?.visitId);
    if (selectedMilkingPresent) return;

    selectFirstMilking(state);
};

const initialState: State = {
    farmLicenseKey: getURLSearchParam("farmLicenseKey"),
    date: getURLSearchParam("date"),
    datesWithMilkings: [],
    milkings: [],
    filteredMilkings: [],
    selectedMilking: null,
    selectedMilkingIndex: null,
    filterKey: null,
    filterValue: null,
    milkingsRobots: [],
    milkingsCows: [],
    milkingsTags: [],
};

const slice = createSlice({
    name: "mfp-plotter",
    initialState,
    reducers: {
        setFarmLicenseKey(state, action: PayloadAction<string | null>) {
            state.farmLicenseKey = action.payload;

            setURLSearchParam("farmLicenseKey", state.farmLicenseKey);

            state.datesWithMilkings = [];
            resetMilkingData(state);
        },
        setInitialFarmLicenseKey(state, action: PayloadAction<FarmRow[]>) {
            if (state.farmLicenseKey !== null) return;

            state.farmLicenseKey = action.payload.at(0)?.farmId;
            setURLSearchParam("farmLicenseKey", state.farmLicenseKey);
        },
        setDate(state, action: PayloadAction<string | null>) {
            state.date = dayjs(action.payload).format(dateFormat);

            const date = action.payload;
            setURLSearchParam("date", date ? dayjs(date).format(dateFormat) : null);

            resetMilkingData(state);
        },
        setFilterKey(state, action: PayloadAction<FilterKey | null>) {
            state.filterKey = action.payload;
            state.filterValue = null;

            filterMilkings(state);
            checkSelectedMilkingPresent(state);
        },
        setFilterValue(state, action: PayloadAction<string | null>) {
            state.filterValue = action.payload;

            filterMilkings(state);
            checkSelectedMilkingPresent(state);
        },
        setDatesWithMilkings(state, action: PayloadAction<MilkingDateRow[]>) {
            const dates = action.payload.map((row) => row.date);
            state.datesWithMilkings = dates;

            const dateNormalized = dayjs(state.date).startOf("day").format(dateFormat);
            const datesNormalized = dates.map((date) => dayjs(date).startOf("day").format(dateFormat));
            const dateInDatesWithMilkings = datesNormalized.includes(dateNormalized);

            if (state.date === null || !dateInDatesWithMilkings) {
                state.date = dates?.at(-1);
                setURLSearchParam("date", state.date ? dayjs(state.date).format(dateFormat) : null);
            }
        },
        setMilkings(state, action: PayloadAction<AllMilkingsRow[]>) {
            const milkings = action.payload;
            state.milkings = milkings;

            filterMilkings(state);

            const visitId = getURLSearchParam("visitId");
            const selectMilkingByVisitIdOnLoad = visitId ? parseInt(visitId) : null;
            // If there should be a selected milking on load, select it
            if (selectMilkingByVisitIdOnLoad) {
                const milking = state.filteredMilkings.find(
                    (milking) => milking.visitId === selectMilkingByVisitIdOnLoad
                );
                if (milking) {
                    state.selectedMilking = milking;
                    state.selectedMilkingIndex = state.filteredMilkings.findIndex(
                        (milking) => milking.visitId === selectMilkingByVisitIdOnLoad
                    );
                }
            }

            if (state.selectedMilking === null) {
                selectFirstMilking(state);
            }

            state.milkingsCows = milkings.map((row) => row.cowUserNumber);
            state.milkingsRobots = milkings.map((row) => row.robotLdnId);
            state.milkingsTags = milkings.map((row) => row.tagId);

            // If filter value is not present in the new milkings, reset it
            if (
                state.filterKey === "cowUserNumber" &&
                !state.milkingsCows.some((cow) => String(cow) === state.filterValue)
            ) {
                state.filterValue = null;
            } else if (
                state.filterKey === "robotLdnId" &&
                !state.milkingsRobots.some((robot) => String(robot) === state.filterValue)
            ) {
                state.filterValue = null;
            } else if (
                state.filterKey === "tagId" &&
                !state.milkingsTags.some((tag) => String(tag) === state.filterValue)
            ) {
                state.filterValue = null;
            }
        },
        setSelectedMilking(state, action: PayloadAction<AllMilkingsRow | null>) {
            const selectedMilking = action.payload;

            state.selectedMilking = selectedMilking;
            setURLSearchParam("visitId", state.selectedMilking ? state.selectedMilking.visitId.toString() : null);

            if (selectedMilking === null) {
                state.selectedMilkingIndex = null;
            } else {
                state.selectedMilkingIndex = state.filteredMilkings.findIndex(
                    (milkings) => milkings.visitId === selectedMilking.visitId
                );
            }
        },
        selectPreviousMilking(state) {
            if (state.selectedMilkingIndex === null) return;

            const previousMilking = state.filteredMilkings[state.selectedMilkingIndex - 1];
            if (previousMilking) {
                state.selectedMilking = previousMilking;
                setURLSearchParam("visitId", state.selectedMilking ? state.selectedMilking.visitId.toString() : null);
                state.selectedMilkingIndex = state.selectedMilkingIndex - 1;
            }
        },
        selectNextMilking(state) {
            if (state.selectedMilkingIndex === null) return;

            const nextMilking = state.filteredMilkings[state.selectedMilkingIndex + 1];
            if (nextMilking) {
                state.selectedMilking = nextMilking;
                setURLSearchParam("visitId", state.selectedMilking ? state.selectedMilking.visitId.toString() : null);
                state.selectedMilkingIndex = state.selectedMilkingIndex + 1;
            }
        },
    },
});

export const { reducer: mfpPlotterReducer } = slice;
export const {
    setFarmLicenseKey,
    setInitialFarmLicenseKey,
    setDate,
    setFilterKey,
    setFilterValue,
    setDatesWithMilkings,
    setMilkings,
    setSelectedMilking,
    selectPreviousMilking,
    selectNextMilking,
} = slice.actions;
