import styled from "styled-components";

import { CustomerListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/CustomerListItem";
import { DeviceListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/DeviceListItem";
import { FarmListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/FarmListItem";
import { CustomerExtendedRow } from "components/layout/Navbar/ApplicationSearch/application-search.types";

const DeviceListItemX = styled(DeviceListItem)`
    padding-left: 2.5rem;
`;
const FarmListItemX = styled(FarmListItem)`
    padding-left: 2.5rem;
`;

type Props = {
    data: CustomerExtendedRow;
    onClick: () => void;
};

export const CustomerNestedListItem = (props: Props) => {
    const { data, onClick } = props;

    return (
        <>
            <CustomerListItem data={data} onClick={onClick} />
            {data.farms.map((farm: any) => (
                <FarmListItemX key={farm.FarmLicenseKey} data={farm} onClick={onClick} />
            ))}
            {data.devices.map((device: any) => (
                <DeviceListItemX key={device.DeviceSerialNumber} data={device} onClick={onClick} />
            ))}
        </>
    );
};
