export function assert(condition: boolean, message: string) {
    if (!condition) {
        throw new Error(message);
    }
}
export function isPlainObject(value: unknown): boolean {
    return typeof value === "object" && value !== null && !Array.isArray(value);
}
export function isValidDate(value: unknown): boolean {
    return value instanceof Date && !Number.isNaN(value.getTime());
}
export function isInvalidDate(value: unknown): boolean {
    return value instanceof Date && Number.isNaN(value.getTime());
}
export function stringify(value: unknown): string {
    // NOTE: Might be made more user friendly in the future.
    const stringified = JSON.stringify(value);

    if (typeof stringified === "undefined") {
        return "undefined";
    } else if (stringified === null) {
        return "null";
    } else {
        return stringified;
    }
}
