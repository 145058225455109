import { Flex, Icon } from "components/ui-deprecated";

export type PaginatorProps = {
    dataLength: number;
    currentPage: number;
    pageSize: number;
    setPage: (prev: (arg0: number) => number) => void;
};

export default function Paginator(props: PaginatorProps) {
    const { dataLength, currentPage, pageSize, setPage } = props;
    const pages = Math.ceil(dataLength / pageSize);
    const upperRange = (currentPage + 1) * pageSize;

    return (
        <Flex className={"paginator"} justifyContent={"center"} alignItems={"center"}>
            <span>
                {currentPage * pageSize + 1} - {upperRange > dataLength ? dataLength : upperRange} of {dataLength}
            </span>
            <button disabled={currentPage <= 0} onClick={() => setPage((prev) => prev - 1)}>
                <Icon icon={"angle-left"} />
            </button>
            <button disabled={currentPage >= pages - 1} onClick={() => setPage((prev) => prev + 1)}>
                <Icon icon={"angle-right"} />
            </button>
        </Flex>
    );
}
