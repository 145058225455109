import styled from "styled-components";

export const InputRadio = styled.input.attrs({ type: "radio" })`
    position: relative;

    padding: 0.375rem !important;

    display: inline-block;

    background-color: ${({ theme }) => theme.color.dairyLight};
    border: 1px solid ${({ theme }) => theme.color.grayLight};
    border-radius: 100%;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);

    -webkit-appearance: none;

    &:hover {
        background-color: ${({ theme }) => theme.color.grayDairyLight};
    }

    &:active {
        background-color: ${({ theme }) => theme.color.lelyRedDark};
        border-color: ${({ theme }) => theme.color.lelyRedDark};
    }

    &:checked {
        background-color: ${({ theme }) => theme.color.lelyRed};
        border-color: ${({ theme }) => theme.color.lelyRed};

        &:hover {
            background-color: ${({ theme }) => theme.color.lelyRedDark};
            border-color: ${({ theme }) => theme.color.lelyRedDark};
        }
    }
`;
