import styles from "components/ui/DatePicker/DatePicker.module.css";
import { Popover } from "components/ui/DatePicker/Popover";

type Props = {
    children: React.ReactNode;
};

const DatePickerPopoverContent = (props: Props) => {
    return (
        <Popover.Content
            align="end"
            // Offset, so popover is aligned with the control step "next"
            alignOffset={-32}
        >
            <div className={styles.content}>{props.children}</div>
        </Popover.Content>
    );
};

export { DatePickerPopoverContent };
