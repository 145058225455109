import { Page } from "components/layout/Page";
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { colSpan, height } from "components/widgets/widget.utils";
import { FarmsRegisteredHorizonWidget } from "pages/DashboardPage/fms/widgets/FarmsRegisteredHorizonWidget";
import { HorizonPackageDistributionWidget } from "pages/DashboardPage/fms/widgets/HorizonPackageDistributionWidget";

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

const ManagementSoftwareSection = (props: Props) => {
    return (
        <Page.Content.Section>
            <Page.Content.Section.Title>Management Software</Page.Content.Section.Title>
            <Page.Content.Widgets>
                <WidgetFrame colSpan={colSpan.chartHalf} height={height.chartSm}>
                    <FarmsRegisteredHorizonWidget queryParameters={props.queryParameters} />
                </WidgetFrame>
                <WidgetFrame colSpan={colSpan.chartHalf} height={height.chartSm}>
                    <HorizonPackageDistributionWidget queryParameters={props.queryParameters} />
                </WidgetFrame>
            </Page.Content.Widgets>
        </Page.Content.Section>
    );
};

export { ManagementSoftwareSection };
