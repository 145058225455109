import { css } from "styled-components";

const sidebarCollapsedCSS = css`
    width: 8rem;
`;

const sidebarMobileCollapsedCSS = css`
    left: -18rem;
`;

const sidebarMobileCSS = css`
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        position: absolute;

        width: 18rem;

        ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && sidebarMobileCollapsedCSS};
    }
`;

const sidebarScrollbarCSS = css`
    // TODO Scrollbars

    // scrollbar-color: ${({ theme }) => theme.color.lelyRedDark} ${({ theme }) => theme.color.lelyRed};
    //
    // ::-webkit-scrollbar {
    //     background-color: ${({ theme }) => theme.color.lelyRed};
    // }
    //
    // ::-webkit-scrollbar-track-piece {
    //     border-color: ${({ theme }) => theme.color.lelyRed};
    // }
    //
    // ::-webkit-scrollbar-thumb {
    //     background-color: ${({ theme }) => theme.color.lelyRedDark};
    //
    //     border-left-color: ${({ theme }) => theme.color.lelyRed};
    //     border-right-color: ${({ theme }) => theme.color.lelyRed};
    // }
`;

export const sidebarCSS = css`
    grid-area: sidebar;

    position: relative;
    z-index: 1200;

    width: 18rem;
    height: 100%;

    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.color.lelyRed};

    transition: all 0.25s ease-in;

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && sidebarCollapsedCSS};

    ${sidebarMobileCSS};

    ${sidebarScrollbarCSS};
`;
