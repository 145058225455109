import { Formik, Form } from "formik";
import styled from "styled-components";

import { SwitchCheckbox } from "components/ui-deprecated";
import { FormValues } from "pages/LoginPage/login-page.types";
import { initialValues } from "pages/LoginPage/login-page.utils";

const FormField = styled.div``;

const FormStyled = styled(Form)`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    & > div {
        width: 100%;
    }

    & label {
        display: block;

        font-size: 16px;
        margin-bottom: 10px;
    }

    & input[type="text"],
    & input[type="password"] {
        width: 100%;
        padding: 15px 20px;

        background-color: #fff;
        border: none;
        border-radius: 0.25rem;
        box-shadow: 0 2px 5px #850c1866;

        color: #495057;
        line-height: 16px;

        transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
    }

    & div#toggle {
        display: flex;
        align-items: center;
        gap: 16px;

        & label {
            margin-bottom: 0;
        }
    }

    & button[type="submit"] {
        padding: 16px 32px;

        background-color: #850c18;
        border: none;
        border-radius: 0.25rem;
        box-shadow: 0 2px 5px #850c1866;

        line-height: 16px;

        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
`;

type Props = {
    onSubmit: (data: FormValues) => void;
};

const LoginForm = (props: Props) => {
    return (
        <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
            {({ values, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
                <FormStyled>
                    {/*{message && getErrorMessage(message)}*/}
                    <FormField>
                        <label>Email or username</label>
                        <input
                            type={"text"}
                            name={"username"}
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>

                    <FormField>
                        <label>Password</label>
                        <input
                            type={"password"}
                            name={"password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>

                    <div id="toggle">
                        <span>Remember me</span>
                        <SwitchCheckbox
                            type={"success"}
                            name={"rememberMe"}
                            label={""}
                            checked={values.rememberMe}
                            onChange={(value) => setFieldValue("rememberMe", value)}
                        />
                    </div>

                    <button type={"submit"} className={"button"} disabled={isSubmitting}>
                        Log in
                    </button>
                </FormStyled>
            )}
        </Formik>
    );
};

export { LoginForm };
