import styled from "styled-components";

export const DataContentError = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: ${({ theme }) => theme.color.red};

    font-weight: 500;
`;
