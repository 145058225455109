import clsx from "clsx";

import styles from "components/ui/DatePicker/Controls/Controls.module.css";

type Props = {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
};

const Controls = (props: Props) => {
    return (
        <div className={clsx(styles.controls, props.className)} style={props.style}>
            {props.children}
        </div>
    );
};

export { Controls };
