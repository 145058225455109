import { Tick } from "chart.js";

import { ChartConfiguration } from "components/ui/Chart/chart.types";

export const createLinearYAxis = (options?: {
    position?: "left" | "right";
    axisLabel?: string;
    hideBorder?: boolean;
    hideLabels?: boolean;
    hideGrid?: boolean;
    beginAtZero?: boolean;
    maxTicksLimit?: number;
    stacked?: boolean;
    precision?: number;
    min?: number;
    max?: number;
    getTickLabel?: (tickValue: string | number, index: number, ticks: Tick[]) => string | number | string[] | number[];
}): ChartConfiguration<"line" | "bar">["options"]["scales"]["y"] => ({
    type: "linear",
    position: options?.position ?? "left",
    ticks: {
        display: options?.hideLabels ? false : true,
        padding: 8,
        maxTicksLimit: options?.maxTicksLimit,
        precision: options?.precision ?? 2,
        callback: options?.getTickLabel,
    },
    grid: {
        // hide axis ticks outside of the chart
        tickLength: 0,
        display: options?.hideGrid ? false : true,
    },
    border: {
        display: options?.hideBorder ? false : true,
    },
    title: {
        display: options?.axisLabel ? true : false,
        text: options?.axisLabel,
    },
    beginAtZero: options?.beginAtZero ? true : false,
    stacked: options?.stacked ? true : false,
    max: options?.max,
    min: options?.min,
});
