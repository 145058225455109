import styled, { DefaultTheme } from "styled-components";

const colorMap = (theme: DefaultTheme) => ({
    info: theme.color.blue,
    warning: theme.color.orange,
    danger: theme.color.red,
});

export const NotificationItemIcon = styled.div<{ variant: string }>`
    width: 16px;
    flex-shrink: 0;

    font-size: 16px;
    text-align: center;

    opacity: 0.8;

    color: ${({ theme, variant }) => colorMap(theme)[variant] ?? "unset"};
`;
