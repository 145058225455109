import styled from "styled-components";

export const BooleanFiltersWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const BooleanFilterItemStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.gray};
    border-radius: 4px;
    // FIXME color with alpha
    box-shadow: 0 0 5px #dedbd666;
    height: fit-content;

    label {
        margin-bottom: 0;
        cursor: pointer;

        span {
            text-align: center;
            padding: 4px 8px;
            display: block;
            border-radius: 4px;
        }

        input {
            display: none;

            &:checked + span {
                background-color: ${({ theme }) => theme.color.lelyRed};
                color: ${({ theme }) => theme.color.white};
            }
        }
    }
`;
