import { type IconName } from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import styled from "styled-components";

import "components/ui-deprecated/Icon/Icon.less";

library.add(fas as any);

export type LelyIconName = `lely-icon-${string}`;
export type FontAwesomeIconName = IconName;

const addedIcon: Record<string, ReactElement> = {};
const iconSets: Array<string> = [];

const IconStyled = styled.span`
    display: inline-flex;
    align-items: center;
`;

export type IconProps = {
    // Icon name, will be used from the imported fontawesome 'solid' icon library
    icon: string;
    className?: string;
};

export default function Icon(props: IconProps) {
    const { className } = props;
    const icon = props.icon ?? "";

    if (typeof addedIcon[icon] !== "undefined") {
        return addedIcon[icon];
    } else if (iconSets.reduce((is, set) => (icon.startsWith(set) ? true : is), false)) {
        return (
            <IconStyled
                className={["datamole-ui-components-icon", icon, className, icon]
                    .filter((x) => x !== undefined)
                    .join(" ")}
            />
        );
    } else {
        return <FontAwesomeIcon icon={icon as any} className={[className, "icon"].join(" ")} />;
    }
}
export function addIconAsBase64(name: string, base64: string) {
    addedIcon[name] = <img className={"datamole-ui-components-icon img icon"} src={base64} alt={`icon-${name}`} />;
}
export function addIconSet(classNameStartsWith: string) {
    iconSets.push(classNameStartsWith);
}
