import styled from "styled-components";

import { NotificationItemAction } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItemAction";
import { NotificationItemContent } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItemContent";
import { NotificationItemFooter } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItemFooter";
import { NotificationItemIcon } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItemIcon";
import { NotificationItemMetadata } from "components/layout/Navbar/Notifications/NotificationItem/NotificationItemMetadata";
import { getFormattedDate } from "components/layout/Navbar/Notifications/notifications.utils";
import { Icon } from "components/ui-deprecated";
import { useSetNotificationReadMutation } from "store/services/notification.service";

const NotificationItemStyled = styled.div`
    padding: 8px 16px;

    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
`;

export type NotificationMessage = {
    id: string;
    text: string;
    iconId: string;
    validity: Date;
};

type Props = {
    data: NotificationMessage;
    isRead: boolean;
};

const NotificationItem = (props: Props) => {
    const { data, isRead } = props;
    const { text, validity } = data;

    const [setNotificationRead, { isLoading: isSettingRead }] = useSetNotificationReadMutation();
    const handleReadNotification = async () => {
        try {
            await setNotificationRead(data.id).unwrap();
        } catch (err) {
            // TODO Better exception handling
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };

    let iconId = data.iconId;
    if (iconId === "warning") {
        iconId = "exclamation";
    } else if (iconId === "danger") {
        iconId = "exclamation-triangle";
    }

    return (
        <NotificationItemStyled>
            <NotificationItemContent>
                <NotificationItemIcon variant={data.iconId}>
                    <Icon icon={iconId} />
                </NotificationItemIcon>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </NotificationItemContent>
            <NotificationItemFooter>
                <NotificationItemMetadata>{getFormattedDate(validity)}</NotificationItemMetadata>
                {!isRead && !isSettingRead && (
                    <NotificationItemAction onClick={handleReadNotification}>Mark as read</NotificationItemAction>
                )}
            </NotificationItemFooter>
        </NotificationItemStyled>
    );
};

export { NotificationItem };
