import { z } from "zod";

// eslint-disable-next-line no-restricted-imports
import packageJSON from "../package.json";

const stringSchema = z.string({ required_error: "Not defined" });
const urlSchema = stringSchema.url().refine((val) => val.at(-1) === "/", {
    message: "url should end with the character '/'",
});

const environments = z.enum(["production", "acceptance", "test", "integration", "development"]);
export type ENVIRONMENTS = z.infer<typeof environments>;
const configSchema = z.object({
    MODE: environments.optional(),
    API_URL: urlSchema.default(`${window.location.origin}/`),
    APP_NAME: stringSchema.default("Service Support App"),
    APP_TITLE: stringSchema.default("Lely Service Support App"),
    APP_VERSION: stringSchema.default(`v${packageJSON.version}`),
    CLIENT_ID: stringSchema,
    SENTRY_DSN: stringSchema.optional(),
});

let config: z.infer<typeof configSchema>;
const result = configSchema.safeParse({
    MODE: import.meta.env.MODE,
    API_URL: import.meta.env.VITE_API_URL,
    APP_NAME: import.meta.env.APP_NAME,
    APP_TITLE: import.meta.env.APP_TITLE,
    APP_VERSION: import.meta.env.APP_VERSION,
    CLIENT_ID: import.meta.env.VITE_CLIENT_ID,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
});

class EnvironmentVariablesError extends Error {
    name = "EnvironmentVariablesError";

    constructor(errors: Record<string, string[]>) {
        const message = Object.entries(errors)
            .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
            .join("\n");

        super(`\n\nErrors while validating environment variables:\n\n${message}\n\n`);
    }
}

if (result.success) {
    config = result.data;
} else {
    // FIXME Should work after enabling TS strict mode
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errors = result.error.flatten();
    throw new EnvironmentVariablesError(errors.fieldErrors);
}

// eslint-disable-next-line no-console
console.log(`${config.APP_NAME} ${config.APP_VERSION} on "${config.MODE}" environment`);

export { config };
