import styled from "styled-components";

export const ComboboxInputIcon = styled.span`
    color: ${({ theme }) => theme.color.grayDairyDarker};

    font-size: 1rem;

    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.gray};
    }
`;
