import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "store";
import { setGlobalVariable, GlobalVariables, GlobalVariablesDefaultState } from "store/slices/pages.slice";

export const usePagesState = <T extends keyof GlobalVariablesDefaultState, TContext extends keyof GlobalVariables>(
    key: T,
    options?: { contextType?: TContext }
) => {
    const { contextType = "default" } = options ?? {};

    const value = useAppSelector((state) => state.pages.globals[contextType][key]);
    const dispatch = useAppDispatch();

    const handleSetValue = useCallback(
        (value: GlobalVariables[TContext][T]) => {
            dispatch(setGlobalVariable({ context: contextType, variable: key, value }));
        },
        [contextType, dispatch, key]
    );

    return [value, handleSetValue] as const;
};
