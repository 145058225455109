import { type ChartConfiguration } from "components/ui/Chart/chart.types";
import { baseChartOptions } from "components/ui/Chart/configurations/chart-configurations/base.chart-configuration";
import { createLegendConfiguration } from "components/ui/Chart/configurations/plugins/legend.plugin-configuration";
import { createTooltipConfiguration } from "components/ui/Chart/configurations/plugins/tooltip.plugin-configuration";
import {
    CreateRadarChartDisplayValuesPluginOptions,
    createRadarChartDisplayValuesPlugin,
} from "components/ui/Chart/plugins/radar-values.chart-plugin";

type Options = Pick<CreateRadarChartDisplayValuesPluginOptions, "getTotalValue">;

export const createRadarChartConfiguration = (options?: Options): ChartConfiguration<"radar"> => {
    const displayValuesPlugin = createRadarChartDisplayValuesPlugin(options);

    return {
        type: "radar",
        options: {
            ...baseChartOptions,
            elements: {
                point: { radius: 0, hoverRadius: 0 },
                line: {
                    borderWidth: 1,
                },
            },
            plugins: {
                legend: createLegendConfiguration(),
                tooltip: createTooltipConfiguration(),
            },
        } as ChartConfiguration<"radar">["options"],
        plugins: [displayValuesPlugin],
    };
};
