import { useHasUserArea } from "hooks/useHasUserArea";
import { UserAreaName } from "types/user.types";

type Props = {
    areas: UserAreaName[];
    children: React.ReactNode;
};

const UserAreaBoundary = (props: Props) => {
    const canShow = useHasUserArea(props.areas);
    if (!canShow) return null;

    return <>{props.children}</>;
};

export { UserAreaBoundary };
