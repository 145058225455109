import { ComponentType } from "react";
import styled from "styled-components";

import { TableHeaderCell as TableHeaderCellDefault } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCell";
import { TableHeaderRowProps } from "components/ui/table-final-saviour/Table/TableHeaderRow/table-header-row.types";
import { TableHeaderCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

const TableHeaderRowStyled = styled.tr``;

type Props = TableHeaderRowProps;

const TableHeaderRow = (props: Props) => {
    return (
        <TableHeaderRowStyled>
            {props.columns.map((column, columnIndex) => {
                const TableHeaderCell: ComponentType<TableHeaderCellBaseProps<unknown>> =
                    column.components?.TableHeaderCell ?? TableHeaderCellDefault;
                return <TableHeaderCell key={`header-cell-${columnIndex}`} column={column} columnIndex={columnIndex} />;
            })}
        </TableHeaderRowStyled>
    );
};

export { TableHeaderRow };
