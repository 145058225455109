import { forwardRef, ReactNode } from "react";
import styled from "styled-components";

import { PAGE_ID, PAGE_MAX_WIDTH } from "components/layout/Page/page.utils";

const PageStyled = styled.div`
    position: relative;

    height: 100%;
    width: 100%;

    overflow-y: auto;

    container-type: inline-size;
    container-name: page-container;
`;

const PageInner = styled.div`
    max-width: ${PAGE_MAX_WIDTH}px;
    padding: 3rem 0;
    margin: 0 auto;

    // Referenced by media queries (widget colspan for example)
    container-type: inline-size;
    container-name: page;

    @container page-container (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        padding: 1.5rem 0;
    }
`;

type Props = {
    children: ReactNode;
    className?: string;
};

const Page = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <PageStyled id={PAGE_ID} ref={ref}>
            <PageInner className={props.className}>{props.children}</PageInner>
        </PageStyled>
    );
});

Page.displayName = "Page";

export { Page };
