import { RefCallback, useCallback, useState } from "react";
import useResizeObserver, { ObservedSize } from "use-resize-observer";

const APPROXIMATE_ROW_HEIGHT = 32;
const APPROXIMATE_HEADER_ROW_HEIGHT = 34;

type Options = {
    approximateRowHeight?: number; // only change if the row height is different than the default (32px)
    maxHeight?: number;
    rowCount: number;
};
type Height = "100%" | number;
type ReturnValue = {
    ref: RefCallback<HTMLTableElement>;
    height: Height;
    // Should the scrollbar on the virtualized scroller component be hidden
    hideScrollbar?: boolean;
};

export const useAutoHeight = (options: Options): ReturnValue => {
    const { maxHeight, rowCount, approximateRowHeight = APPROXIMATE_ROW_HEIGHT } = options;

    const [{ height: observedHeight = 0 }, setSize] = useState<ObservedSize>({ width: 0, height: 0 });

    const onResize = useCallback((size) => {
        requestAnimationFrame(() => setSize(size));
    }, []);
    const { ref } = useResizeObserver<HTMLTableElement>({
        // We need to wrap the callback in a requestAnimationFrame, otherwise the resize handler can be triggered
        // more times than the browser can handle, which can cause console errors (mainly on Chromium-based browsers)
        // https://github.com/ZeeCoder/use-resize-observer/issues/38
        onResize: onResize,
    });

    // If no max height provided, table should be 100% of the parent container (parent has to have fixed height)
    if (!maxHeight) return { ref, height: "100%" };

    // Approximate table height based on row count (should be always smaller than observedHeight, unless it is 0)
    // This is needed, otherwise the observedHeight can get stuck on 0 when the rows of the table are changed
    const approximatedHeight = approximateRowHeight * rowCount + APPROXIMATE_HEADER_ROW_HEIGHT;
    // Pick the larger of the two heights
    const minHeight = Math.max(approximatedHeight, observedHeight);
    // Set the height, but limit it to the maxHeight
    const height = Math.min(minHeight, maxHeight);

    // When the observed height is smaller than the max height, letting the scroller component handle
    // the scrollbar visibility  can result in an infinite resize loop, which is caused by recomputing
    // the height upon showing/hiding the scrollbar.
    // It's clear that the scrollbar should be hidden in this case, so we can just explicitly set it here.
    const hideScrollbar = observedHeight <= maxHeight;

    return { ref, height, hideScrollbar };
};
