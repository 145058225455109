export const CHART_FONT = "Ubuntu";

export type ChartColorTransparency = "33" | "ff";
export const chartColorTransparency = {
    transparent: "33",
    none: "ff",
} satisfies Record<string, ChartColorTransparency>;

type GetColorGradientByValuesOptions = {
    values: number[];
    colors: string[];
};
export const getColorGradientByValues = (options: GetColorGradientByValuesOptions) => {
    const { values, colors } = options;

    const min = Math.min(...values);
    const max = Math.max(...values);
    const step = (max - min) / (colors.length - 1);

    return values.map((value) => {
        const index = Math.floor((value - min) / step);

        return colors[index];
    });
};
