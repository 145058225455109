import styled from "styled-components";

import { SearchResultList } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultList";
import { SearchResultMenuContent } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultMenuContent";
import { toSearchResultItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-menu.utils";
import { SearchCategory, SearchResults } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { Loader } from "components/ui/Loader";

const SearchMenuStyled = styled.div`
    max-height: 30rem;
    padding: 0.25rem 0;

    overflow-y: auto;
`;

type Props = {
    data: SearchResults;
    query: string;
    category: SearchCategory;
    isLoading: boolean;
    onClick: () => void;
};

const SearchResultMenu = (props: Props) => {
    const items = toSearchResultItem(props.category, props.data);

    const showLoading = props.isLoading;
    const showNoData = !showLoading && items.length === 0;
    const showData = !showLoading && items.length > 0;

    return (
        <SearchMenuStyled>
            {showLoading && (
                <SearchResultMenuContent>
                    <Loader size={"24px"} />
                </SearchResultMenuContent>
            )}
            {showNoData && (
                <SearchResultMenuContent>
                    No matching results for <b>{props.query}</b> were found
                </SearchResultMenuContent>
            )}
            {showData && <SearchResultList data={items} onClick={props.onClick} />}
        </SearchMenuStyled>
    );
};

export { SearchResultMenu };
