import { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";

import { SIDEBAR_NAVIGATION_ITEM_CLASS } from "components/layout/Sidebar/SidebarNavigation/sidebar-navigation-item.consts";
import { sidebarNavigationItemCSS } from "components/layout/Sidebar/SidebarNavigation/sidebar-navigation-item.styles";
import { SidebarLinkMenu } from "components/layout/Sidebar/sidebar.types";
import { NavLink } from "components/ui/NavLink";
import { Icon, Popover } from "components/ui-deprecated";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useUserAreas } from "hooks/useUserAreas";
import { routes } from "routes";
import { useAppSelector } from "store";

const SidebarNavigationItemMenuStyled = styled.div`
    ${sidebarNavigationItemCSS};

    justify-content: space-between;

    & > div > .datamole-ui-components-icon {
        margin-right: 1rem;

        font-size: 1rem;
    }
`;

const collapsedCSS = css`
    padding: 1rem 3.5rem;

    & > .title {
        display: none;
    }
`;

const SidebarNavigationItemMenuItemStyled = styled(NavLink)`
    ${sidebarNavigationItemCSS};

    padding: 0.75rem 2rem;

    background-color: ${({ theme }) => theme.color.lelyRedDark};

    color: ${({ theme }) => theme.color.grayDairy};

    font-size: 0.75rem;
    font-weight: 400;

    &.active {
        color: ${({ theme }) => theme.color.white};

        font-weight: 500;
    }

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && collapsedCSS};
`;

type Props = {
    item: SidebarLinkMenu;
    sidebarCollapsed: boolean;
    onClick?: () => void;
};

const SidebarNavigationItemMenu = (props: Props) => {
    const { item, sidebarCollapsed } = props;
    const { title, icon, links } = item;

    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);
    const ref = useRef<HTMLDivElement>(null);

    const userAreas = useUserAreas();
    const developerMode = useAppSelector((state) => state.user.developerMode);

    useOutsideClick(menuRef, () => sidebarCollapsed && setOpen(false));

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const targetIsElement = event.target instanceof HTMLElement || event.target instanceof SVGElement;
            if (!ref.current || !targetIsElement) return;

            const refContainsTarget = ref.current.contains(event.target);
            const targetIsSidebarNavigationItem = event.target.closest(`.${SIDEBAR_NAVIGATION_ITEM_CLASS}`);

            if (refContainsTarget || !targetIsSidebarNavigationItem) return;

            setOpen(false);
        };

        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    let mainItem = (
        <SidebarNavigationItemMenuStyled
            ref={menuRef}
            className={`content ${open ? "active" : ""} ${SIDEBAR_NAVIGATION_ITEM_CLASS}`}
            onClick={() => setOpen(!open)}
        >
            <div>
                <Icon icon={icon} />
                <span className={"title"}>{title}</span>
            </div>
            <Icon icon={open ? "angle-up" : "angle-down"} />
        </SidebarNavigationItemMenuStyled>
    );

    if (sidebarCollapsed) {
        mainItem = (
            <Popover trigger={"hover"} placement={"right"} overlay={title}>
                {mainItem}
            </Popover>
        );
    }

    return (
        <div ref={ref}>
            {mainItem}
            {open && (
                <div>
                    {links.map((getLink) => {
                        const link = getLink({ userAreas, developerMode });
                        if (!link) return null;

                        let item = (
                            <SidebarNavigationItemMenuItemStyled key={link.route} to={routes[link.route].path}>
                                <Icon icon={link.icon} />
                                <span className={"title"}>{link.title}</span>
                            </SidebarNavigationItemMenuItemStyled>
                        );

                        if (sidebarCollapsed) {
                            item = (
                                <Popover key={link.route} trigger={"hover"} placement={"right"} overlay={link.title}>
                                    {item}
                                </Popover>
                            );
                        }

                        return item;
                    })}
                </div>
            )}
        </div>
    );
};

export { SidebarNavigationItemMenu };
