import { useContext, useEffect, useState } from "react";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import InnerTableLayout from "components/components-deprecated/tables/components/InnerTableLayout";
import { getCellContent } from "components/components-deprecated/tables/tables.helper";
import { ExpandableRowWithInnerLayoutProps } from "components/components-deprecated/tables/tables.interfaces";
import { render } from "components/components-deprecated/tables/widget-hooks.helper";
import { Flex, Icon } from "components/ui-deprecated";
import { processData, toDataWrapper } from "deprecated/data-wrapper/data.utils";
import { toDataSourceParameters, useLazyDataSource } from "hooks/useDataSource";
import { DataSourceParameter } from "store/services/data-source.service";

const ExpandableRowWithInnerLayout: React.FC<ExpandableRowWithInnerLayoutProps> = ({
    expandableQueryParameters,
    row,
    level,
    headers,
    widerColumns,
    innerTableLayout,
}) => {
    const [queryData, setQueryData] = useState();
    const [expanded, setExpanded] = useState(false);
    const { formatter, fieldsToLinks, customColumnTypes, combineColumns, setContextFromColumns, currentPage } =
        useContext(TableContext);

    const level1QueryParameters = (Object.entries(expandableQueryParameters?.level1?.parameters ?? {}).map(
        ([name, dataKey]) => ({
            name,
            value: row[dataKey],
        })
    ) ?? []) as DataSourceParameter[];
    const level2QueryParameters = (Object.entries(expandableQueryParameters?.level2?.parameters ?? {}).map(
        ([name, dataKey]) => ({
            name,
            value: row[dataKey],
        })
    ) ?? []) as DataSourceParameter[];
    const [trigger, { data: innerData, isFetching, isError }] = useLazyDataSource(
        expandableQueryParameters?.level1?.dataSource
    );

    const handleExpandRow = () => {
        trigger([
            ...toDataSourceParameters(expandableQueryParameters?.level0),
            ...level1QueryParameters,
            ...level2QueryParameters,
        ]);

        setQueryData(queryData);
        setExpanded(!expanded);
    };

    useEffect(() => {
        setExpanded(false);
    }, [currentPage]);

    const innerDataProcessed = processData(innerData, expandableQueryParameters?.level1?.processDataConfig ?? {});
    const innerDataWrapper = toDataWrapper(innerDataProcessed);

    return (
        <div className={"expandable-row-container"}>
            <Flex key={JSON.stringify(row)} className={"basic-table-row expandable-row"} onClick={handleExpandRow}>
                <Flex className={"toggle-button-wrapper"}>
                    <div className={"toggle-button"}>
                        <Icon icon={expanded ? "lely-icon-caret-up" : "lely-icon-caret-down"} />
                    </div>
                </Flex>
                {headers.map((field) => (
                    <Flex className={`basic-table-cell ${widerColumns.includes(field) ? "wider" : ""}`} key={field}>
                        {(() => {
                            return getCellContent(
                                row,
                                field,
                                formatter,
                                fieldsToLinks,
                                customColumnTypes,
                                combineColumns,
                                setContextFromColumns
                            );
                        })()}
                    </Flex>
                ))}
            </Flex>
            {expanded && (
                <div style={{ margin: "20px" }}>
                    {render(
                        <div className={"row-expansion"}>
                            <InnerTableLayout layout={innerTableLayout} data={innerDataWrapper} level={level + 1} />
                        </div>,
                        isError,
                        !!innerData,
                        isFetching
                    )}
                </div>
            )}
        </div>
    );
};

export default ExpandableRowWithInnerLayout;
