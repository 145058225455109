import styled from "styled-components";

import { Link } from "components/ui/Link";

const LinkStyled = styled(Link)`
    min-width: max-content;

    text-decoration: underline;
    color: ${({ theme }) => theme.color.grayDairyDarker};

    &:hover {
        color: ${({ theme }) => theme.color.grayDairyDarker};
    }
`;

type Props = {
    to: string;
    name: string;
};

const WidgetFrameTitleLink = ({ to, name }: Props) => {
    return <LinkStyled to={to}>{name}</LinkStyled>;
};

export { WidgetFrameTitleLink };
