import { api as baseApi } from "store/api";
import { Cluster } from "types/cluster.types";
import { GUID } from "types/common.types";

const TAG_CLUSTER = "TAG_CLUSTER";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_CLUSTER],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getClusters: builder.query<Cluster[], void>({
                query: () => ({
                    url: `admin/cluster`,
                }),
                providesTags: () => [TAG_CLUSTER],
            }),
            getUserClusters: builder.query<Cluster[], { userId: GUID }>({
                query: ({ userId }) => ({
                    url: `cluster/user/${userId}`,
                }),
                providesTags: () => [TAG_CLUSTER],
            }),
            setUserClusters: builder.mutation<void, { userId: GUID; clusterIds: GUID[] }>({
                query: ({ userId, clusterIds }) => ({
                    url: `cluster/user/${userId}/filter`,
                    method: "put",
                    body: { clusters: clusterIds },
                }),
                invalidatesTags: () => [TAG_CLUSTER],
            }),
        }),
    });

export const { useGetClustersQuery, useGetUserClustersQuery, useSetUserClustersMutation } = api;
