import styled from "styled-components";

import logoImage from "components/layout/Sidebar/assets/logo.png";

const LoginPageLogoStyled = styled.div`
    width: 160px;

    img {
        width: 100%;
        height: 100%;
    }
`;

const LoginPageLogo = () => {
    return (
        <LoginPageLogoStyled>
            <img src={logoImage} alt={"logo"} />
        </LoginPageLogoStyled>
    );
};

export { LoginPageLogo };
