import styled from "styled-components";

export const FeedbackStyled = styled.div`
    position: absolute;
    bottom: 6rem;
    right: -2rem;
    z-index: 2500;

    transform: rotate(-90deg);

    height: 2rem;

    padding-block-start: 0.25rem;
    padding-block-end: 0.25rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;

    background-color: ${({ theme }) => theme.color.lelyRed};
    border-start-start-radius: 0.5rem;
    border-start-end-radius: 0.5rem;
    box-shadow: 0 0 0.25rem #c30a1466;

    color: ${({ theme }) => theme.color.white};
    font-weight: 500;
    letter-spacing: 0.025rem;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.lelyRedDark};
        box-shadow: 0 0 1rem #c30a1466;
    }
`;

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
