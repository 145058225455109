import { ReactNode } from "react";
import styled from "styled-components";

const PageContentWidgetsStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 32px;
`;

type Props = {
    children: ReactNode;
};

const PageContentWidgets = (props: Props) => {
    return <PageContentWidgetsStyled>{props.children}</PageContentWidgetsStyled>;
};

export { PageContentWidgets };
