import { Chart, Plugin } from "chart.js";

export const createVerticalLinePlugin = (): Plugin<"line"> => ({
    id: "verticalLinePlugin",
    beforeDraw: (chart: Chart) => {
        if (chart.getActiveElements().length) {
            const activePoint = chart.getActiveElements()[0];
            const chartArea = chart.chartArea;
            const ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(activePoint.element.x, chartArea.top);
            ctx.lineTo(activePoint.element.x, chartArea.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
            ctx.stroke();
            ctx.restore();
        }
    },
});
