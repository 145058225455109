import { useState, useRef, ReactNode } from "react";

import Tooltip from "components/ui-deprecated/Tooltip/Tooltip";
import { useOutsideClick } from "hooks/useOutsideClick";
import "components/ui-deprecated/Popover/Popover.less";

export type PopoverProps = {
    arrow: boolean;
    placement: "bottom" | "right";
    trigger: "hover" | "click";
    overlay: ReactNode;
    children: ReactNode;
};

Popover.defaultProps = {
    arrow: true,
    placement: "bottom",
    trigger: "hover",
};

export default function Popover({ arrow, trigger, placement, overlay, children }: PopoverProps) {
    const ref = useRef<null | any>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const position = getPosition(ref.current, placement);
    useOutsideClick(ref, () => trigger === "click" && setShowTooltip(false));
    return (
        <div
            className={"datamole-ui-components-popover"}
            onClick={() => trigger === "click" && setShowTooltip(true)}
            onMouseEnter={() => trigger === "hover" && setShowTooltip(true)}
            onMouseLeave={() => trigger === "hover" && setShowTooltip(false)}
            ref={ref}
        >
            {showTooltip && (
                <Tooltip
                    show={true}
                    position={{
                        y: position.top,
                        x: position.left,
                        padding: position.padding,
                    }}
                >
                    <div className={`datamole-ui-components-popover-tooltip ${placement}`}>
                        {arrow && <div className={"arrow"} />}
                        <div className={"content"}>{overlay}</div>
                    </div>
                </Tooltip>
            )}
            {children}
        </div>
    );
}

function getPosition(
    div: null | HTMLElement,
    placement: "bottom" | "right"
): {
    top: number;
    left: number;
    padding: number;
} {
    if (div) {
        const { top, left, height, width } = div.getBoundingClientRect();

        if (placement === "bottom") {
            return {
                top: top + height,
                left,
                padding: 0,
            };
        } else {
            return {
                top,
                left: left + width / 2,
                padding: width / 2,
            };
        }
    } else {
        return {
            top: 0,
            left: 0,
            padding: 0,
        };
    }
}
