import { SidebarControl } from "components/layout/Sidebar/SidebarControls/SidebarControl";
import { UserRoleSelectContent } from "components/layout/Sidebar/SidebarControls/UserRoleSelect/UserRoleSelectContent";
import { useAppSelector } from "store";

const UserRoleSelect = () => {
    const primaryRoles = useAppSelector((state) => state.user.primaryCombinedRole.children);
    const secondaryRoles = useAppSelector((state) => state.user.secondaryRoles);
    const primaryActiveRoleId = useAppSelector((state) => state.user.primaryActiveRoleId);

    const hasOnlyOnePrimaryRole = primaryRoles.length === 1;
    const hasNoSecondaryRoles = secondaryRoles.length === 0;
    if (hasOnlyOnePrimaryRole && hasNoSecondaryRoles) return null;

    const primaryActiveRole = primaryRoles.find((role) => role.id === primaryActiveRoleId);

    return (
        <SidebarControl title={"Roles"} subtitle={primaryActiveRole?.name} icon={"lely-icon-human"}>
            {({ handleToggleOpen }) => <UserRoleSelectContent handleToggleOpen={handleToggleOpen} />}
        </SidebarControl>
    );
};

export { UserRoleSelect };
