import { ComponentPropsWithoutRef, forwardRef } from "react";
import styled, { css } from "styled-components";

type Variant = "primary" | "secondary" | "text";

const primaryVariant = css`
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;

    background-color: ${({ theme }) => theme.color.lelyRed};
    color: ${({ theme }) => theme.color.dairyLight};

    &:hover:not(:disabled) {
        box-shadow: 0 3px 10px -3px ${({ theme }) => theme.color.lelyRedDark};
    }
`;

const textVariant = css`
    text-decoration: underline;

    &:hover:not(:disabled) {
        color: ${({ theme }) => theme.color.textGray};

        text-decoration: none;
    }
`;

const ButtonStyled = styled.button<{ variant: Variant }>`
    background-color: unset;
    border: none;
    outline: none;

    font-size: 0.875rem;
    line-height: 1;

    transition: box-shadow 0.2s ease-in;

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    ${({ variant }) => variant === "primary" && primaryVariant};
    ${({ variant }) => variant === "text" && textVariant};
`;

type Props = ComponentPropsWithoutRef<"button"> & { variant?: Variant };

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const variant = props.variant ?? "primary";

    return (
        <ButtonStyled {...props} ref={ref} variant={variant}>
            {props.children}
        </ButtonStyled>
    );
});

Button.displayName = "Button";

export { Button };
export { type Variant as ButtonVariant };
