import styled, { css } from "styled-components";

import { Checkbox } from "components/Checkbox";
import { splitAndCapitalize } from "utils/string.utils";

type WidgetFrameTitleCheckboxProps = {
    checked: boolean;
    onChange: () => void;
    name: string;
    label?: string;
};

const smSizeCSS = css`
    height: 1.5rem;
    margin: -0.125rem 0;
`;

const WidgetFrameTitleCheckboxStyled = styled.div`
    height: 1.5rem;
    display: flex;
    justify-content: flex-end;
    ${({ theme: { widgetSize } }) => widgetSize === "sm" && smSizeCSS};
`;

const WidgetFrameTitleCheckbox = ({ name, label, checked, onChange }: WidgetFrameTitleCheckboxProps) => {
    const checkboxLabel = label ?? splitAndCapitalize(name);
    return (
        <WidgetFrameTitleCheckboxStyled>
            <Checkbox name={name} label={checkboxLabel} value={checked} onChange={onChange} />
        </WidgetFrameTitleCheckboxStyled>
    );
};

export { WidgetFrameTitleCheckbox };
