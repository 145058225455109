import { ComponentType } from "react";
import styled from "styled-components";

import { CustomerNestedListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/CustomerExtendedListItem";
import { CustomerListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/CustomerListItem";
import { DeviceListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/DeviceListItem";
import { FarmListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/FarmListItem";
import { TechnicianListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/search-result-list-items/TechnicianListItem";
import {
    SearchResultItem,
    SearchResultItemType,
} from "components/layout/Navbar/ApplicationSearch/application-search.types";

const SearchResultListStyled = styled.div`
    width: 100%;
`;

const itemComponentMap: Record<SearchResultItemType, ComponentType<any>> = {
    customer: CustomerListItem,
    customerExtended: CustomerNestedListItem,
    device: DeviceListItem,
    farm: FarmListItem,
    technician: TechnicianListItem,
};

type Props = {
    data: SearchResultItem[];
    onClick?: () => void;
};

const SearchResultList = (props: Props) => {
    const sortedData = props.data.sort((a, b) => a.sortValue?.localeCompare(b.sortValue));

    return (
        <SearchResultListStyled>
            {sortedData.map((item, index) => {
                const ItemComponent = itemComponentMap[item.type];
                return <ItemComponent key={index} data={item.data} onClick={props.onClick} />;
            })}
        </SearchResultListStyled>
    );
};

export { SearchResultList };
