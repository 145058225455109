import { useState } from "react";

import { Calendar } from "components/ui/DatePicker/Calendar/Calendar";
import { Controls } from "components/ui/DatePicker/Controls";
import { ControlsNext } from "components/ui/DatePicker/Controls/ControlsNext";
import { ControlsPrevious } from "components/ui/DatePicker/Controls/ControlsPrevious";
import { ControlsTrigger } from "components/ui/DatePicker/Controls/ControlsTrigger";
import { DatePickerPopoverContent } from "components/ui/DatePicker/DatePickerPopoverContent";
import { DatePickerPopoverContentDivider } from "components/ui/DatePicker/DatePickerPopoverContentDivider";
import { Popover } from "components/ui/DatePicker/Popover";
import { Presets } from "components/ui/DatePicker/Presets";
import { SELECT_TIMEOUT } from "components/ui/DatePicker/date-picker.utils";
import { dateFormat, Dayjs, dayjs } from "components/ui/DatePicker/date.utils";
import { presets } from "components/ui/DateRangePicker/date-range-picker.utils";

type Props = {
    dateFrom: string;
    dateTo: string;
    setDateFrom: (value: string) => void;
    setDateTo: (value: string) => void;
    className?: string;
    style?: React.CSSProperties;
};

const DateRangePicker = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const dateFrom = dayjs(props.dateFrom).startOf("day");
    const dateTo = dayjs(props.dateTo).endOf("day");

    const dateFromString = dateFrom.format("MMM Do");
    const dateToString = dateTo.format("MMM Do, YYYY");
    const dateFromYearString = dateFrom.format("YYYY");
    const dateToYearString = dateTo.format("YYYY");
    const dateFromOptionalYearString = dateFromYearString !== dateToYearString ? `, ${dateFromYearString}` : "";
    const label = `${dateFromString}${dateFromOptionalYearString} - ${dateToString}`;

    const [selectionDateFrom, setSelectionDateFrom] = useState<Dayjs | null>(null);
    const [selectionDateTo, setSelectionDateTo] = useState<Dayjs | null>(null);

    const selected = {
        from: (selectionDateFrom ?? dateFrom)?.toDate(),
        to: (selectionDateFrom ? selectionDateTo : dateTo)?.toDate(),
    };

    const handleSelectDateRange = (dateFrom: Dayjs, dateTo: Dayjs) => {
        props.setDateFrom(dateFrom.startOf("day").format(dateFormat));
        props.setDateTo(dateTo.endOf("day").format(dateFormat));

        setTimeout(() => {
            setIsOpen(false);
        }, SELECT_TIMEOUT);
    };

    const handleSelection = (date: Dayjs) => {
        // If selection is defined, select the date range, otherwise initiate the selection
        if (selectionDateFrom && selectionDateTo) {
            handleSelectDateRange(selectionDateFrom, selectionDateTo);
            setSelectionDateFrom(null);
            setSelectionDateTo(null);
        } else {
            setSelectionDateFrom(dayjs(date));
            setSelectionDateTo(null);
        }
    };

    const handleDayHover = (date: Dayjs) => {
        // If the selection is happening, use the hovered date as the end of the range
        if (!selectionDateFrom) return;

        setSelectionDateTo(date);
    };

    const handleDisableBeforeSelectionDates = (date: Dayjs) => {
        if (!selectionDateFrom) return false;

        return date.isBefore(selectionDateFrom);
    };

    const handleSelectPrevRange = () => {
        const diff = dateTo.diff(dateFrom, "day") + 1;
        const from = dateFrom.subtract(diff, "day");
        const to = dateTo.subtract(diff, "day");
        handleSelectDateRange(from, to);
    };

    const handleSelectNextRange = () => {
        const diff = dateTo.diff(dateFrom, "day") + 1;
        const from = dateFrom.add(diff, "day");
        const to = dateTo.add(diff, "day");
        handleSelectDateRange(from, to);
    };

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <Controls className={props.className} style={props.style}>
                <ControlsPrevious onClick={handleSelectPrevRange} />
                <ControlsTrigger>{label}</ControlsTrigger>
                <ControlsNext onClick={handleSelectNextRange} />
            </Controls>
            <DatePickerPopoverContent>
                <Presets
                    presets={presets}
                    selectedDate={{ from: dateFrom, to: dateTo }}
                    setSelectedDate={({ from, to }) => {
                        handleSelectDateRange(from, to);
                    }}
                />
                <DatePickerPopoverContentDivider />
                <Calendar
                    mode={"range"}
                    selected={selected}
                    disabled={(date) => handleDisableBeforeSelectionDates(dayjs(date))}
                    onDayClick={(date: Date) => handleSelection(dayjs(date))}
                    onDayMouseEnter={(date: Date) => handleDayHover(dayjs(date))}
                    onDayFocus={(date: Date) => handleDayHover(dayjs(date))}
                />
            </DatePickerPopoverContent>
        </Popover>
    );
};

export { DateRangePicker };
