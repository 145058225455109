import moment from "deprecated/data-utils/moment/moment";
import type { Pipeline, Row } from "deprecated/data-utils/types";
import { sort as dwSort } from "deprecated/data-wrapper";

export type SortConfiguration = Array<{
    fieldName: string;
    order: "ASC" | "DESC";
}>;

/**
 * @example
 * import { sort } from "core/data-utils";
 * let pipeline = sort([ { fieldName: "Year", order: "ASC" } ]);
 * let output = pipeline(data);
 */

export default function sort(sortConfiguration: SortConfiguration): Pipeline {
    function compare(row1: Row, row2: Row): number {
        for (const { fieldName, order } of sortConfiguration) {
            let value1 = row1[fieldName];
            let value2 = row2[fieldName];

            if (value1 === value2) {
                continue;
            } else if ((value1 === null || value1 === " ") && value2 !== null) {
                return order === "ASC" ? -1 : 1;
            } else if (value1 !== null && (value2 === null || value2 === " ")) {
                return order === "ASC" ? 1 : -1;
            } else if (typeof value1 === "number" && typeof value2 === "number") {
                return order === "ASC" ? value1 - value2 : value2 - value1;
            } else if (typeof value1 === "string" && typeof value2 === "string") {
                value1 = value1.toUpperCase().trim().replace(/\W/, "");
                value2 = value2.toUpperCase().trim().replace(/\W/, "");

                // TODO: FIX issue when column displays values from different column
                if (value1 > value2) {
                    return order === "ASC" ? 1 : -1;
                } else if (value1 < value2) {
                    return order === "ASC" ? -1 : 1;
                }
            } else if (moment(value1).isValid() && moment(value2).isValid()) {
                if (moment(value1) > moment(value2)) {
                    return order === "ASC" ? -1 : 1;
                } else if (moment(value2) > moment(value1)) {
                    return order === "ASC" ? 1 : -1;
                }
            }
        }

        return 0;
    }

    return (dw) => {
        return dwSort(dw, compare);
    };
}
