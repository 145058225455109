import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { Content } from "components/layout/Content";
import { Feedback } from "components/layout/Feedback";
import { PageLoader } from "components/layout/PageLoader";
import logoImage from "components/layout/Sidebar/assets/logo.png";
import { FmsReportNavbar } from "components/layout/fms-report/FmsReportNavbar";
import { FmsReportNavbarContent } from "components/layout/fms-report/FmsReportNavbarContent";
import { FmsReportNavbarLogo } from "components/layout/fms-report/FmsReportNavbarLogo";
import { NotificationBoard } from "components/ui-deprecated";
import { theme } from "styles/theme";

const LayoutStyled = styled.div`
    position: relative;

    height: 100%;
    width: 100%;

    display: grid;

    grid-template-rows: max-content 1fr;
    grid-template-areas:
        "navbar"
        "content";
`;

type FmsReportLayoutProps = {
    isTokenValid: boolean;
};

export const FmsReportLayout = ({ isTokenValid }: FmsReportLayoutProps) => {
    return (
        <ThemeProvider theme={{ ...theme }}>
            <LayoutStyled>
                <FmsReportNavbar>
                    <FmsReportNavbarContent>
                        <FmsReportNavbarLogo src={logoImage} alt={"logo"} />
                        Service Support App
                    </FmsReportNavbarContent>
                </FmsReportNavbar>
                <Content>
                    <Suspense fallback={<PageLoader />}>
                        {isTokenValid === null && <PageLoader />}
                        {/* TODO: create InvalidToken component */}
                        {isTokenValid ? <Outlet /> : "Invalid token"}
                    </Suspense>
                </Content>
                <Feedback />
                <NotificationBoard />
            </LayoutStyled>
        </ThemeProvider>
    );
};
