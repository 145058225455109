import { PageHeader as _PageHeader } from "components/layout/Page/PageHeader/PageHeader";
import { PageHeaderDataContent } from "components/layout/Page/PageHeader/PageHeaderDataContent";
import { PageHeaderTitle } from "components/layout/Page/PageHeader/PageHeaderTitle";
import { PageHeaderWidgetFrame } from "components/layout/Page/PageHeader/PageHeaderWidgetFrame";
import { PageHeaderWidgets } from "components/layout/Page/PageHeader/PageHeaderWidgets";

const PageHeader = _PageHeader as typeof _PageHeader & {
    DataContent: typeof PageHeaderDataContent;
    Title: typeof PageHeaderTitle;
    WidgetFrame: typeof PageHeaderWidgetFrame;
    Widgets: typeof PageHeaderWidgets;
};

PageHeader.DataContent = PageHeaderDataContent;
PageHeader.Title = PageHeaderTitle;
PageHeader.WidgetFrame = PageHeaderWidgetFrame;
PageHeader.Widgets = PageHeaderWidgets;

export { PageHeader };
