import { useEffect } from "react";

export const OUTSIDE_CLICK_NO_CLOSE_CLASS = "outside-click-no-close";

export const useOutsideClick = (
    ref: { current: null | HTMLElement },
    onClick: (clickedElement: HTMLElement | SVGElement) => void
) => {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const targetIsElement = event.target instanceof HTMLElement || event.target instanceof SVGElement;
            if (!ref.current || !targetIsElement) return;

            const refContainsTarget = ref.current.contains(event.target);
            const targetHasNoCloseClass = event.target.closest(`.${OUTSIDE_CLICK_NO_CLOSE_CLASS}`);
            if (refContainsTarget || targetHasNoCloseClass) return;

            onClick(event.target);
        };

        document.addEventListener("click", handleClick, { capture: true });
        return () => {
            document.removeEventListener("click", handleClick, { capture: true });
        };
    });
};
