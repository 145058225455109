import { Alert } from "@datamole/wds-component-alert";
import { Button } from "@datamole/wds-component-button";
import { useState } from "react";

import { Dialog } from "components/ui/Dialog";
import { Icon } from "components/ui-deprecated";
import { dataSourceIdMap } from "hooks/useDataSource";
import { InsightsWidgetExpandedDataRow } from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";
import { useAppDispatch, useAppSelector } from "store";
import { dataSourceApi, TAG_DATA_SOURCE } from "store/services/data-source.service";
import { useSetInsightMutation } from "store/services/insights.service";

type Props = {
    data: InsightsWidgetExpandedDataRow;
};

const ConfirmInsightButton = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const userId = useAppSelector((state) => state.user.id);
    const dispatch = useAppDispatch();

    const [setInsight] = useSetInsightMutation();

    const handleSubmit = async (options: { insightWasUseful: boolean }) => {
        await setInsight({
            insightId: props.data.InsightId,
            insightCreatedDate: props.data.InsightCreatedDate,
            useful: options.insightWasUseful,
            removeLines: true,
            userId,
        });
        setIsOpen(false);
        dispatch(
            dataSourceApi.util.invalidateTags([
                {
                    type: TAG_DATA_SOURCE,
                    id: JSON.stringify({
                        id: dataSourceIdMap["Insights_ExpandedDetail"],
                        parameters: [
                            {
                                name: "customerNumber",
                                value: props.data.CustomerNumber,
                            },
                        ],
                    }),
                },
            ])
        );
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Trigger asChild>
                <Button size={"sm"}>
                    <Button.Icon>
                        <Icon icon={"check"} />
                    </Button.Icon>
                    <Button.Content>Done</Button.Content>
                </Button>
            </Dialog.Trigger>
            <Dialog.Content>
                <Dialog.Content.Title>Evaluate Insight</Dialog.Content.Title>
                <Dialog.Content.Body>
                    <Alert severity={"info"}>
                        <Alert.Icon />
                        <Alert.Content>
                            <Alert.Title>Was this insight useful for you?</Alert.Title>
                            Your response will help us improve the quality of the insight list in the future.
                        </Alert.Content>
                    </Alert>
                </Dialog.Content.Body>
                <Dialog.Content.Actions>
                    <Button onClick={() => setIsOpen(false)}>
                        <Button.Icon>
                            <Icon icon={"times"} />
                        </Button.Icon>
                        <Button.Content>Cancel</Button.Content>
                    </Button>
                    <Button onClick={() => handleSubmit({ insightWasUseful: false })}>
                        <Button.Icon>
                            <Icon icon={"thumbs-down"} />
                        </Button.Icon>
                        <Button.Content>No, it was not useful</Button.Content>
                    </Button>
                    <Button onClick={() => handleSubmit({ insightWasUseful: true })} variant={"primary"}>
                        <Button.Icon>
                            <Icon icon={"thumbs-up"} />
                        </Button.Icon>
                        <Button.Content>Yes, it was useful</Button.Content>
                    </Button>
                </Dialog.Content.Actions>
            </Dialog.Content>
        </Dialog>
    );
};

export { ConfirmInsightButton };
