import { useCallback, useMemo, useState } from "react";

import { isDataColumn } from "components/ui/table-final-saviour/Table/columns/table-columns.utils";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";
import { readCellValue } from "components/ui/table-final-saviour/Table/table.utils";
import { normalize } from "utils/string.utils";

type UseSearchOptions<T extends Row> = {
    columns: Column<T>[];
};

export const useSearch = <T extends Row>(options: UseSearchOptions<T>) => {
    const { columns } = options;

    const [searchValue, setSearchValue] = useState("");
    const searchValueNormalized = useMemo(() => normalize(searchValue), [searchValue]);

    const applySearch = useCallback(
        (data: T[]) =>
            data?.filter((row) => {
                const columnStrings = columns.filter(isDataColumn).map((column) => {
                    const valueString = readCellValue({ column, row });
                    const valueStringNormalized = normalize(valueString);
                    return valueStringNormalized;
                });
                const foundMatchInCell = columnStrings.some((columnString) =>
                    columnString.includes(searchValueNormalized)
                );
                return foundMatchInCell;
            }),
        [searchValueNormalized, columns]
    );

    return { searchValue, setSearchValue, applySearch };
};
