import { ReactNode } from "react";
import styled from "styled-components";

const PageHeaderStyled = styled.div`
    padding: 0 3rem;

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        padding: 0 1rem;
    }
`;

type Props = {
    children: ReactNode;
};

const PageHeader = (props: Props) => {
    return <PageHeaderStyled>{props.children}</PageHeaderStyled>;
};

export { PageHeader };
