import styled from "styled-components";

import { SidebarCollapseButton } from "components/layout/Sidebar/SidebarCollapseButton/SidebarCollapseButton";
import { SidebarControls } from "components/layout/Sidebar/SidebarControls";
import { SidebarEnvIndicator } from "components/layout/Sidebar/SidebarEnvIndicator";
import { SidebarFooter } from "components/layout/Sidebar/SidebarFooter";
import { SidebarLogo } from "components/layout/Sidebar/SidebarLogo";
import { SidebarNavigation } from "components/layout/Sidebar/SidebarNavigation";
import { sidebarCSS } from "components/layout/Sidebar/sidebar.styles";
import { SIZE_SMALL } from "components/layout/layout.consts";

const SidebarStyled = styled.div`
    ${sidebarCSS};
`;

type Props = {
    sidebarCollapsed: boolean;
    closeSidebar: () => void;
    toggleCollapsedSidebar: () => void;
};

const Sidebar = (props: Props) => {
    const { sidebarCollapsed, closeSidebar, toggleCollapsedSidebar } = props;

    const handleItemClick = () => {
        if (window.innerWidth > SIZE_SMALL) return;

        closeSidebar();
    };

    return (
        <SidebarStyled id={"sidebar"}>
            <SidebarLogo />
            <SidebarControls />
            <SidebarNavigation sidebarCollapsed={sidebarCollapsed} onItemClick={handleItemClick} />
            <SidebarFooter />
            <SidebarCollapseButton
                collapsedSidebar={sidebarCollapsed}
                toggleCollapsedSidebar={toggleCollapsedSidebar}
            />
            <SidebarEnvIndicator />
        </SidebarStyled>
    );
};

export { Sidebar };
