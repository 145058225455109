import { useEffect } from "react";

export const useKeyPress = (targetKey: string, callback: () => void) => {
    useEffect(() => {
        function handleKeyDown({ key }: KeyboardEvent) {
            if (key === targetKey) callback();
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [targetKey, callback]);
};
