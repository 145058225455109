import { useContext } from "react";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import { InnerTableLayout as InnerTableLayoutInterface } from "components/components-deprecated/tables/data-source-query.interfaces";
import { getCellContent } from "components/components-deprecated/tables/tables.helper";
import { Flex } from "components/ui-deprecated";
import { DataWrapper, map } from "deprecated/data-wrapper";

interface InnerTableLayoutProps {
    layout: null | InnerTableLayoutInterface;
    data: DataWrapper;
    level: number;
}

const InnerTableLayout = (props: InnerTableLayoutProps) => {
    const { layout, data } = props;

    const innerLayoutKeys = Object.keys(layout);
    const largestInnerArray = Math.max(...innerLayoutKeys.map((m) => layout[m].length));

    const columnWidth = 100 / largestInnerArray;

    const { formatter, fieldsToLinks } = useContext(TableContext);

    if (!innerLayoutKeys.length) {
        return null;
    }

    const innerData = data;

    return (
        <Flex
            flexDirection={"column"}
            style={{
                width: "100%",
                gap: "1rem",
            }}
        >
            {innerLayoutKeys.map((layoutKey) => {
                if (innerData instanceof Error) {
                    return null;
                }

                let shouldBeRendered = false;

                const currentLayout = layout[layoutKey];

                map(innerData, (row) =>
                    currentLayout.map((key) => {
                        if (row[key] !== null) {
                            shouldBeRendered = true;
                        }
                    })
                );

                if (!shouldBeRendered) {
                    return null;
                }

                return (
                    <Flex key={layoutKey} flexDirection={"column"}>
                        <h3
                            style={{
                                fontSize: "20px",
                            }}
                        >
                            {layoutKey}
                        </h3>
                        {layoutKey !== "Descriptions" ? (
                            <table
                                style={{
                                    width: "100%",
                                }}
                            >
                                <thead>
                                    <tr>
                                        {currentLayout.map((column, columnIndex) => {
                                            const width =
                                                columnIndex === 0 && currentLayout.length !== largestInnerArray
                                                    ? (largestInnerArray - currentLayout.length + 1) * columnWidth
                                                    : columnWidth;
                                            return (
                                                <th key={column} style={{ width: `${width}%` }}>
                                                    {column.slice(layoutKey.length - column.length)}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(innerData, (row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {currentLayout.map((column, columnIndex) => {
                                                const width =
                                                    columnIndex === 0 && currentLayout.length !== largestInnerArray
                                                        ? (largestInnerArray - currentLayout.length + 1) * columnWidth
                                                        : columnWidth;

                                                return (
                                                    <td key={column} style={{ width: `${width}%` }}>
                                                        {row[column] === null
                                                            ? ""
                                                            : getCellContent(row, column, formatter, fieldsToLinks)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <Flex flexDirection={"column"} style={{ gap: "1rem" }}>
                                {currentLayout.map((title) => {
                                    const data = innerData.data[title].data;

                                    if (!data || (!data[0] && !data[1])) {
                                        return null;
                                    }

                                    return (
                                        <div key={title}>
                                            <div style={{ fontWeight: "bold" }}>
                                                {title.slice(layoutKey.length - title.length)}
                                            </div>
                                            {data.map((value, valueIndex) => {
                                                return value ? <div key={valueIndex}>{value}</div> : null;
                                            })}
                                        </div>
                                    );
                                })}
                            </Flex>
                        )}
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default InnerTableLayout;
