import BasicTable from "components/components-deprecated/tables/components/BasicTable";
import {
    emptyData,
    emptyDataFromAllDataSources,
    emptyHiddenColumns,
} from "components/components-deprecated/tables/data-constants";
import { HiddenColumns } from "components/components-deprecated/tables/data-source-query.interfaces";
import { ExpandedQueryParameters } from "components/components-deprecated/tables/tables.interfaces";
import { DataWrapper } from "deprecated/data-wrapper";

type Props = {
    data: { data: DataWrapper };
    expandableQueryData?: any;
    dataFromAllDataSources?: any;
    allDataIsError?: boolean;
    allDataHasSpinner?: boolean;
    allDataHasData?: boolean;
    format?: any;
    preprocessData?: any;
    padded?: boolean;
    pageSize: number;
    // Default order of table by one column
    defaultOrderField: null | string;
    // Default direction of order of table by one column
    defaultOrderDirection: "ASC" | "DESC";
    // Hide specified columns
    hiddenColumns: HiddenColumns;
    lastImportDataSource?: any;
    fieldsToLinks?: { [key: string]: string };
    grouping?: boolean;
    expandable?: boolean;
    expandableStaticData?: DataWrapper;
    doubleExpandable?: boolean;
    // Parameter name, data key map
    expandedQueryParameters?: ExpandedQueryParameters;
    search?: boolean;
    // Which rows should display differently: {
    //  "Row Name": "Component Name"
    // }
    filterColumns: Array<string>;
    commaSplitFilterColumns: Array<string>;
    multiSelectFilterColumns: Array<string>;
    contextFilters: { [key: string]: string };
    booleanFilterColumns: { [key: string]: boolean };
    customColumnTypes: {
        [key: string]: string;
    };
    setContextFromColumns: {
        [key: string]: string;
    };
    widerColumns?: Array<string>;
    combineColumns?: { [key: string]: Array<string> };
    strictFilter?: boolean;
    // Inner layout of expansion
    innerTableLayout?: {
        [key: string]: Array<string>;
    };
    csvExportTable: boolean;
};

const TableWidget = (props: Props) => {
    const {
        data,
        dataFromAllDataSources,
        allDataIsError,
        allDataHasSpinner,
        allDataHasData,
        expandedQueryParameters,
        format,
        padded,
        pageSize,
        defaultOrderField,
        defaultOrderDirection,
        hiddenColumns,
        grouping,
        fieldsToLinks,
        expandable,
        expandableStaticData,
        doubleExpandable,
        search,
        customColumnTypes,
        filterColumns,
        commaSplitFilterColumns,
        multiSelectFilterColumns,
        contextFilters,
        setContextFromColumns,
        booleanFilterColumns,
        widerColumns,
        combineColumns,
        strictFilter,
        innerTableLayout,
        csvExportTable,
    } = props;

    return (
        <BasicTable
            data={data !== undefined ? data.data : emptyData}
            dataFromAllDataSources={
                dataFromAllDataSources !== undefined ? dataFromAllDataSources : emptyDataFromAllDataSources
            }
            allDataIsError={allDataIsError}
            allDataHasSpinner={allDataHasSpinner}
            allDataHasData={allDataHasData}
            hiddenColumns={hiddenColumns}
            fieldsToLinks={fieldsToLinks}
            defaultOrderField={defaultOrderField}
            defaultOrderDirection={defaultOrderDirection}
            format={format}
            pageSize={pageSize}
            grouping={grouping}
            expandable={expandable}
            expandableStaticData={expandableStaticData}
            doubleExpandable={doubleExpandable}
            expandedQueryParameters={expandedQueryParameters}
            search={search}
            customColumnTypes={customColumnTypes}
            setContextFromColumns={setContextFromColumns}
            filterColumns={filterColumns}
            commaSplitFilterColumns={commaSplitFilterColumns}
            multiSelectFilterColumns={multiSelectFilterColumns}
            contextFilters={contextFilters}
            booleanFilterColumns={booleanFilterColumns}
            padded={padded}
            widerColumns={widerColumns}
            combineColumns={combineColumns}
            strictFilter={strictFilter}
            innerTableLayout={innerTableLayout}
            csvExportTable={csvExportTable}
        />
    );
};

TableWidget.defaultProps = {
    padded: false,
    pageSize: 10,
    defaultOrderField: null,
    defaultOrderDirection: "ASC",
    hiddenColumns: emptyHiddenColumns,
    showHeader: true,
    fieldsToLinks: {},
    grouping: false,
    expandable: false,
    doubleExpandable: false,
    expandedQueryParameters: {
        level1: null,
        level2: null,
    },
    search: false,
    filterColumns: [],
    commaSplitFilterColumns: [],
    multiSelectFilterColumns: [],
    contextFilters: {},
    customColumnTypes: {},
    setContextFromColumns: {},
    booleanFilterColumns: {},
    widerColumns: [],
    combineColumns: {},
    strictFilter: false,
    innerTableLayout: null,
    csvExportTable: false,
};

export { TableWidget };
