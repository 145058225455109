import { useCallback, useRef, useState } from "react";

import { useOutsideClick } from "hooks/useOutsideClick";

export const usePopover = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleToggleOpen = useCallback(() => {
        setIsOpen((x) => !x);
    }, []);

    useOutsideClick(ref, handleClose);

    return { ref, isOpen, handleToggleOpen, handleOpen, handleClose };
};
