import styled from "styled-components";

import { NavLink } from "components/ui/NavLink";

const PageNavigationSubTabItem = styled(NavLink)`
    padding: 0.75rem 1rem;

    color: ${({ theme }) => theme.color.textGrayDairyDarker};

    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.dairyLight};
        color: unset;
    }

    &.active {
        border-bottom: 2px solid #c30a14;

        color: ${({ theme }) => theme.color.textGray};

        font-weight: 500;
    }

    // TODO Could use some love for smaller screens
    @container page (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 0.75rem 1rem;

        font-size: 0.75rem;
        line-height: 1rem;

        word-break: break-word;
    }

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        padding: 0.5rem 0.5rem;
    }
`;

export { PageNavigationSubTabItem };
