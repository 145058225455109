import { moment } from "deprecated/data-utils";

/**
 * Formats the date to `'YYYY-MM-DD HH:mm'` string
 * if difference is less than 7 days compared to the current date.
 *
 * Otherwise, displays a relative time difference (x days ago).
 *
 * @param date
 */
export function getFormattedDate(date: Date): string {
    const referencedDate = moment(date);
    const today = moment();

    if (referencedDate.diff(today, "days") < -7) {
        return referencedDate.format("YYYY-MM-DD HH:mm");
    } else {
        return referencedDate.fromNow();
    }
}
