import styled from "styled-components";

import { SIDEBAR_NAVIGATION_ITEM_CLASS } from "components/layout/Sidebar/SidebarNavigation/sidebar-navigation-item.consts";
import { sidebarNavigationItemCSS } from "components/layout/Sidebar/SidebarNavigation/sidebar-navigation-item.styles";
import { NavLink } from "components/ui/NavLink";
import { Icon, Popover } from "components/ui-deprecated";

const SidebarNavigationItemStyled = styled(NavLink)`
    ${sidebarNavigationItemCSS};
`;

type Props = {
    title: string;
    icon: string;
    path: string;
    sidebarCollapsed: boolean;
    onClick?: () => void;
};

const SidebarNavigationItem = (props: Props) => {
    const { title } = props;

    let content = (
        <SidebarNavigationItemStyled to={props.path} onClick={props.onClick} className={SIDEBAR_NAVIGATION_ITEM_CLASS}>
            <Icon icon={props.icon} />
            <span className={"title"}>{title}</span>
        </SidebarNavigationItemStyled>
    );

    if (props.sidebarCollapsed) {
        content = (
            <Popover trigger={"hover"} placement={"right"} overlay={title}>
                <div>{content}</div>
            </Popover>
        );
    }

    return content;
};

export { SidebarNavigationItem };
