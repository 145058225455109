import { ClusterFilterContent } from "components/layout/Sidebar/SidebarControls/ClusterFilter/ClusterFilterContent";
import { SidebarControl } from "components/layout/Sidebar/SidebarControls/SidebarControl";
import { useAppSelector } from "store";

const ClusterFilter = () => {
    const canFilterClusters = useAppSelector((state) => state.user.permissions.canFilterClusters);
    const selectedClusters = useAppSelector((state) => state.user.selectedClusters);
    const sortedSelectedClusters = selectedClusters.toSorted((a, b) => a.name.localeCompare(b.name));

    if (!canFilterClusters) return null;

    let subtitle: string;
    if (sortedSelectedClusters.length === 0) {
        subtitle = "All selected";
    } else {
        const clusterNames = sortedSelectedClusters.map((cluster) => cluster.name);
        subtitle = `${clusterNames.join(", ")}`;
    }

    return (
        <SidebarControl title={"Lely Centers"} subtitle={subtitle} icon={"lely-icon-farm"}>
            {({ handleToggleOpen }) => (
                <ClusterFilterContent selectedClusters={sortedSelectedClusters} handleToggleOpen={handleToggleOpen} />
            )}
        </SidebarControl>
    );
};

export { ClusterFilter };
