import { TreeMultiSelectOption } from "components/ui-deprecated/Form/Fields/TreeMultiSelect";
import { Cluster } from "types/cluster.types";

const transformToOptions = (clusters: Cluster[]): TreeMultiSelectOption[] => {
    return clusters
        .map((cluster) => ({
            key: cluster.id,
            value: cluster.id,
            title: cluster.name,
            children: transformToOptions(cluster.clusters),
        }))
        .sort((a, b) => a.title.localeCompare(b.title));
};

export const toSelectOptions = (options: { clusters?: Cluster[] }): TreeMultiSelectOption[] => {
    const clusters = options.clusters ?? [];

    return transformToOptions(clusters);
};
