import styled from "styled-components";

export const SearchResultListItemChip = styled.span`
    padding: 0.125rem 0.5rem;

    display: flex;
    gap: 0.25rem;
    align-items: center;

    background-color: ${({ theme }) => theme.color.dairyLight};
    border-radius: 0.5rem;

    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
`;
