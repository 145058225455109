import styled from "styled-components";

export const SidebarControlsSeparator = styled.div`
    height: 2px;
    margin: 1rem 2rem;

    background-color: ${({ theme }) => theme.color.lelyRedDark};

    opacity: 0.25;

    &:only-child {
        display: none;
    }
`;
