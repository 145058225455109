import styled, { keyframes } from "styled-components";

import { Icon } from "components/ui-deprecated";

const rotateAnimation = keyframes`
    0% { transform: rotate(0deg)}
    100% { transform: rotate(360deg)}
`;

const LoaderIcon = styled(Icon)<{ size: string }>`
    font-size: ${(props) => props.size};

    color: ${({ theme }) => theme.color.gray};
    animation: ${rotateAnimation} 1s linear infinite;
`;

type Props = {
    /**
     * Size in pixels.
     * Determines the `font-size` of the loading icon.
     */
    size?: `${number}px`;
    className?: string;
};

const Loader = (props: Props) => {
    const size = props.size ?? "unset";

    // TODO Use a better looking icon (maybe use primary color)
    return <LoaderIcon icon={"spinner"} size={size} className={props.className} />;
};

export { Loader };
