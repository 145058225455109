import { PresetDate } from "components/ui/DatePicker/Presets";
import {
    startOfMonthDay,
    startOfWeekDay,
    startOfYearDay,
    weekAgo,
    yesterday,
    monthAgo,
    today,
} from "components/ui/DatePicker/date.utils";

export const SELECT_TIMEOUT = 250;

export const presets: PresetDate[] = [
    { title: "Today", value: today },
    { title: "Yesterday", value: yesterday },
    { title: "Start of the Week", value: startOfWeekDay },
    { title: "Week ago", value: weekAgo },
    { title: "Start of the Month", value: startOfMonthDay },
    { title: "Month ago", value: monthAgo },
    { title: "Start of the Year", value: startOfYearDay },
];
