/**
 * This module is used by `parse` module, and is intended to provide a high level interface for type recognition while
 * itself being quite low level and optimized.
 */
import { isValidDate, isInvalidDate } from "deprecated/common";
import { isIsoDateString } from "deprecated/data-wrapper/data.utils";

// This type represents a hint which is a candidate for an actual type in a column. Candidate resolution is a bit higher
// than actual data types (e.g., "string", "iso date string" and "number as string" instead of just "string"). This is
// to give hints about possible data type transformations, used mainly in parsing string formats.
// This data type is represented as numbers in power of two in order to enable efficiency via bitwise operations. The
// candidate is actually a compound of these numbers and the actual number represents a set of potential data types.
// Despite that it is implemented as bitwise operations over numbers, this module provides more high-level API which is
// easy to use and allow us to completely swap the implementation under the hood if needed.
export type Hint = number;
// Meaning: [ mask, fix ], see mergeHints for more details
export type MergeRule = [Hint, Hint];
// Determines whether the map is applied. In "exact", pattern must be exactly matched against the hint. In "subset",
// there is something from the mask present but nothing else. In "dirty", whole mask must match but there are also other
// values.
export type MapRuleType = "exact" | "subset" | "dirty";
type TransformString = (values: ReadonlyArray<unknown>) => Array<string | null>;
type TransformNumber = (values: ReadonlyArray<unknown>) => Array<number | null>;
type TransformBoolean = (values: ReadonlyArray<unknown>) => Array<boolean | null>;
type TransformDate = (values: ReadonlyArray<unknown>) => Array<Date | null>;
export type TypeAndTransform =
    | {
          type: "string";
          transform: TransformString;
      }
    | {
          type: "number";
          transform: TransformNumber;
      }
    | {
          type: "boolean";
          transform: TransformBoolean;
      }
    | {
          type: "date";
          transform: TransformDate;
      };
export type TypeAndTransformInit<T> = TypeAndTransform | ((arg: T) => TypeAndTransform);
export type MapRule<T> = [Hint, MapRuleType, TypeAndTransformInit<T>];
type MapRuleFlat = [Hint, MapRuleType, TypeAndTransform];

function createHintConstant(hint: Hint): () => Hint {
    return () => hint;
}

// Factory for building primitive hints (those who contain just one type).
export const h = {
    // Null literal
    unknown: createHintConstant(0),
    // General string
    string: createHintConstant(1),
    // Number which doesn't hold any other conditions
    number: createHintConstant(2),
    // Boolean type
    boolean: createHintConstant(4),
    // Date instance
    date: createHintConstant(8),
    // String matching ISO DATE format
    stringIsoDate: createHintConstant(16),
    // When `String(parseInt(str, 10)) == str`
    stringInteger: createHintConstant(32),
    // When `String(parseFloat(str)) == str`
    stringNumber: createHintConstant(64),
    // Literals "true" and "false"
    stringBoolean: createHintConstant(128),
    // Literals "1" and "0"
    stringIntegerBoolean: createHintConstant(256),
    // When `n === floor(n)`
    numberInteger: createHintConstant(512),
    // Literals 1 and 0
    numberBoolean: createHintConstant(1024),
    // Values undefined, NaN, Invalid Date and empty string
    missing: createHintConstant(2048),
};
// Checks if union contains hint.
export function containsHint(union: Hint, hint: Hint): boolean {
    return (union & hint) > 0 || (union === 0 && hint === 0);
}
// Removes hint from union.
export function removeHint(union: Hint, hint: Hint): Hint {
    return union & ~hint;
}
// Checks type and actual value of the argument and returns appropriate data type hint. If the type/value is not
// supported, null is returned otherwise.
export function getHint(value: unknown, strictTypes: boolean): Hint | null {
    if (value === null) {
        return h.unknown();
    } else if (typeof value === "string") {
        if (strictTypes) {
            if (isIsoDateString(value)) {
                return h.stringIsoDate();
            } else {
                return h.string();
            }
        } else {
            if (isIsoDateString(value)) {
                return h.stringIsoDate();
            } else if (value === "1" || value === "0") {
                return h.stringIntegerBoolean();
            } else if (value === "true" || value === "false") {
                return h.stringBoolean();
            } else if (String(parseInt(value)) === value) {
                return h.stringInteger();
            } else if (String(parseFloat(value)) === value) {
                return h.stringNumber();
            } else if (value === "") {
                return h.missing();
            } else {
                // General string only if it is not specialized
                return h.string();
            }
        }
    } else if (typeof value === "number" && !Number.isNaN(value)) {
        if (!strictTypes && (value === 1 || value === 0)) {
            return h.numberBoolean();
        } else if (Math.floor(value) === value) {
            return h.numberInteger();
        } else {
            // General number only if it is not specialized
            return h.number();
        }
    } else if (typeof value === "boolean") {
        return h.boolean();
    } else if (isValidDate(value)) {
        return h.date();
    } else if (typeof value === "undefined" || Number.isNaN(value) || isInvalidDate(value)) {
        return h.missing();
    } else {
        return null;
    }
}
// If a hint exactly matches the pattern (union of hints), then it is replaced by the replacement (again, union). This
// function only creates rule for use in mergeHints.
export function createMergeRule(pattern: ReadonlyArray<Hint>, replacement: Hint | ReadonlyArray<Hint>): MergeRule {
    const mask = pattern.reduce((mask, hint) => mask | hint);
    const fix = typeof replacement === "number" ? replacement : replacement.reduce((fix, hint) => fix | hint);
    return [mask, fix];
}
// Merges two hints together and applies given rules in order to fix forbidden combinations if any occurred.
export function mergeHints(first: Hint, second: Hint, rules: ReadonlyArray<MergeRule>): Hint {
    return rules.reduce((merged, [mask, fix]) => {
        // Check forbidden combinations
        if ((merged & mask) === mask) {
            // Remove the combination and replace it with the fix
            return (merged & ~mask) | fix;
        } else {
            return merged;
        }
    }, first | second);
}
// Tries to match the pattern to a hint (which will come into the rule) in a way specified by argument `type`. For more
// info about `type`, check comment of MapRuleType type alias. Map rules are for determining which array transformation
// should be applied based on resulting hint.
export function createMapRule<T>(
    pattern: ReadonlyArray<Hint>,
    type: MapRuleType,
    tt: TypeAndTransformInit<T>
): MapRule<T> {
    const mask = pattern.reduce((mask, hint) => mask | hint);
    return [mask, type, tt];
}
export function createStringTransform(transform: TransformString): TypeAndTransform {
    return {
        type: "string",
        transform,
    };
}
export function createNumberTransform(transform: TransformNumber): TypeAndTransform {
    return {
        type: "number",
        transform,
    };
}
export function createBooleanTransform(transform: TransformBoolean): TypeAndTransform {
    return {
        type: "boolean",
        transform,
    };
}
export function createDateTransform(transform: TransformDate): TypeAndTransform {
    return {
        type: "date",
        transform,
    };
}
// Gets appropriate type name & transform for resulting hint. If no rule can be applied, defaultValue is used. Argument
// `arg` is passed into rules' initialization (if used by the rule).
export function getTypeAndTransform<T>(
    hint: Hint,
    rules: ReadonlyArray<MapRule<T>>,
    defaultValue: TypeAndTransformInit<T>,
    arg: T | null = null
): TypeAndTransform {
    const rulesFlat: ReadonlyArray<MapRuleFlat> = rules.map((rule) => {
        const tt = rule[2];

        if (typeof tt === "function") {
            if (arg === null) {
                throw new Error("Must pass a map rule initialization argument.");
            } else {
                return [rule[0], rule[1], tt(arg)];
            }
        } else {
            return [rule[0], rule[1], tt];
        }
    });

    for (const [mask, type, transform] of rulesFlat) {
        switch (type) {
            case "exact":
                if (hint === mask) {
                    return transform;
                }

                break;

            case "subset":
                if (hint & mask && !(hint & ~mask)) {
                    return transform;
                }

                break;

            case "dirty":
                if ((hint & mask) === mask && hint & ~mask) {
                    return transform;
                }

                break;
        }
    }

    if (typeof defaultValue === "function") {
        if (arg === null) {
            throw new Error("Must pass a map rule initialization argument.");
        } else {
            return defaultValue(arg);
        }
    } else {
        return defaultValue;
    }
}
