import styled from "styled-components";

import { WidgetFrame } from "components/layout/WidgetFrame";
import { TableHeadExpandable } from "components/ui/table-final-saviour/Table/TableHead";

const WidgetFrameTableStyled = styled(WidgetFrame.Table)`
    border-bottom: 1px solid ${(props) => props.theme.color.grayDairyLight};
`;

const ExpandedContentSectionTable: typeof WidgetFrame.Table = (props) => {
    return (
        <WidgetFrameTableStyled
            {...props}
            components={{
                ...props.components,
                TableHead: TableHeadExpandable,
            }}
        />
    );
};

export { ExpandedContentSectionTable };
