import { DialogContent as _DialogContent } from "components/ui/Dialog/DialogContent/DialogContent";
import { DialogContentActions } from "components/ui/Dialog/DialogContent/DialogContentActions";
import { DialogContentBody } from "components/ui/Dialog/DialogContent/DialogContentBody";
import { DialogContentTitle } from "components/ui/Dialog/DialogContent/DialogContentTitle";

const DialogContent = _DialogContent as typeof _DialogContent & {
    Actions: typeof DialogContentActions;
    Body: typeof DialogContentBody;
    Title: typeof DialogContentTitle;
};

DialogContent.Actions = DialogContentActions;
DialogContent.Body = DialogContentBody;
DialogContent.Title = DialogContentTitle;

export { DialogContent };
