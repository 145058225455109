import { useField } from "formik";
import styled from "styled-components";

import ErrorField from "components/ui-deprecated/Form/Fields/ErrorField";
import SmartSelect from "components/ui-deprecated/SmartSelect/SmartSelect";
import type { Row } from "deprecated/data-utils";

import "components/ui-deprecated/Form/Fields/SelectField.less";

const Label = styled.label`
    font-weight: 500;
`;

const Error = styled(ErrorField)`
    margin-top: 0.5rem;
    margin-bottom: 0;
`;

export type SelectFieldProps = {
    label: string;
    options: ReadonlyArray<{
        value: string;
        text: string;
    }>;
    name: string;
    placeholder: string;
    customOnChange?: (row: Row) => void;
};

export default function SelectField({ label, options, customOnChange, ...props }: SelectFieldProps) {
    const [field, meta, helpers] = useField(props);

    return (
        <div className={"input-field"} onBlur={() => helpers.setTouched(true)}>
            <Label htmlFor={`${field.name}-${label}`}>{label}</Label>
            <SmartSelect
                id={`${field.name}-${label}`}
                valueField={"value"}
                selectFields={["text"]}
                data={options}
                value={meta.value}
                onChange={(option) => {
                    if (typeof customOnChange === "function") {
                        customOnChange(option);
                    }

                    helpers.setValue(option.value);
                }}
                // FIXME
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                name={props.name}
                placeholder={props.placeholder}
            />
            {meta.touched && meta.error ? <Error message={meta.error} /> : null}
        </div>
    );
}
