import styled from "styled-components";

import { Button } from "components/ui/Button";

export const NotificationListHeaderButton = styled(Button)`
    margin-left: 16px;

    font-size: 13px;
    line-height: 16px;

    align-self: center;
`;
