import * as DialogPrimitive from "@radix-ui/react-dialog";

import { DialogContent } from "components/ui/Dialog/DialogContent";

const Dialog = DialogPrimitive.Root as typeof DialogPrimitive.Root & {
    Content: typeof DialogContent;
    Trigger: typeof DialogPrimitive.Trigger;
};

Dialog.Content = DialogContent;
Dialog.Trigger = DialogPrimitive.Trigger;

export { Dialog };
export type { DialogWidth } from "components/ui/Dialog/DialogContent/DialogContent";
