import styled, { css } from "styled-components";

import { Panel } from "components/ui/Panel";

const warningCSS = css`
    border: 1px solid ${({ theme }) => theme.color.red};

    & .select-input-wrapper > input {
        // TODO Implement less hacky way once all old table components are removed
        // Needed because of the combination of fixed height and border, which is added here
        padding-top: 7px;
        padding-bottom: 7px;
    }
`;

export const selectPanelCSS = css`
    height: 40px;
    width: 100%;

    border: 0;

    & .select-input-wrapper {
        box-shadow: none;
    }
`;

export const SelectPanel = styled(Panel)<{ $showWaring?: boolean }>`
    ${selectPanelCSS};

    ${({ $showWaring }) => $showWaring && warningCSS};
`;
