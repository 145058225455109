import styled from "styled-components";

const KPIStyled = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: max-content 1fr;
    grid-column-gap: 0.5rem;
    height: 100%;
`;

export type Props = {
    children: React.ReactNode;
    className?: string;
};

export const KPI = ({ children, className }: Props) => {
    return <KPIStyled className={className}>{children}</KPIStyled>;
};
