import styled from "styled-components";

import { config } from "config";

const PageHeaderTitleText = styled.h1`
    margin-bottom: 0;

    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: 0.25rem;

    @container page (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1.75rem;
        line-height: 2rem;
    }
`;

const PageHeaderTitleCategory = styled.div`
    margin-bottom: 0;

    color: ${({ theme }) => theme.color.grayDairyDarker};

    font-size: 1rem;
    letter-spacing: 0.125rem;
    line-height: 1.5rem;

    @container page (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

type Props = {
    value: string;
    /**
     * Can be a name of the parent page.
     */
    category?: string;
};

const PageHeaderTitle = (props: Props) => {
    const { value, category } = props;

    document.title = `${value} | ${config.APP_TITLE}`;

    return (
        <div>
            {category && <PageHeaderTitleCategory>{category}</PageHeaderTitleCategory>}
            <PageHeaderTitleText>{value}</PageHeaderTitleText>
        </div>
    );
};

export { PageHeaderTitle };
