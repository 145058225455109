import { ChartData } from "components/ui/Chart/chart.types";
import { theme } from "styles/theme";

export const createThresholdLineDataset = <T = unknown>(options: {
    id?: string;
    label?: string;
    data: T;
    color: string;
    hidden?: boolean;
}): ChartData<any, T>["datasets"][number] => ({
    id: options.id,
    label: options.label,
    data: options.data,
    borderColor: options.color,
    backgroundColor: theme.color.white,
    pointRadius: 0,
    pointHoverRadius: 0,
    borderWidth: 1,
    borderDash: [6, 6],
    hidden: options.hidden ?? false,
});
