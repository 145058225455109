import styled from "styled-components";

const ExpandedContentSectionContentTitle = styled.div`
    &:not(:last-child) {
        padding-bottom: 0.5rem;
    }

    color: ${(props) => props.theme.color.textGrayDairyDarker};
    font-size: 1rem;
    font-weight: 500;
`;

export { ExpandedContentSectionContentTitle };
