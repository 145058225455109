import { Alert } from "@datamole/wds-component-alert";

export type ErrorFieldProps = {
    message: string;
    className?: string;
};

export default function ErrorField({ message, className }: ErrorFieldProps) {
    return (
        <Alert severity={"warning"} className={className}>
            <Alert.Icon />
            <Alert.Content>{message}</Alert.Content>
        </Alert>
    );
}
