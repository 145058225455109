const IMPERSONATION_STORAGE_KEY = "LSSA::impersonationToken";

export const getImpersonationToken = (): string | null => {
    const token = sessionStorage.getItem(IMPERSONATION_STORAGE_KEY);
    if (!token) return null;

    return token;
};

export const setImpersonationToken = (token: string) => {
    sessionStorage.setItem(IMPERSONATION_STORAGE_KEY, token);
};

export const destroyImpersonationToken = () => {
    sessionStorage.removeItem(IMPERSONATION_STORAGE_KEY);
};

export const isImpersonated = () => {
    return !!getImpersonationToken();
};
