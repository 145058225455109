import styled from "styled-components";

export const StickyHeaderFixedContainer = styled.div<{ $show: boolean }>`
    display: ${({ $show }) => ($show ? "block" : "none")};

    width: 100%;

    background-color: ${({ theme }) => theme.color.white}cc;
    backdrop-filter: blur(4px);
    border-bottom: 1px solid ${({ theme }) => theme.color.dairyLight};

    animation: fadeIn 0.25s ease-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;
