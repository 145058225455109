import styled from "styled-components";

export const NotificationListHeader = styled.div`
    padding-bottom: 0.5rem;

    border-bottom: 2px solid ${({ theme }) => theme.color.lelyRed};

    font-weight: 500;
    font-size: 16px;

    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }
`;
