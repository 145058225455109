import { ComponentType } from "react";

import { TableCell as TableCellDefault, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import {
    TableHeaderCellSortable as TableHeaderCellDefault,
    TableHeaderCellSortableProps,
} from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { SortConfig, SortOrder } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable";
import { DataColumnDataType } from "components/ui/table-final-saviour/Table/columns/table-columns.types";
import { useSort } from "components/ui/table-final-saviour/Table/hooks/useSort";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";
import { readCellValue } from "components/ui/table-final-saviour/Table/table.utils";
import { splitAndCapitalize } from "utils/string.utils";

type GetCellValueOptions<T extends Row> = {
    column: DataSortableColumn<T>;
    row: T;
    value: T[keyof T];
};

type UseSortControls<T extends Row> = ReturnType<typeof useSort<T>>["sortControls"];
export interface DataSortableColumn<T extends Row>
    extends Column<T>,
        Pick<TableCellProps<T>, "align" | "fontStyle" | "width">,
        UseSortControls<T> {
    _type: "data_sortable";
    dataKey: keyof T;
    dataType: DataColumnDataType;
    title?: string;
    getCellValue?: (options: GetCellValueOptions<T>) => React.ReactNode;
    components?: {
        TableCell?: ComponentType<TableCellProps<T>>;
        TableHeaderCell?: ComponentType<TableHeaderCellSortableProps<T>>;
    };
}

const getSortOrder = (key: keyof Row, options: SortConfig<any>): SortOrder => {
    if (options.key !== key) return "none";
    return options.order;
};

export const createDataSortableColumn = <T extends Row>(
    options: Omit<DataSortableColumn<T>, "_type">
): DataSortableColumn<T> => {
    const { dataKey, dataType, components } = options;

    const TableCell: ComponentType<TableCellProps<T>> = components?.TableCell ?? TableCellDefault;
    const TableHeaderCell: ComponentType<TableHeaderCellSortableProps<T>> =
        components?.TableHeaderCell ?? TableHeaderCellDefault;

    const getCellValue = options?.getCellValue ?? readCellValue;

    const sortOrder = getSortOrder(dataKey, options.sortConfig);
    const handleSetSortByKey = () => options.setSortByKey({ dataKey, dataType });

    return {
        _type: "data_sortable",
        ...options,
        components: {
            TableCell: (props) => (
                <TableCell align={options?.align} width={options?.width} {...props}>
                    {getCellValue({
                        column: props.column as DataSortableColumn<T>,
                        row: props.row,
                        value: props.row[dataKey],
                    })}
                </TableCell>
            ),
            TableHeaderCell: (props) => (
                <TableHeaderCell
                    align={options?.align}
                    width={options?.width}
                    sortOrder={sortOrder}
                    onClick={handleSetSortByKey}
                    {...props}
                >
                    {options?.title ?? splitAndCapitalize(String(dataKey))}
                </TableHeaderCell>
            ),
        },
    };
};
