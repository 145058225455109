import styled, { css } from "styled-components";

import logoImage from "components/layout/Sidebar/assets/logo.png";
import { config } from "config";

const collapsedCSS = css`
    margin: 1.5rem;

    & > .title {
        display: none;
    }
`;

const SidebarLogoStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 2rem;

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && collapsedCSS};

    & > img {
        width: 5rem;
        margin-right: 1rem;
    }

    & > .title {
        color: ${({ theme }) => theme.color.white};

        font-size: 1.25rem;
        font-weight: 400;
    }
`;

const SidebarLogo = () => {
    return (
        <SidebarLogoStyled>
            <img src={logoImage} alt={"logo"} />
            <div className={"title"}>{config.APP_NAME}</div>
        </SidebarLogoStyled>
    );
};

export { SidebarLogo };
