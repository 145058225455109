import { useEffect, useRef, useState } from "react";

import {
    removeFmsReportToken,
    setFmsReportToken,
    validateFmsReportToken,
} from "services/fms-report/fms-report.service";

export const useFmsReportTokenValidity = (token: string): boolean | null => {
    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

    const tokenRef = useRef(token);

    // Validate token on mount and when token changes
    useEffect(() => {
        // reset if token has changed
        if (tokenRef.current !== token) {
            setIsTokenValid(null);
            removeFmsReportToken();
        }

        tokenRef.current = token;

        validateFmsReportToken(token)
            .then((isValid) => {
                // do not set if token changed in the meantime
                if (tokenRef.current !== token) {
                    return;
                }

                setIsTokenValid(isValid);
                setFmsReportToken(token);
                return;
            })
            .catch(() => {
                // do not set if token changed in the meantime
                if (tokenRef.current !== token) {
                    return;
                }

                setIsTokenValid(false);
            });
    }, [token]);

    // Remove token on unmount
    useEffect(() => {
        return () => {
            removeFmsReportToken();
        };
    }, []);

    return isTokenValid;
};
