import styled from "styled-components";

import { SearchClearButton } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchInput/SearchClearButton";
import { SearchIcon } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchInput/SearchIcon";

const SearchContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
`;

const SeachInputStyled = styled.input`
    width: 100%;
    padding: 0.5rem 1rem;

    background: none;
    border: none;
`;

type Props = {
    value: string;
    setValue: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
};

const SearchInput = (props: Props) => {
    const { value, setValue } = props;

    return (
        <SearchContainer>
            <SeachInputStyled
                type={"text"}
                value={value}
                placeholder={"Search..."}
                onChange={(e) => setValue(e.target.value)}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
            {value.length > 0 && <SearchClearButton onClick={() => setValue("")} />}
            <SearchIcon />
        </SearchContainer>
    );
};

export { SearchInput };
