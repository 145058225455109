import styled, { css } from "styled-components";

export const inputTextCSS = css`
    width: 100%;
    padding: 0.5rem 1rem;

    border: 1px solid ${({ theme }) => theme.color.grayDairy};
    border-radius: 0.25rem;
`;

export const InputText = styled.input`
    ${inputTextCSS};
`;
