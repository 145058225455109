import styled from "styled-components";

export const CategorySelectMenuItem = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    padding: 0.25rem 1rem;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.dairyLight};
    }

    & > span {
        color: ${({ theme }) => theme.color.grayDairyDarker};
    }
`;
