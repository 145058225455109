import styled from "styled-components";

export const FmsReportNavbar = styled.nav`
    grid-area: navbar;

    height: 3.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.color.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.grayLight};
    box-shadow: 0 2px 5px #dedbd666;

    color: ${({ theme }) => theme.color.lelyRed};

    font-size: 1rem;
    letter-spacing: 0.125rem;
`;
