import styled from "styled-components";

import { Icon } from "components/ui-deprecated";

const NoDataWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    grid-column: span 2;
    color: ${({ theme }) => theme.color.textGrayDairyDarker};
    height: fit-content;
`;

type Props = {
    fallbackText?: string;
};

const KPINoData = (props: Props) => {
    const { fallbackText = "No data" } = props;

    return (
        <NoDataWrapper>
            <Icon icon="exclamation-circle" />
            {fallbackText}
        </NoDataWrapper>
    );
};

export { KPINoData };
