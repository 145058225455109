import styled from "styled-components";

export const NotificationMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    max-height: calc(100vh - 6.5rem);
    width: 600px;
    padding: 24px 32px;
    overflow: auto;

    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 5px #dedbd666;
`;
