import { forwardRef, memo } from "react";
import styled from "styled-components";

import { TableProps } from "components/ui/table-final-saviour/Table/TableBase/table.types";

const TableBaseStyled = styled.table`
    width: 100%;
    table-layout: fixed;

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.textGray};
`;

type Props = TableProps;

const TableBase = forwardRef<HTMLTableElement, Props>((props, ref) => {
    return (
        <TableBaseStyled ref={ref} className={props.className} style={props.style}>
            {props.children}
        </TableBaseStyled>
    );
});

TableBase.displayName = "TableBase";

const TableBaseMemo = memo(TableBase);
export { TableBaseMemo as TableBase };
