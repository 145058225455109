/**
 * This module implements parsers and checkers for input data. It tries to be as robust as possible.
 *
 * Module `./hints.js` is the fundamental basic block for parse module and is used heavily here. Thanks to it,
 * specifying various type extraction rules is very declarative, although it may seems overwhelming for the first sight.
 *
 * The basic idea of general algorithm is as follows:
 *   - go through the data and for each column get all possible type candidates (hints) based on actual values
 *   - while doing that, check if all items are valid/supported
 *   - after first pass from where a resulting type hint comes out, get appropriate transformation of array
 *   - in the second pass, transform the data using the transformations
 */
import { range, zipWith } from "lodash";
import Papa from "papaparse";

import { isPlainObject, isInvalidDate, stringify, assert } from "deprecated/common";
import { parseIsoDateString } from "deprecated/data-wrapper/data.utils";
import type { Hint, TypeAndTransform } from "deprecated/data-wrapper/hints";
import {
    h,
    createMergeRule,
    createMapRule,
    mergeHints,
    getHint,
    containsHint,
    removeHint,
    getTypeAndTransform,
    createStringTransform,
    createNumberTransform,
    createBooleanTransform,
    createDateTransform,
} from "deprecated/data-wrapper/hints";
import { hasEqualShape } from "deprecated/data-wrapper/operations";
import type { DataWrapper, Field } from "deprecated/data-wrapper/types";

// Merge rules are for fixing invalid combinations that turned up during hints merging.
const mergeRules = [
    // If there is a string which is not ISO, we cannot parse all strings into Dates
    [[h.string(), h.stringIsoDate()], h.string()], // There occured a float so it cannot be of type integer
    [[h.stringInteger(), h.stringNumber()], h.stringNumber()], // General string occured, cancel any other assumptions
    [[h.string(), h.stringBoolean()], h.string()], // There occured a float so it cannot be of type integer
    [[h.number(), h.numberInteger()], h.number()], // General number occured, cancel any other assumptions
    [[h.number(), h.numberBoolean()], h.number()], // General integer occured, cancel boolean assumption
    [[h.numberInteger(), h.numberBoolean()], h.numberInteger()],
    // FIXME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
].map(([pattern, replacement]) => createMergeRule(pattern, replacement));
// Map rules are for getting appropriate transformation of data array based on resulting hint.
const mapRules = [
    [[h.string()], "exact", createStringTransform(castOnly())], // // Integer type is kept as number, but this can change in the future.
    [[h.number(), h.numberInteger()], "subset", createNumberTransform(castOnly())],
    [[h.boolean()], "exact", createBooleanTransform(castOnly())],
    [[h.date()], "exact", createDateTransform(castOnly())],
    [
        [h.stringIsoDate(), h.date()],
        "subset",
        createDateTransform(
            transformWith((value) => {
                if (typeof value === "string") {
                    return parseIsoDateString(value);
                } else if (value instanceof Date) {
                    return value;
                } else {
                    // Should not happen
                    return new Date(String(value));
                }
            })
        ),
    ],
    [[h.stringIntegerBoolean()], "exact", createBooleanTransform(transformWith((value) => parseInt(value, 10) === 1))],
    [[h.stringBoolean()], "exact", createBooleanTransform(transformWith((value) => value === "true"))],
    [[h.numberBoolean()], "exact", createBooleanTransform(transformWith((value) => value === 1))],
    [
        [h.number(), h.numberInteger(), h.stringInteger(), h.stringNumber()],
        "subset",
        createNumberTransform(transformWith((value) => parseFloat(value))),
    ],
    [
        [h.boolean(), h.stringBoolean(), h.numberBoolean(), h.stringIntegerBoolean()],
        "subset",
        createBooleanTransform(
            transformWith((value) => {
                if (typeof value === "boolean") {
                    return value;
                } else if (typeof value === "string") {
                    return value === "true" || value === "1";
                } else {
                    return parseInt(value, 10) === 1;
                }
            })
        ),
    ],
    [
        [
            h.number(),
            h.numberBoolean(),
            h.numberInteger(),
            h.stringNumber(),
            h.stringInteger(),
            h.stringIntegerBoolean(),
        ],
        "subset",
        createNumberTransform(transformWith(parseFloat)),
    ],
    [
        [
            h.string(),
            h.stringNumber(),
            h.stringBoolean(),
            h.stringInteger(),
            h.stringIsoDate(),
            h.stringIntegerBoolean(),
        ],
        "subset",
        createStringTransform(castOnly()),
    ],
    // FIXME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
].map(([pattern, type, tt]) => createMapRule(pattern, type, tt));
const defaultMapRule = createStringTransform(transformWith(String));

// Returns a function which accepts array of values and returns the array with element of correct type. Null values are
// handled by this function so they can be ignored in passed transformation function.
function transformWith<T>(transform: (value: any) => T): (values: ReadonlyArray<any>) => Array<T | null> {
    return (values) => {
        const output = [];

        for (let i = 0; i < values.length; i++) {
            if (values[i] !== null) {
                output[i] = transform(values[i]);
            } else {
                output[i] = null;
            }
        }

        return output;
    };
}

// Just to trick Flow to think that the array is of correct type. The type of array elements *must* be ensured in a
// previous pass.
function castOnly<T>(): (values: ReadonlyArray<unknown>) => Array<T | null> {
    return (values) => {
        return values as any as Array<T | null>;
    };
}

export function createDataWrapper(
    data: DataWrapper["data"],
    nested: DataWrapper["nested"],
    length: number
): DataWrapper {
    return {
        data,
        nested,
        length,
        _cache: {},
    };
}

function getHintOrThrow(value: unknown, strictTypes: boolean): Hint {
    const hint = getHint(value, strictTypes);

    if (hint === null) {
        throw new Error(`Unsupported type of value: ${stringify(value)}`);
    } else {
        return hint;
    }
}

function addChecked(value: unknown, hint: Hint): [unknown, Hint] {
    if (containsHint(hint, h.missing())) {
        const isMissing = typeof value === "undefined" || Number.isNaN(value) || isInvalidDate(value) || value === "";
        assert(isMissing, "Invalid handling of missing values.");
        return [null, removeHint(hint, h.missing())];
    } else {
        return [value, hint];
    }
}

function checkUnknownFields(knownFields: ReadonlyArray<string>, row: Readonly<Record<string, unknown>>) {
    const set = {};
    Object.keys(row).forEach((field) => (set[field] = true));
    knownFields.forEach((field) => {
        if (!set[field]) {
            throw new Error(`New field encountered during processing: ${field}`);
        }
    });
}

function getTypedField(tt: TypeAndTransform, column: ReadonlyArray<unknown>): Field {
    if (tt.type === "string") {
        return {
            type: tt.type,
            data: tt.transform(column),
        } as {
            type: "string";
            data: ReadonlyArray<string | null>;
        };
    } else if (tt.type === "number") {
        return {
            type: tt.type,
            data: tt.transform(column),
        } as {
            type: "number";
            data: ReadonlyArray<number | null>;
        };
    }

    if (tt.type === "boolean") {
        return {
            type: tt.type,
            data: tt.transform(column),
        } as {
            type: "boolean";
            data: ReadonlyArray<boolean | null>;
        };
    }

    if (tt.type === "date") {
        return {
            type: tt.type,
            data: tt.transform(column),
        } as {
            type: "date";
            data: ReadonlyArray<Date | null>;
        };
    } else {
        throw new Error("Unreachable");
    }
}

function parseTraditional(data: ReadonlyArray<any>, strictTypes: boolean): DataWrapper {
    const row = data[0];

    if (!isPlainObject(row)) {
        throw new Error("Invalid format");
    }

    const fields = Object.keys(row);
    const columns = fields.map(() => []);
    let hints = fields.map((field) => getHintOrThrow(row[field], strictTypes));

    for (let i = 0; i < data.length; i++) {
        const row = data[i];

        if (!isPlainObject(row)) {
            throw new Error("Data items must be objects");
        }

        checkUnknownFields(fields, row);
        const rowHints = fields.map((field) => getHintOrThrow(row[field], strictTypes));
        hints = zipWith(hints, rowHints, (first, second) => mergeHints(first, second, mergeRules));
        fields.forEach((field, index) => {
            const added = addChecked(row[field], hints[index]);
            columns[index].push(added[0]);
            hints[index] = added[1];
        });
    }

    const namedColumns = makeNamedColumns(fields, columns, hints);
    return createDataWrapper(namedColumns, null, data.length);
}

export function makeNamedColumns(
    fields: ReadonlyArray<string>,
    columns: ReadonlyArray<ReadonlyArray<unknown>>,
    hints: ReadonlyArray<Hint>
): DataWrapper["data"] {
    return columns.reduce((namedColumns, column, index) => {
        // Filter out columns which contain only null values
        // => no longer requested behaviour
        // if (!containsHint(hints[index], h.unknown())) {
        //     const tt = getTypeAndTransform(hints[index], mapRules, defaultMapRule, column.length);
        //     namedColumns[fields[index]] = getTypedField(tt, column);
        // }
        const tt = getTypeAndTransform(hints[index], mapRules, defaultMapRule, column.length);
        namedColumns[fields[index]] = getTypedField(tt, column);
        return namedColumns;
    }, {});
}
function parseSeries(data: ReadonlyArray<any>, strictTypes: boolean): DataWrapper {
    const row = data[0];

    if (!isPlainObject(row)) {
        throw new Error("Invalid format");
    }

    const fields = Object.keys(row).filter((field) => field !== "data");
    const allFields = fields.concat(["data"]);
    const columns = fields.map(() => []);
    const nested = [];
    let hints = fields.map((field) => getHintOrThrow(row[field], strictTypes));

    for (let i = 0; i < data.length; i++) {
        const row = data[i];

        if (!isPlainObject(row)) {
            throw new Error("Data items must be objects");
        }

        checkUnknownFields(allFields, row);
        const rowHints = fields.map((field) => getHintOrThrow(row[field], strictTypes));
        hints = zipWith(hints, rowHints, (first, second) => mergeHints(first, second, mergeRules));
        fields.forEach((field, index) => {
            const added = addChecked(row[field], hints[index]);
            columns[index].push(added[0]);
            hints[index] = added[1];
        });

        if (Array.isArray(row.data)) {
            const wrapper = fromJS(row.data);

            if (wrapper instanceof Error) {
                throw wrapper;
            } else {
                nested.push(wrapper);
            }
        } else {
            throw new Error("Invalid nested data type");
        }
    }

    // Check if nested data wrappers has equal shape
    for (let i = 0; i < data.length - 1; i++) {
        if (!hasEqualShape(nested[i], nested[i + 1])) {
            throw new Error("Incompatible types of nested data");
        }
    }

    const namedColumns = makeNamedColumns(fields, columns, hints);
    return createDataWrapper(namedColumns, nested, data.length);
}

function parseHeatmap(data: ReadonlyArray<unknown>): DataWrapper {
    // NOTE: Heatmap can be also implemented as array data wrapper with nested array data wrappers
    if (!Array.isArray(data[0])) {
        throw new Error("Invalid format");
    }

    const fields = range(data[0].length);
    const columns: Array<Array<number>> = fields.map(() => []);

    for (let i = 0; i < data.length; i++) {
        const row = data[i];

        if (!Array.isArray(row)) {
            throw new Error("Invalid format");
        }

        assert(row.length === fields.length, "Invalid heatmap shape");

        for (let j = 0; j < row.length; j++) {
            if (typeof row[j] !== "number") {
                throw new Error("Invalid heatmap data");
            }

            columns[j].push(row[j]);
        }
    }

    const namedColumns = columns.reduce((namedColumns, column, index) => {
        namedColumns[String(index)] = {
            type: "number",
            data: column,
        };
        return namedColumns;
    }, {});
    return createDataWrapper(namedColumns, null, data.length);
}

function parseArray(data: ReadonlyArray<unknown>): DataWrapper {
    let hint = h.unknown();
    const column = [];

    for (let i = 0; i < data.length; i++) {
        hint = mergeHints(hint, getHintOrThrow(data[i], true), mergeRules);
        const added = addChecked(data[i], hint);
        column.push(added[0]);
        hint = added[1];
    }

    if (containsHint(hint, h.unknown())) {
        return createDataWrapper({}, null, 0);
    } else {
        const tt = getTypeAndTransform(hint, mapRules, defaultMapRule, data.length);
        return createDataWrapper(
            {
                __array__: getTypedField(tt, column),
            },
            null,
            data.length
        );
    }
}

function parsePandas(data: any): DataWrapper {
    if (!isPlainObject(data)) {
        throw new Error("Invalid format");
    }

    const typedData: Readonly<Record<string, unknown>> = data;
    const fields = Object.keys(data);
    const hints = fields.map(() => h.unknown());
    const columns = fields.map((field, columnIndex) => {
        const fieldData = typedData[field];

        if (!isPlainObject(fieldData)) {
            throw new Error("Invalid pandas format");
        }

        // Sort to be sure
        const sortedIndex = Object.keys(fieldData).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
        const column = sortedIndex.map((index) => {
            const hint = mergeHints(hints[columnIndex], getHintOrThrow(fieldData[index], true), mergeRules);
            const added = addChecked(fieldData[index], hint);
            hints[columnIndex] = added[1];
            return added[0];
        });
        return column;
    });
    const namedColumns = makeNamedColumns(fields, columns, hints);
    // Get field names again, some fields could disappear because they are all null
    const names = Object.keys(namedColumns);

    if (names.length > 0) {
        return createDataWrapper(namedColumns, null, namedColumns[names[0]].data.length);
    } else {
        return createDataWrapper({}, null, 0);
    }
}

export function fromJS(raw: unknown, strictTypes = true): DataWrapper | Error {
    try {
        if (Array.isArray(raw)) {
            if (raw.length > 0) {
                if (isPlainObject(raw[0])) {
                    if (Array.isArray(raw[0].data)) {
                        return parseSeries(raw, strictTypes);
                    } else {
                        return parseTraditional(raw, strictTypes);
                    }
                } else if (Array.isArray(raw[0])) {
                    return parseHeatmap(raw);
                } else {
                    return parseArray(raw);
                }
            } else {
                return createDataWrapper({}, null, 0);
            }
        } else {
            return parsePandas(raw);
        }
    } catch (ex) {
        return ex;
    }
}
export function fromCSV(raw: string, delimiter = "", header = true): DataWrapper | Error {
    try {
        const parsedCSV = Papa.parse(raw, {
            delimiter,
            header,
        });
        return fromJS(parsedCSV.data, false);
    } catch (ex) {
        return ex;
    }
}
