import { Flex, Icon } from "components/ui-deprecated";

export type WidgetSearchProps = {
    value: string;
    setValue: (p: string) => void;
};

export default function TableSearch(props: WidgetSearchProps) {
    const { value, setValue } = props;
    const isPrintMode = false;
    const isPrePrintMode = false;

    if (isPrintMode || isPrePrintMode) {
        return null;
    }

    return (
        <Flex className={"widget-search"} alignItems={"stretch"}>
            <span className={"icon"}>
                <Icon icon={"search"} />
            </span>
            <input
                className={"search-input"}
                type={"text"}
                placeholder={"Search..."}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
            />
        </Flex>
    );
}
