import styled from "styled-components";

export const SidebarControlContentTitle = styled.div`
    display: flex;
    justify-content: space-between;

    margin-bottom: 1.5rem;

    color: #c30a14;
    font-size: 1rem;
    font-weight: 500;
`;
