import { ReactNode } from "react";
import styled from "styled-components";

import { SidebarControlContent } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlContent/SidebarControlContent";
import { SidebarControlTrigger } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlTrigger";
import { SidebarControlTriggerContent } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlTrigger/SidebarControlTriggerContent";
import { SidebarControlTriggerTitle } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlTrigger/SidebarControlTriggerTitle";
import {
    SIDEBAR_TRIGGER_SUBTITLE_CLASS,
    SIDEBAR_TRIGGER_TITLE_CLASS,
} from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlTrigger/sidebar-trigger.consts";
import { SidebarControlToggleFunctionProp } from "components/layout/Sidebar/sidebar.types";
import { Popover } from "components/ui/Popover";
import { Icon } from "components/ui-deprecated";
import { usePopover } from "hooks/usePopover";

const SidebarControlStyled = styled.div`
    position: relative;
`;

type Props = {
    title: string;
    subtitle?: string;
    icon: string;
    popoverWidth?: string;
    children?: React.FunctionComponent<SidebarControlToggleFunctionProp> | ReactNode;
};

const SidebarControl = ({ icon, title, subtitle, popoverWidth, children }: Props) => {
    const { ref, isOpen, handleToggleOpen } = usePopover();

    return (
        <SidebarControlStyled ref={ref}>
            <SidebarControlTrigger onClick={handleToggleOpen}>
                <SidebarControlTriggerContent>
                    <Icon icon={icon} />
                    <SidebarControlTriggerTitle>
                        <span className={SIDEBAR_TRIGGER_TITLE_CLASS}>{title}</span>
                        {subtitle && (
                            <span className={SIDEBAR_TRIGGER_SUBTITLE_CLASS} title={subtitle}>
                                {subtitle}
                            </span>
                        )}
                    </SidebarControlTriggerTitle>
                </SidebarControlTriggerContent>
                <Icon icon={"angle-right"} />
            </SidebarControlTrigger>
            {isOpen && (
                <Popover variant={"right"} $zIndex={10}>
                    <SidebarControlContent $popoverWidth={popoverWidth}>
                        {typeof children === "function" ? children({ handleToggleOpen }) : children}
                    </SidebarControlContent>
                </Popover>
            )}
        </SidebarControlStyled>
    );
};

export { SidebarControl };
