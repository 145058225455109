import clsx from "clsx";

import styles from "components/ui/DatePicker/Controls/Controls.module.css";
import Icon from "components/ui-deprecated/Icon/Icon";

type Props = {
    onClick: () => void;
    disabled?: boolean;
};

const ControlsPrevious = (props: Props) => {
    return (
        <button
            className={clsx(styles.control, styles.controlStep, styles.controlStepPrev)}
            onClick={props.onClick}
            disabled={props.disabled}
            type="button"
        >
            <Icon icon={"angle-left"} />
        </button>
    );
};

export { ControlsPrevious };
