import styled from "styled-components";

import { BooleanFilters, BooleanFiltersProps } from "components/ui/BooleanFilters";

const WidgetFrameTitleBooleanFiltersStyled = styled.div`
    min-width: max-content;
    margin: -0.25rem 0;
`;

type Props = BooleanFiltersProps<any>;

const WidgetFrameTitleBooleanFilters = (props: Props) => {
    return (
        <WidgetFrameTitleBooleanFiltersStyled>
            <BooleanFilters {...props} />
        </WidgetFrameTitleBooleanFiltersStyled>
    );
};

export { WidgetFrameTitleBooleanFilters };
