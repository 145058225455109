import { MenuListItemIcon, MenuListLinkItem } from "components/ui/MenuList";
import { routes } from "routes";
import { useAppSelector } from "store";

const AdministrationLink = () => {
    const canAdministrationWrite = useAppSelector((state) => state.user.permissions.canAdministrationWrite);
    if (!canAdministrationWrite) return null;

    return (
        <MenuListLinkItem to={routes.administration.path.slice(0, -2)}>
            <MenuListItemIcon icon={"lely-icon-gear"} />
            Administration
        </MenuListLinkItem>
    );
};

export { AdministrationLink };
