import dayjs from "dayjs";

import { DataColumnDataType } from "components/ui/table-final-saviour/Table/columns/table-columns.types";
import { isDataColumn } from "components/ui/table-final-saviour/Table/columns/table-columns.utils";
import { Column, Row, RowIndex } from "components/ui/table-final-saviour/Table/table.types";
import { dateFormat } from "utils/date.utils";

export type ReadCellValueOptions<T extends Row> = {
    column: Column<T>;
    row: T;
};

export const readCellValue = <T extends Row>(options: ReadCellValueOptions<T>): string => {
    const { column, row } = options;
    if (!isDataColumn(column)) return "";

    const value = row[column.dataKey];

    // TODO Will be added later
    // Parse value if column value parser provided
    // if (column.valueTransform) parsedValue = column.valueTransform(parsedValue);

    // Transform nil values into string
    if (value === null || value === undefined || value === "") return "";

    const isDate = column.dataType === "date" && typeof value === "string" && dayjs(value).isValid();
    if (isDate) {
        return dayjs(value).format("DD/MM/YYYY");
    }

    const stringValue = value.toLocaleString();
    return stringValue;
};

export type CompareFn = (key: RowIndex, descending?: boolean) => <T extends Row>(a: T, b: T) => number;

export const compareString: CompareFn =
    (key: RowIndex, descending = false) =>
    <T extends Row>(a: T, b: T): number => {
        const aValue = a[key] ?? "";
        const bValue = b[key] ?? "";

        if (descending) {
            return bValue.localeCompare(aValue);
        } else {
            return aValue.localeCompare(bValue);
        }
    };

export const compareNumber: CompareFn =
    (key: RowIndex, descending = false) =>
    <T extends Row>(a: T, b: T): number => {
        if (descending) {
            return b[key] - a[key];
        } else {
            return a[key] - b[key];
        }
    };

export const compareDate: CompareFn =
    (key: RowIndex, descending = false) =>
    <T extends Row>(a: T, b: T): number => {
        const aValue = new Date(a[key]).getTime();
        const bValue = new Date(b[key]).getTime();

        if (descending) {
            return bValue - aValue;
        } else {
            return aValue - bValue;
        }
    };

export const compareBoolean: CompareFn =
    (key: RowIndex, descending = false) =>
    <T extends Row>(a: T, b: T): number => {
        const aValue = Number(a[key]);
        const bValue = Number(b[key]);

        if (descending) {
            return bValue - aValue;
        } else {
            return aValue - bValue;
        }
    };

export const compareFnMap: Record<DataColumnDataType, CompareFn> = {
    string: compareString,
    number: compareNumber,
    date: compareDate,
    boolean: compareBoolean,
};
