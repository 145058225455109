import { api as baseApi } from "store/api";
import { GUID } from "types/common.types";

const TAG_IMPERSONATION = "TAG_IMPERSONATION";

type ImpersonableUser = {
    login: string;
    name: string;
    userId: string;
};

type ImpersonationResult = {
    token: string;
    realUserId: string;
    impersonatedUserId: string;
    issuedDateTime: string;
    expiresDateTime: string;
};

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_IMPERSONATION],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getImpersonableUsers: builder.query<ImpersonableUser[], void>({
                query: () => ({
                    url: `impersonation/user`,
                }),
                providesTags: () => [TAG_IMPERSONATION],
            }),
            impersonate: builder.mutation<ImpersonationResult, { userId: GUID }>({
                query: ({ userId }) => ({
                    url: "impersonation/token",
                    method: "post",
                    body: { impersonatedUserId: userId },
                }),
                invalidatesTags: () => [TAG_IMPERSONATION],
            }),
        }),
    });

export const { useGetImpersonableUsersQuery, useImpersonateMutation } = api;
