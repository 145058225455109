import { matchPath } from "react-router-dom";

import { Routes, routes } from "routes";

export const getRouteFromPath = (path: string) => {
    const routesArray = Object.entries(routes) as {
        [key in keyof Routes]: [routeId: key, routeData: Routes[key]];
    }[keyof Routes][];
    const matchedRoutes = routesArray
        .filter(([_, { path: routePath }]) => matchPath(routePath, path))
        // longer path will contain more specific route
        .toSorted(([_routeIdA, { path: a }], [_routeIdB, { path: b }]) => a.length - b.length);

    if (matchedRoutes.length === 0) {
        return null;
    }

    const route = matchedRoutes.at(-1);
    return route;
};
