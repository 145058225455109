import styled, { css } from "styled-components";

import {
    SIDEBAR_TRIGGER_SUBTITLE_CLASS,
    SIDEBAR_TRIGGER_TITLE_CLASS,
} from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlTrigger/sidebar-trigger.consts";

const collapsedCSS = css`
    & > .${SIDEBAR_TRIGGER_TITLE_CLASS}, & > .${SIDEBAR_TRIGGER_SUBTITLE_CLASS} {
        display: none;
    }
`;

export const SidebarControlTriggerTitle = styled.div`
    display: flex;
    flex-direction: column;

    overflow: hidden;

    & > .${SIDEBAR_TRIGGER_SUBTITLE_CLASS} {
        color: ${({ theme }) => theme.color.grayDairy};

        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && collapsedCSS};
`;
