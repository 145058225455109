import styled from "styled-components";

import footerImage from "components/layout/Sidebar/assets/footer.png";

const SidebarFooterStyled = styled.footer`
    padding: 1rem 0 1.5rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: ${({ theme }) => theme.color.white};
    font-size: 0.625rem;
    font-weight: 300;

    & > img {
        width: 5rem;
        padding-top: 0.5rem;
    }
`;

const SidebarFooter = () => {
    return (
        <SidebarFooterStyled>
            Powered by <img src={footerImage} alt={"footer"} />
        </SidebarFooterStyled>
    );
};

export { SidebarFooter };
