import { css } from "styled-components";

import {
    tableCellBaseCSS,
    TableCellBaseCSSOptions,
} from "components/ui/table-final-saviour/Table/TableCell/table-cell.styles";

export type TableHeaderCellBaseCSSOptions = TableCellBaseCSSOptions;

export const tableHeaderCellBaseCSS = css`
    ${tableCellBaseCSS};

    background-color: ${({ theme }) => theme.color.white};

    font-weight: 500;
    letter-spacing: 0.025rem;

    vertical-align: middle;
`;
