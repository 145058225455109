import { Plugin } from "chart.js";

export const xAxisLabelsOnChartAreaPlugin: Plugin = {
    id: "xAxisLabelsOnChartArea",
    afterDatasetDraw(chart) {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;

        if (!xAxis || !yAxis) return;

        // Calculate the bottom position of the gradient
        // Adjust this based on your gradient logic
        const chartHeight = chart.height;

        // Redraw the X-axis labels
        ctx.save();
        xAxis.ticks.forEach((tick, index) => {
            const label = tick.label as string;
            const textSize = ctx.measureText(label);

            const x = xAxis.getPixelForTick(index) - textSize.width / 2; // Adjust for label width
            const y = chartHeight - 8; // Example offset

            ctx.fillText(label, x, y);
        });
        ctx.restore();
    },
};
