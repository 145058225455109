import { ReactElement } from "react";

import { ErrorWidget } from "components/components-deprecated/tables/ErrorWidget";
import { Loading } from "components/ui-deprecated";

export const render = (
    widget: ReactElement,
    isError: boolean,
    hasData: boolean,
    hasSpinner: boolean,
    noDataMessage = "No data"
): ReactElement => {
    if (isError) {
        return <ErrorWidget />;
    }
    if (!hasData && !hasSpinner) {
        return <span className={"no-data"}>{noDataMessage}</span>;
    }
    if (hasData && !hasSpinner) {
        return widget;
    }
    return <Loading style={{ height: "100%" }} show={hasSpinner}></Loading>;
};
