import { NavbarButton } from "components/layout/Navbar/NavbarButton";
import { Icon } from "components/ui-deprecated";
import { useAppDispatch } from "store";
import { logout } from "store/slices/auth.slice";

const LogoutButton = () => {
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <NavbarButton title={"Log out"} onClick={handleLogout}>
            <Icon icon={"sign-out-alt"} />
        </NavbarButton>
    );
};

export { LogoutButton };
