import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import Headers from "components/components-deprecated/tables/components/Headers";
import StandardRow from "components/components-deprecated/tables/components/StandardRow";
import { getCellContent, getHeadersForLevel } from "components/components-deprecated/tables/tables.helper";
import { ExpandableStaticRowProps } from "components/components-deprecated/tables/tables.interfaces";
import { Flex, Icon } from "components/ui-deprecated";
import { map as mapData } from "deprecated/data-wrapper";
import { setProperty, useAppSelector } from "store";

const GROUP_ID_FIELD = "Customer";

const ExpandableStaticRow: React.FC<ExpandableStaticRowProps> = ({
    expandableStaticData,
    row,
    headers,
    widerColumns,
}) => {
    const [expanded, setExpanded] = useState(false);
    const { formatter, fieldsToLinks, customColumnTypes, hiddenColumns, combineColumns, setContextFromColumns } =
        useContext(TableContext);

    const mappedData = expandableStaticData ? mapData(expandableStaticData, (row) => row) : [];

    const widgetReduxState = useAppSelector((state) => state.widget);
    const { projectDetailsCustomerName } = widgetReduxState;

    const dispatch = useDispatch();

    const handleExpandRow = () => {
        dispatch(
            setProperty({
                // This context key is specifically for this widget
                key: "projectDetailsCustomerName",
                value: row[GROUP_ID_FIELD],
            })
        );
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (projectDetailsCustomerName !== row[GROUP_ID_FIELD]) {
            setExpanded(false);
        }
    }, [projectDetailsCustomerName]);

    return (
        <div className={"expandable-row-container"}>
            <Flex key={JSON.stringify(row)} className={"basic-table-row expandable-row"} onClick={handleExpandRow}>
                <Flex className={"toggle-button-wrapper"}>
                    <div className={"toggle-button"}>
                        <Icon icon={expanded ? "lely-icon-caret-up" : "lely-icon-caret-down"} />
                    </div>
                </Flex>
                {headers.map((field) => (
                    <Flex className={`basic-table-cell ${widerColumns.includes(field) ? "wider" : ""}`} key={field}>
                        {(() => {
                            return getCellContent(
                                row,
                                field,
                                formatter,
                                fieldsToLinks,
                                customColumnTypes,
                                combineColumns,
                                setContextFromColumns
                            );
                        })()}
                    </Flex>
                ))}
            </Flex>
            {expanded && (
                <div className={"row-expansion"}>
                    <Headers level={1} data={expandableStaticData} grouping={false} />
                    <div>
                        {mappedData.map((item) => {
                            return (
                                <StandardRow
                                    key={JSON.stringify(item)}
                                    row={item}
                                    headers={getHeadersForLevel(expandableStaticData, hiddenColumns, 1, false)}
                                    formatter={formatter}
                                    fieldsToLinks={fieldsToLinks}
                                    customColumnTypes={customColumnTypes}
                                    setContextFromColumns={setContextFromColumns}
                                    widerColumns={widerColumns}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExpandableStaticRow;
