import { api as baseApi } from "store/api";

export type UserEvent =
    | "navigation"
    | "location-change"
    | "click:documentation"
    | "click:farm-scan-print"
    | "click:notifications"
    | "click:sidebar-collapse"
    | "select:clusters"
    | "select:language"
    | "select:primary-role"
    | "select:units";

export type UserEventQueryParams = {
    type: UserEvent;
    value: string;
    atPath: string;
    atURL: string;
    userPrimaryRole: string;
    userCluster?: string;
    userCompany?: string;
};

const api = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        sendUserEvent: builder.mutation<void, UserEventQueryParams>({
            query: (params) => ({
                url: `UserEvent`,
                method: "POST",
                body: params,
            }),
        }),
    }),
});

export const { useSendUserEventMutation } = api;
