import { z } from "zod";

export const authResponseSuccessSchema = z.object({
    access_token: z.string(),
    refresh_token: z.string(),
    token_type: z.literal("Bearer"),
    expires_in: z.number().optional(),
});

export const authResponseErrorSchema = z.object({
    error: z.enum(["invalid_grant"]),
    error_description: z.enum([
        "Your user name or password is invalid.",
        "Too many login attempts. Please try again later.",
        "Your password has expired.",
        "Server error",
    ]),
});

export const authResponseSchema = z.union([authResponseSuccessSchema, authResponseErrorSchema]);
