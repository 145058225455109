import { ReactNode } from "react";

import { DataContentError } from "components/ui/DataContent/DataContentError";
import { Dependency } from "components/ui/DataContent/data-content.types";
import { getErrorMessage } from "components/ui/DataContent/data-content.utils";
import { LoaderContent } from "components/ui/LoaderContent";
import { Icon } from "components/ui-deprecated";

type Props = {
    dependencies: Dependency[];
    children: ReactNode;
    className?: string;
};

const DataContent = (props: Props) => {
    const { dependencies } = props;

    const isLoading = dependencies.some((x) => x.isLoading);
    const isFetching = dependencies.some((x) => x.isFetching);
    const isError = dependencies.some((x) => x.isError);
    const error = dependencies.map((x) => getErrorMessage(x.error)).join("\n");

    if (isError) {
        return (
            <DataContentError>
                <Icon icon="exclamation-circle" />
                {error}
            </DataContentError>
        );
    }

    return (
        <LoaderContent isLoading={isLoading} isFetching={isFetching} className={props.className}>
            {props.children}
        </LoaderContent>
    );
};

export { DataContent };
export type { Props as DataContentProps };
