import styled from "styled-components";

import { InputRowInline } from "components/ui/input/InputRowInline";

export const InputRow = styled(InputRowInline)`
    flex-direction: column;
    align-items: flex-start;

    & > label {
        font-size: 0.875rem;
        font-weight: 500;
    }
`;
