import { Alert } from "@datamole/wds-component-alert";

import { Dialog } from "components/ui/Dialog";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";

type Props = {
    pageId?: string;
    title: string;
    children?: any;
};

export const ConfluenceDocsTrigger: React.FC<Props> = (props: Props) => {
    const { pageId, title, children } = props;

    const sendUserEvent = useSendUserEvent();

    let trigger = children;

    if (pageId) {
        const handleOpenConfluenceWidgetDocs = () => {
            sendUserEvent({
                type: "click:documentation",
                value: title,
            });
        };

        trigger = (
            <Dialog>
                <Dialog.Trigger>
                    <button type={"button"} onClick={handleOpenConfluenceWidgetDocs}>
                        {children}
                    </button>
                </Dialog.Trigger>
                <Dialog.Content>
                    <Dialog.Content.Title>Documentation Temporarily Unavailable</Dialog.Content.Title>
                    <Dialog.Content.Body>
                        <Alert severity={"warning"}>
                            <Alert.Icon />
                            <Alert.Content>
                                <Alert.Title>Sorry for the inconvenience</Alert.Title>
                                We are facing issues with the documentation service provider.
                                <br />
                                We are working on resolving the issue.
                            </Alert.Content>
                        </Alert>
                    </Dialog.Content.Body>
                </Dialog.Content>
            </Dialog>
        );
    }

    return trigger;
};
