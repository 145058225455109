import { Alert } from "@datamole/wds-component-alert";
import { useState, useEffect, ReactNode } from "react";
import styled from "styled-components";

import Icon from "components/ui-deprecated/Icon/Icon";

const NotificationBoardStyled = styled.div`
    position: fixed;
    right: 3rem;
    bottom: 2rem;
    z-index: 2000;

    max-width: 30rem;
`;

const AlertStyled = styled(Alert)`
    display: flex;
    justify-content: space-between;

    border: 1px solid !important;

    &[data-severity="success"] {
        background-color: var(--wds-color-success-100) !important;
        border-color: var(--wds-color-success-300) !important;
    }

    &[data-severity="warning"] {
        background-color: var(--wds-color-warning-100) !important;
        border-color: var(--wds-color-warning-300) !important;
    }

    &[data-severity="error"] {
        background-color: var(--wds-color-error-100) !important;
        border-color: var(--wds-color-error-300) !important;
    }

    &[data-severity="info"] {
        border-color: var(--wds-color-gray-300) !important;
    }
`;

const DismissIcon = styled(Icon)`
    margin-left: 1rem;

    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.textGrayDairyDarker};
    }
`;

export type NotificationType = "success" | "info" | "warning" | "danger";

class NotificationSingle {
    listeners: Array<(arg0: Array<ReactNode>) => void> = [];
    notifications: Array<ReactNode> = [];

    addListener(fn: (arg0: Array<ReactNode>) => void) {
        this.listeners.push(fn);
    }

    removeListener(fn: (arg0: Array<ReactNode>) => void) {
        const index = this.listeners.indexOf(fn);

        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    }

    success(content: string | ReactNode) {
        this.addNotification("success", content);
    }

    info(content: string | ReactNode) {
        this.addNotification("info", content);
    }

    warning(content: string | ReactNode) {
        this.addNotification("warning", content);
    }

    danger(content: string | ReactNode) {
        this.addNotification("danger", content);
    }

    addNotification(type: NotificationType, content: string | ReactNode) {
        const id = String(this.notifications.length);
        this.notifications.push(
            <Notification id={id} type={type}>
                {content}
            </Notification>
        );
        this.listeners.forEach((l) => l([...this.notifications]));
    }

    removeNotification(id: string) {
        this.notifications[Number(id)] = null;
        this.listeners.forEach((l) => l([...this.notifications]));
    }
}

const notificationSingle = new NotificationSingle();
export default notificationSingle;
export type NotificationProps = {
    id: string;
    type: NotificationType;
    children: ReactNode;
    show: boolean;
    defaultTimer: number;
};

Notification.defaultProps = {
    type: "success",
    show: true,
    defaultTimer: 5000,
};

export function Notification(props: NotificationProps) {
    const { id, type, children, show, defaultTimer } = props;

    function handleRemove() {
        notificationSingle.removeNotification(id);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleRemove();
        }, defaultTimer);
        return () => clearTimeout(timer);
    });

    if (!show) {
        return null;
    }

    const typeToSeverity = {
        success: "success",
        info: "info",
        warning: "warning",
        danger: "error",
    } as const;

    return (
        <div className={"datamole-ui-components-notification"}>
            <AlertStyled id={id} severity={typeToSeverity[type]}>
                <div>{children}</div>
                <div onClick={handleRemove}>
                    <DismissIcon icon={"times"} />
                </div>
            </AlertStyled>
        </div>
    );
}

export function NotificationBoard() {
    const [notifications, setNotifications] = useState<Array<ReactNode>>([]);
    useEffect(() => {
        const notificationListener = (notifications: Array<ReactNode>) => {
            setNotifications(notifications);
        };

        notificationSingle.addListener(notificationListener);
        return function cleanup() {
            notificationSingle.removeListener(notificationListener);
        };
    }, [setNotifications]);

    return (
        <NotificationBoardStyled>
            {notifications.map((n, i) => n !== null && <div key={i}>{n}</div>)}
        </NotificationBoardStyled>
    );
}
