import { css } from "styled-components";

const mobileCollapsedCSS = css`
    right: -35px;

    background-color: ${({ theme }) => theme.color.lelyRed};

    &::before {
        left: -2px;
        top: -37px;

        box-shadow: -18px 15px ${({ theme }) => theme.color.lelyRed};
    }

    &::after {
        left: -2px;
        top: 33px;

        box-shadow: -18px -15px ${({ theme }) => theme.color.lelyRed};
    }
`;

const mobileCSS = css`
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        right: -1px;

        height: 35px;
        width: 35px;

        &:hover {
            box-shadow: 0 0 0.125rem 0 #850c1866;
        }

        & > svg {
            font-size: 1.25rem;

            z-index: 4;
        }

        &::before {
            content: "";
            left: -4px;
            top: -37px;

            box-shadow: 18px 15px ${({ theme }) => theme.color.lelyRedDark};

            height: 35px;
            width: 35px;
            position: absolute;
            border-radius: 50%;
            background-color: transparent;
        }

        &::after {
            content: "";
            left: -4px;
            top: 33px;

            box-shadow: 18px -15px ${({ theme }) => theme.color.lelyRedDark};

            height: 35px;
            width: 35px;
            position: absolute;
            border-radius: 50%;
            background-color: transparent;
        }

        ${({ theme: { sidebarCollapsed } }) => sidebarCollapsed && mobileCollapsedCSS};
    }
`;

export const sidebarCollapseButtonCSS = css`
    position: absolute;
    right: -1rem;
    top: calc(3rem - 2px);
    z-index: 3;

    width: 2rem;
    height: 2rem;
    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.color.lelyRedDark};
    border-radius: 50%;
    border-color: transparent;
    box-shadow: 0 0 0.125rem 0 #850c1866;
    outline: transparent;

    color: ${({ theme }) => theme.color.white};

    &:hover {
        box-shadow: 0 0 0.25rem 0.125rem #850c1866;
    }

    ${mobileCSS};
`;
