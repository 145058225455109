import clsx from "clsx";
import { HTMLAttributes } from "react";

import styles from "components/ui/ButtonGroup/button-group.module.css";

type Props = HTMLAttributes<HTMLDivElement>;

const ButtonGroup = (props: Props) => {
    const { children, className, ...otherProps } = props;

    return (
        <div {...otherProps} className={clsx(styles.button_group, className)}>
            {children}
        </div>
    );
};

export { ButtonGroup };
