import { Page as _Page } from "components/layout/Page/Page";
import { PageContent } from "components/layout/Page/PageContent";
import { PageHeader } from "components/layout/Page/PageHeader";
import { PageNavigation } from "components/layout/Page/PageNavigation";
import { PageSettings } from "components/layout/Page/PageSettings";
import { PageStickyHeader } from "components/layout/Page/PageStickyHeader";

const Page = _Page as typeof _Page & {
    Content: typeof PageContent;
    Header: typeof PageHeader;
    Navigation: typeof PageNavigation;
    Settings: typeof PageSettings;
    StickyHeader: typeof PageStickyHeader;
};

Page.Content = PageContent;
Page.Header = PageHeader;
Page.Navigation = PageNavigation;
Page.Settings = PageSettings;
Page.StickyHeader = PageStickyHeader;

export { usePageSettings } from "components/layout/Page/PageSettings";

export { Page };
