// Only full-format ISO DATE string (with or without time zone).
// eslint-disable-next-line max-len
import { fromJS } from "deprecated/data-wrapper/parse";
import { DataWrapper } from "deprecated/data-wrapper/types";

const ISO_DATE_PATTERN =
    /(\d{4})-(0\d|1[012])-(0[1-9]|[12]\d|3[01])T([01]\d|2[0123]):([012345]\d):([012345]\d)(?:\.(\d{3}))?($|Z|[+-](?:[01]\d|2[0123])(?::[012345]\d)?)/;
export function isIsoDateString(value: string): boolean {
    return ISO_DATE_PATTERN.test(value);
}
// We don't use new Date(string) because it has issues in Safari browser. Also we don't use moment because we don't want
// to bring such heavy dependency for one feature. Furthermore, we have more strict limit how ISO DATE string should
// look like and we want to be in control of how limited it is.
export function parseIsoDateString(value: string): Date | null {
    const match = ISO_DATE_PATTERN.exec(value);

    if (match !== null) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10);
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        const millis = typeof match[7] === "undefined" ? 0 : parseInt(match[7], 10);
        let timezone = 0;

        if (typeof match[8] !== "undefined" && match[8] !== "Z") {
            const tzMatch = /([+-])([01]\d|2[0123])([012345]\d)?/.exec(match[8]);

            if (tzMatch !== null) {
                const hoursShift = parseInt(tzMatch[2], 10);
                const minutesShift = typeof tzMatch[3] === "undefined" ? 0 : parseInt(tzMatch[3], 10);

                if (tzMatch[0] === "+") {
                    timezone = hoursShift * 60 + minutesShift;
                } else {
                    timezone = -(hoursShift * 60 + minutesShift);
                }
            }
        }

        const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, millis));
        date.setUTCMinutes(date.getUTCMinutes() - timezone);
        return date;
    } else {
        return null;
    }
}

// TODO Purge DataWrapper from code
export type Row = Record<string, any>;

export const toDataWrapper = <T extends Row>(data: T[] | undefined): DataWrapper => {
    const dataWrapperOrError = fromJS(data ?? []);

    if (dataWrapperOrError instanceof Error) {
        // eslint-disable-next-line no-console
        console.error("Error converting data to DataWrapper", dataWrapperOrError);
        return null;
    }

    return dataWrapperOrError;
};

const renameColumn = <T extends Row>(data: T[] | undefined, columnName: string, newColumnName: string) => {
    if (!data) return data;
    return data.map((row) => {
        if (!(columnName in row)) return row;

        const { [columnName]: value, ...rowWithoutColumn } = row;
        return { ...rowWithoutColumn, [newColumnName]: value };
    }) as T[];
};

export const renameColumns = <T extends Row>(data: T[] | undefined, renameColumnMap: Record<string, string>) => {
    if (!data) return data;

    for (const columnName in renameColumnMap) {
        data = renameColumn<T>(data, columnName, renameColumnMap[columnName]);
    }
    return data;
};

// Used for tables where some rows are without a link
export const INVALID_LINK_VALUE = "invalid_link_value";

export const addLinkColumn = <T extends Row>(
    data: T[] | undefined,
    columnName: string,
    getColumnValue: (row: T) => string
) => {
    if (!data) return data;
    return data.map((row) => {
        const value = getColumnValue(row);

        if (value === INVALID_LINK_VALUE) {
            return { ...row, [columnName]: null };
        }

        return { ...row, [columnName]: value };
    });
};

export const addLinkColumns = <T extends Row>(
    data: T[] | undefined,
    linkColumnMap: Record<string, (row: T) => string>
) => {
    if (!data) return data;

    for (const columnName in linkColumnMap) {
        data = addLinkColumn<T>(data, columnName, linkColumnMap[columnName]);
    }
    return data;
};

export type ProcessDataConfig<T extends Row> = {
    renameColumnMap?: Record<string, string>;
    addLinkColumnMap?: Record<string, (row: T) => string>;
};

export const processData = <T extends Row>(data: T[] | undefined, config: ProcessDataConfig<T>) => {
    if (!data) return data;
    const dataRenamed = renameColumns(data, config.renameColumnMap ?? {});
    const dataRenamedWithLinks = addLinkColumns(dataRenamed, config.addLinkColumnMap ?? {});
    return dataRenamedWithLinks;
};

export const translateData = <T extends Row>(
    data: T[] | undefined,
    fieldsToTranslate: string[],
    t: (key: string) => string
) => {
    if (!data) return data;

    fieldsToTranslate.forEach((field) => {
        data = renameColumn<T>(data, field, t(field));
    });
    return data;
};
