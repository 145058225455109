import { DefaultTheme } from "styled-components";

import { SIZE_EXTRA_LARGE, SIZE_EXTRA_SMALL, SIZE_LARGE, SIZE_SMALL } from "components/layout/layout.consts";

export const theme: DefaultTheme = {
    breakpoints: {
        xl: `${SIZE_EXTRA_LARGE}px`,
        lg: `${SIZE_LARGE}px`,
        sm: `${SIZE_SMALL}px`,
        xs: `${SIZE_EXTRA_SMALL}px`,
    },
    color: {
        /* Lely red */
        lelyRed: "#c30a14",
        lelyRedDark: "#850c18",

        /* Warm gray-tones */
        black: "#000000",
        darkGray: "#222222",
        gray: "#333333",
        grayDairyDarker: "#766e68",
        grayLight: "#cfcbc3",
        grayDairy: "#dedbd6",
        grayDairyLight: "#efedea",
        dairyLight: "#f7f6f4",
        white: "#ffffff",

        /* Text colors */
        textBlack: "#121212",
        textGray: "#333333",
        textGrayDairyDarker: "#766e68",
        textWhite: "#ffffff",

        /* Additional colors */
        green: "#73bd07",
        orange: "#ff7921",
        red: "#fd3535",
        yellow: "#ffc700",
        blue: "#48bdf0",
    },
    sidebarCollapsed: false,
    widgetSize: "md",
};
