import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getStoredAuthData, removeStoredAuthData } from "services/auth/auth.service";
import { AuthData } from "services/auth/auth.types";

interface AuthState {
    accessToken?: string;
    isAuthenticated: boolean;
}

let initialState: AuthState = { isAuthenticated: false };

const storedAuthData = getStoredAuthData();
if (storedAuthData) {
    initialState = {
        accessToken: storedAuthData.accessToken,
        isAuthenticated: true,
    };
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticate: (state, action: PayloadAction<AuthData>) => {
            state.accessToken = action.payload.accessToken;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.accessToken = undefined;
            state.isAuthenticated = false;

            removeStoredAuthData();
        },
    },
});

export const { authenticate, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
